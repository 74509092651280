import React, {useState} from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import CMFPC from "./CMFP-3A";
import Dialog from '@material-ui/core/Dialog';


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const [materials, setMaterials] = useState<any>(JSON.parse(localStorage.getItem('selectedCodeList') || '[]'));
      const getCode = localStorage.getItem('selectedCode')


      const [Err, setErr] = useState(false)
      const code = localStorage.getItem('selectedCptCode')

      const [open, setOpen] = useState(false)
      const handleClose = () => {
            setOpen(false)
      }

      const increaseQuant = (id: any) => {
            let newList = materials.map((x: any) => {
                  if (x.material_id === id && x.quantity < 10) {
                        x.quantity = x.quantity + 1
                  } return x
            })
            setMaterials(newList)
      }


      const decreaseQuant = (id: any) => {
            let newList = materials.map((x: any) => {
                  if (x.material_id === id && x.quantity > 1) {
                        x.quantity = x.quantity - 1
                  } return x
            })
            setMaterials(newList)
      }

      const addMaterails = () => {
            localStorage.setItem('isCptCodeFlow', 'yes')
            navigation.navigate('SelectManufacture')
      }

      const navigateTo = () => {
            localStorage.setItem('selectedMaterialList', JSON.stringify(materials))
            if (window.screen.width < 950) {
                  navigation.navigate('CMFPC')
            } else {
                  setOpen(true)
            }
      }
      
      const deleteMaterial = (id : any) => {
            let newList = materials.filter((x: any) => x.material_id !== id)
            setMaterials(newList)
            localStorage.setItem('selectedCodeList', JSON.stringify(newList))
          }

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Dashboard'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                          
                                    <View style={styles.card}>
                                          <View style={styles.topHeading}>
                                                <Text style={styles.text1}>Code:- {code}</Text>
                                                <TouchableOpacity onPress={() => addMaterails()}>
                                                      <Text style={styles.text3}>Add Material </Text>
                                                </TouchableOpacity>

                                          </View>
                                          <FlatList
                                                data={materials}
                                                keyExtractor={(item: any) => item.material_id}
                                                renderItem={({ item }) => (

                                                      <View style={styles.material}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                  <img src={require("../assets/material2.png")} style={{ height: 72, width: 72 }} />
                                                                  <View style={{ flexDirection: 'column' }}>
                                                                        <Text style={{ fontSize: 20, color: '#2b2b2b' }}>{item.material_name}</Text>
                                                                        <Text style={{ color: '#4e3e71', fontSize: 15, paddingVertical: 10 }}>Size: {item.size}</Text>
                                                                  </View>
                                                            </View>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                  <View style={styles.quantity}>
                                                                        <TouchableOpacity onPress={() => increaseQuant(item.material_id)}>
                                                                              <Text style={styles.btn}>+</Text>
                                                                        </TouchableOpacity>

                                                                        <Text style={styles.btn2}>{item.quantity === 10 ? item.quantity : "0" + item.quantity}</Text>

                                                                        <TouchableOpacity onPress={() => decreaseQuant(item.material_id)}>
                                                                              <Text style={styles.btn}>-</Text>
                                                                        </TouchableOpacity>
                                                                  </View>
                                                                  <TouchableOpacity onPress={() => deleteMaterial(item.material_id)} style={{ marginVertical: 'auto', marginHorizontal: 20 }}>
                                                                        <img src={require("../assets/delete2.png")} style={{ height: 24, width: 24 }} />
                                                                  </TouchableOpacity>
                                                            </View>
                                                      </View>
                                                )}
                                          />
                                          <TouchableOpacity onPress={() => navigateTo()}>
                                                <View style={styles.button}>
                                                      <Text style={styles.buttonText}>Add To Patient List</Text>
                                                </View>
                                          </TouchableOpacity>
                                    </View>


                        
                        </View>
                        </View>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                  >
                        <View style={{ height: 500, width: 500, margin: 50}}>
                              <CMFPC navigation={navigation} />
                        </View>
                   
                  </Dialog>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      container: {
            flex: 1,
            backgroundColor: "#ffff",
            flexDirection: 'column',

      },
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,

      },
      heading: {
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      quantity: {
            flexDirection: 'row',
            // width: 108,
            // height: 32,
            borderRadius: 5,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            margin: 'auto',
            justifyContent: 'space-between'
      },
      btn: {
            fontSize: 20,
            color: '#4e3e71',
            paddingVertical: 2,
            paddingHorizontal: 10
      },
      btn2: {
            fontSize: 15,
            color: '#4e3e71',
            padding: 2,
            backgroundColor: '#cce9e5',
            borderRadius: 4,
            margin: 5,
            paddingHorizontal: 10
      },
      material: {
            width: '60%',
            marginHorizontal: 24,
            marginVertical: 15,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: '#d8d8d8',
            borderRadius: 8
      },
      info: {
            color: '#0778df',
            fontSize: 20,
            paddingVertical: 2,
      },
      card: {
            marginVertical: 24,
            marginHorizontal: 20,
            flexDirection: 'column',
            justifyContent: 'space-between'

      },
      topHeading: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 10
      },
      materials: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 5,
            borderBottomWidth: 1,
            borderColor: 'grey'
      },
      text2: {
            paddingVertical: 2,
            color: '#007af2',
            fontSize: 20
      },
      text1: {
            fontSize: 24,
            color: '#4e3e71',
      },
      text3: {
            fontSize: 24,
            color: '#4e3e71',
            borderBottomWidth: 1,
            borderBottomColor: '#4e3e71'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            // padding: 15,
            margin: 15,
            flexDirection: 'column'

      }

})