// Customizable Area Start
import React, { useState, useEffect } from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
} from 'react-native';
import EditProfile from './EditProfile';
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';

export default function Profile({ navigation }: { navigation: any }) {

      const [data, setData] = useState<any>({});
      const [editPage, showEditpage] = useState(false);
      const [role, setRole] = useState<any>()


      const parent = (data : any) => {
            showEditpage(false)
            setData(data.data)
      }
      useEffect(() => {
            const getHospitals = async () => {
                  let url = `/account_block/accounts/account_detail`
                  await getAxiosAPI(url).then((res: any) => {
                        if(!res.error){
                              localStorage.setItem('loggedinUserInfo', JSON.stringify(res.data.data.attributes))
                              setData(res.data.data);
                              setRole(res.data.data.attributes.role_id)                  }
                      })
                  
            };
            getHospitals();
      }, []);

      const NavigateTo = (items: any) => {
            localStorage.setItem('userFacilities', JSON.stringify(items.data))
                  navigation.navigate('UserFacilities')
          
        
      }
      const editProfile = () => {
            localStorage.setItem('profileData', JSON.stringify(data))
            if (window.screen.width < 950) {
                  navigation.navigate('EditProfile')
            } else {
                  showEditpage(true)
            }
            
      }
      const date = data?.attributes?.date_of_birth
      var d = new Date(date);

      return (
            <>
            {
              editPage ? <EditProfile role={role} parent={parent} navigation={navigation}/> : 
                              <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <View style={styles.bottomContainer}>
                                          <View style={styles.container}>
                                                {window.screen.width < 950 ? <View style={styles.headingView}>
                                                      <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                                            <img src={require("../assets/leftArrow1.png")} style={{
                                                                  height: 24, width: 24,
                                                            }} />
                                                      </TouchableOpacity>
                                                      <Text style={styles.heading}>Profile</Text>
                                                </View> : null}
                                                <View style={styles.profileImage} >
                                                      <img src={data?.attributes?.profile_image ? data.attributes.profile_image : require("../assets/avatar-chat1.png")} style={{ height: 96, width: 96, paddingTop: 10, borderRadius: '50%' }} />
                                                </View>
                                          </View>

                                          <View style={styles.list}>
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>First Name</Text>
                                                      <Text style={styles.body}> {data?.attributes?.first_name} </Text>
                                                </View>
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Last Name</Text>
                                                      <Text style={styles.body}> {data?.attributes?.last_name} </Text>
                                                </View>
                                                {data?.attributes?.date_of_birth ? <View style={styles.text}>
                                                      <Text style={styles.title}>Date of Birth  </Text>
                                                      <Text style={styles.body}>{d.toLocaleDateString()}</Text>
                                                </View> : null}
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Phone Number</Text>
                                                      <Text style={styles.body}> +{data?.attributes?.full_phone_number} </Text>
                                                </View>
                                                {data?.attributes?.address ?
                                                      <View style={styles.text}>
                                                            <Text style={styles.title}>Address</Text>
                                                            <Text style={styles.body}>{data?.attributes?.address}</Text>
                                                      </View> : null}
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Email</Text>
                                                      <Text style={styles.body}>{data?.attributes?.email}</Text>
                                                </View>
                                                {data?.attributes?.gender ?
                                                      <View style={styles.text}>
                                                            <Text style={styles.title}>Gender </Text>
                                                            <Text style={styles.body}>{data?.attributes?.gender}</Text>
                                                      </View> : null}
                                                {role === '2' || role === '3' ?
                                                      <View style={styles.text}>
                                                            <Text style={styles.title}>Contact Priority </Text>
                                                            <Text style={styles.body}>{data?.attributes?.is_primary_contact ? "Primary" : "Secondary"}</Text>
                                                      </View> : null}
                                                
                                                 
                                                      


                                                <TouchableOpacity onPress={() => NavigateTo(data?.attributes?.facilities)}>
                                                      <View style={styles.text1}>
                                                            <Text style={styles.title}>My Facilities  </Text>
                                                            <img style={{ height: 15, width: 15}} src={require("../../dashboard/assets/arrow.png")} />
                                                      </View>
                                                </TouchableOpacity>

                                          </View>
                                    </View>
                                    <TouchableOpacity onPress={() => editProfile()}>
                                          <View style={styles.button}>
                                                <Text style={styles.buttonText}>Edit Profile</Text>
                                          </View>
                                    </TouchableOpacity>
                              </View>                
            }
            </>
           
      )

}
const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff"
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
            marginBottom: 20,

      },
      title: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            fontWeight: 'bold'
      },
      body: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            borderBottomColor: '#e5e5e5',
            borderBottomWidth: 1,
            paddingBottom: 20
      },
      heading: {
            color: '#fff',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 38,
            marginTop: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      profileImage: {
            flexDirection: 'row',
            marginHorizontal: 20,
            position: 'relative'
      },
      editIcon: {
            justifyContent: 'center',
            position: 'absolute',
            top: 165,
            right: 170,
            elevation: 10,
            backgroundColor: '#e9e9e9'
      },
      bottomContainer: {
      },
      list: {
            flexDirection: 'column',
            marginHorizontal: 24,
            marginVertical: 24,
            justifyContent: 'center'
      },
      text: {
            flexDirection: 'column',
      },
      text1: {
            paddingVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      icons: {
            justifyContent: 'flex-end',
      }
})
// Customizable Area End