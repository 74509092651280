import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import HomeScreen from "../HomeScreen";
import { asyncLocalStorage } from "../../../framework/src/Utilities"
import NotFoundPage from "../../../framework/src/NotFoundPage";


export function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params, hash = "") => {
    let url = routeMap[to].path + hash;
    // replace params ids in the url with actual values
      if (params && Object.keys(params).length > 0) {
        Object.keys(params).forEach(param => {
          const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
          url = url.replace(re, escape(params[param]));
        });
      }
      // removing empty params from url - every string between /: and ?
      url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
      // if the route is not a modal
      if (!routeMap[to].modal) {
        history.push(url);
        // if the route is a modal
      } else {
        // checking if the url ends with a slash or not
        const slash = /\/$/.test(match.url) ? "" : "/";
        // current url in the browser + slash + modal url with parameters
        url = match.url + slash + url;
        // removing the */ from the url
        url = url.replace(/\*\/?/g, "");
        history.push(url);
      }
   
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};


function endsWithAlertWeb(nestedPath) {
  const lowercasePath = nestedPath.toLowerCase();
  return lowercasePath.endsWith("/alertweb") || lowercasePath.endsWith("alertweb");
}


const WebRoutesGenerator = ({ routeMap }) => {
  const authenticated = asyncLocalStorage.getItem('authToken')
  const routes = Object.keys(routeMap).map(route => "/"+route.toLowerCase())
  routes.push("/")
  const path = window.location.pathname;
  if(!routes.includes(path.toLowerCase()) && !endsWithAlertWeb(path.toLowerCase())){
    return  <Route component={NotFoundPage} />
  }
  if (authenticated) {
    return Object.keys(routeMap).map(route => {
      const currentRoute = routeMap[route];
      const Component = currentRoute.component;

      if (currentRoute.modal) {
        return (
          <ModalRoute
            key={currentRoute.path}
            path={currentRoute.path}
            component={props => (
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            )}
            onBackdropClick={() => null}
          />
        );
      } else {
        return (
          <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => (
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            )}
          />
        );
      }
    });
  } else {
    return Object.keys(routeMap).map(route => {
      const currentRoute = routeMap[route];
      const Component = currentRoute.component;
      const publicRoutes = [
        '/ForgotPassword',
        '/AuthenticateAccount',
        '/EmailAccountRegistration',
        '/RolesPermissions2',
        '/EmailAccountLoginBlock',
        '/TermsCondition',
        '*/AlertWeb'
      ]
      // console.log(currentRoute.path)
      if (publicRoutes.includes(currentRoute.path)) {
        if (currentRoute.modal) {
          console.log(currentRoute.path)
          return (
            <ModalRoute
              key={currentRoute.path}
              path={currentRoute.path}
              component={props => (
                <Wrapper element={<Component />} {...props} routeMap={routeMap} />
              )}
              onBackdropClick={() => null}
            />
          );
        } else {
          return (
            <Route
              key={currentRoute.path}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={props => (
                <Wrapper element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
        }  
      } else {
        if (currentRoute.modal) {
          return (
            <ModalRoute
              key={currentRoute.path}
              path={currentRoute.path}
              component={props => (
                <Wrapper element={<HomeScreen />} {...props} routeMap={routeMap} />
              )}
              onBackdropClick={() => null}
            />
          );
        } else {
          return (
            <Route
              key={currentRoute.path}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={props => (
                <Wrapper element={<HomeScreen />} {...props} routeMap={routeMap} />
              )}
            />
          );
        }
      }
      
    });
  }
  
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
