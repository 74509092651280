// Customizable Area Start
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Picker,
  View,
  TextInput,
  ScrollView,
  FlatList,
  ActivityIndicator,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  getAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import {
  ageWithMonth,
  getBookingDate,
  getBookingTime,
  getTimeStemp,
  SortData,
} from "../../../framework/src/Utilities";
import Scale from "../../../components/src/Scale";
// @ts-ignore
import GenerateReportCustomTable from "../../../framework/src/GenerateReportCustomTable";
import CustomPopUp from "../../../framework/src/CustomPopUp";

export const validation_Schema = yup.object({});

const tableComponents = [
  "Id",
  "First name",
  "Last name",
  "Patient Phone",
  "Patient age",
  "Date of Birth",
  "Patient gender",
  "Patient email",
  "Facility",
  "Booking datetime",
  "Patient type",
  "Latex allergies",
  "Surgery detail",
  "Procedure duration",
  "Or table",
  "Microscope",
  "Pre- op antibiotics",
  "Drapes",
  "Sutures",
  "Sutures size",
  "Insurance carrier",
  "Policy member",
  "Authorization",
  "Benefits",
  "Physio therapy",
  "Homecare",
  "Diagnosis codes",
  "CPT codes",
];

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const [selectedDate, setselectedDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const role_id = localStorage.getItem("role");
  const [apiReport, setApiReport] = useState([]);
  const [offset, setOffset] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [surgeons, setSurgeons] = useState([]);
  const [cptCodes, setCptCodes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errormsg, setErrorMsg] = useState("");

  const [showList, setShowlist] = useState({
    surgeon: false,
    cpt: false,
    facility: false,
  });

  const [Names, setNames] = useState({
    surgeon: "",
    cpt: "",
    facility: "",
    surgeon_id: "",
    facility_id: "",
  });

  const ListEmptyView = () => {
    return (
      <View>
        <Text style={styles.heading}>No Record Found</Text>
      </View>
    );
  };
  const ListEmptyViewTable = () => {
    return (
      <View style={{ display: "flex", height: "100%" }}>
        <Text style={styles.heading2}>No Record Found</Text>
      </View>
    );
  };

  const getCptCodeList = async (search: any = "") => {
    let url = `/bx_block_code/order_code/cpt_codes_list?search=${search}&limit=10`;
    await getAxiosAPI(url).then((res: any) => {
      if (!res.error) {
        setCptCodes(
          res.data.data.sort((a: any, b: any) =>
            SortData(a.attributes.code, b.attributes.code)
          )
        );
      }
    });
  };

  const getHospitals = async (search: any = "") => {
    let url = `/bx_block_hospitals/hospitals?search=${search}&limit=10`;
    await getAxiosAPI(url).then((res: any) => {
      if (!res.error) {
        setHospitals(
          res.data.data.sort((a: any, b: any) =>
            SortData(
              a.attributes.name.toLowerCase(),
              b.attributes.name.toLowerCase()
            )
          )
        );
      }
    });
  };

  const getSurgeon = async (search: any = "") => {
    setLoader(true);
    let url = `/account_block/accounts/surgoen_list_for_mm?search=${search}&limit=10&offset=${offset}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        setLoader(false);
      } else {
        setSurgeons(res.data.data);
        setLoader(false);
      }
    });
  };

  const handleSearch = (text: any, type: any) => {
    setNames((preVal) => ({ ...preVal, [type]: text }));
    if (type === "surgeon") {
      if (text.length) {
        getSurgeon(text);
      } else {
        setNames((pre) => ({ ...pre, surgeon_id: "" }));
      }
    } else if (type === "facility") {
      if (text.length) {
        getHospitals(text);
      } else {
        setNames((pre) => ({ ...pre, facility_id: "" }));
      }
    } else {
      getCptCodeList(text);
    }
  };

  useEffect(() => {
    getCptCodeList();
    getHospitals();
    getSurgeon();
  }, []);

  const getBookings = async (value: any) => {
    setLoading(true);
    let startDate = getTimeStemp(selectedDate.start);
    let endDate = getTimeStemp(selectedDate.end);
    let url = `/bx_block_order/orders/order_csv?start_date=${startDate}&end_date=${endDate +
      86387000}&status=${value.status}&cpt_codes=${Names.cpt}&hospital_id=${Names.facility_id
      }&surgeon_id=${Names.surgeon_id}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setErrorMsg(msg);
        setPopup(true);
        setLoading(false);
      } else {
        let reqData = res.data.data.map((x: any) => ({
          Id: x.id,
          First_name: x.attributes.first_name,
          Last_name: x.attributes.last_name,
          Patient_Phone: x.attributes.patient_phone,
          Patient_age: ageWithMonth(x.attributes.patient_age),
          Date_of_birth: getBookingDate(x.attributes.date_of_birth),
          Patient_gender: x.attributes.patient_gender,
          Patient_email: x.attributes.patient_email,
          Facility: x.attributes.hospital.data.attributes.name,
          Booking_datetime:
            getBookingDate(x.attributes.booking_datetime) +
            " at " +
            getBookingTime(x.attributes.booking_datetime),
          Patient_type: x.attributes.patient_type,
          Latex_allergies: x.attributes.latex_allergies,
          Surgery_detail: x.attributes.surgery_detail,
          Procedure_duration: x.attributes.procedure_duration,
          Or_table: x.attributes.or_table,
          Microscope: x.attributes.microscope ? "Yes" : "No",
          Pre_op_antibiotics: x.attributes.pr_op_antibiotics ? "Yes" : "No",
          Drapes: x.attributes.draps,
          Sutures: x.attributes.sutures,
          Sutures_size: x.attributes.sutures_size,
          Insurance_carrier: x.attributes.insurance_carrier,
          Policy_member: x.attributes.policy_member_id,
          Authorization: x.attributes.authorization,
          Benefits: x.attributes.benefits,
          Physio_therapy: x.attributes.physio_therapy ? "Yes" : "No",
          Homecare: x.attributes.homecare ? "Yes" : "No",
          Diagnosis_codes: x.attributes.diagnosis_codes
            .map((x: any) => x.code)
            .join(","),
          CPT_codes: x.attributes.cpt_codes.map((x: any) => x.code).join(","),
        }));
        setApiReport(
          reqData.sort((a: any, b: any) => SortData(a.First_name, b.First_name))
        );
        setLoading(false);
      }
    });
  };

  const handleDateChange = (date: any, type: string) => {
    setselectedDate((perval: any) => ({ ...perval, [type]: date }));
  };

  const downloadFile = ({
    data,
    fileName,
    fileType,
  }: {
    data: any;
    fileName: any;
    fileType: any;
  }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e: any) => {
    e.preventDefault();
    let headers = [tableComponents.join(",")];
    let usersCsv = apiReport.reduce((acc: any, user: any) => {
      const {
        Id,
        First_name,
        Last_name,
        Patient_Phone,
        Patient_age,
        Date_of_birth,
        Patient_gender,
        Patient_email,
        Facility,
        Booking_datetime,
        Patient_type,
        Latex_allergies,
        Surgery_detail,
        Procedure_duration,
        Or_table,
        Microscope,
        Pre_op_antibiotics,
        Drapes,
        Sutures,
        Sutures_size,
        Insurance_carrier,
        Policy_member,
        Authorization,
        Benefits,
        Physio_therapy,
        Homecare,
        Diagnosis_codes,
        CPT_codes,
      } = user;
      acc.push(
        [
          Id,
          First_name,
          Last_name,
          Patient_Phone,
          Patient_age,
          Date_of_birth,
          Patient_gender,
          Patient_email,
          Facility,
          Booking_datetime,
          Patient_type,
          Latex_allergies,
          Surgery_detail,
          Procedure_duration,
          Or_table,
          Microscope,
          Pre_op_antibiotics,
          Drapes,
          Sutures,
          Sutures_size,
          Insurance_carrier,
          Policy_member,
          Authorization,
          Benefits,
          Physio_therapy,
          Homecare,
          Diagnosis_codes,
          CPT_codes,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Bookings Report.csv",
      fileType: "text/csv",
    });
  };

  const tableRenderer = () => {
    return (
      <>
        <GenerateReportCustomTable
          apiReport={apiReport}
          header={tableComponents}
        />
      </>
    );
  };

  const handlePress = (item: any, type: any) => {
    if (type === "surgeon") {
      setNames((preVal: any) => ({
        ...preVal,
        surgeon: item.attributes.first_name + " " + item.attributes.last_name,
        surgeon_id: item.id,
      }));
    }
  };

  return (
    <>
      {popup && (
        <CustomPopUp
          message={errormsg}
          btnText={"Back"}
          ClosePopUp={() => setPopup(false)}
        />
      )}
      {window.screen.width < 950 ? (
        <View>
          <View style={styles.headingView}>
            <TouchableOpacity
              style={styles.leftArrowIconWrapper}
              onPress={() => navigation.goBack()}
            >
              <img
                src={require("../assets/leftArrow1.png")}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
            <Text style={styles.headingh}>Generate Bookings Report</Text>
          </View>
          <View style={styles.outerCard}>
            <Formik
              initialValues={{
                status: "",
              }}
              validationSchema={validation_Schema}
              onSubmit={(values: any, actions: any) => {
                getBookings(values);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
              }) => (
                <View>
                  <Text style={styles.heading1}>Select Status</Text>
                  <View style={styles.pickerContainer}>
                    <Picker
                      selectedValue={values.status}
                      style={styles.picker}
                      onValueChange={handleChange("status")}
                    >
                      <Picker.Item value="" label="Select Status :-" />
                      <Picker.Item value="confirmed" label="Active" />
                      <Picker.Item value="pending" label="In-active" />
                    </Picker>
                  </View>
                  <>
                    <Text style={styles.heading1}>Select Surgeon</Text>

                    <View>
                      <TextInput
                        placeholder="Search Surgeon "
                        placeholderTextColor="#b2bac6"
                        style={styles.input}
                        onFocus={() =>
                          setShowlist((preVal: any) => ({
                            ...preVal,
                            surgeon: true,
                          }))
                        }
                        onBlur={() =>
                          setShowlist((preVal: any) => ({
                            ...preVal,
                            surgeon: false,
                          }))
                        }
                        onChangeText={(queryText) =>
                          handleSearch(queryText, "surgeon")
                        }
                        value={Names.surgeon}
                      />
                      {showList.surgeon && (
                        <View>
                          {loader ? (
                            <ActivityIndicator
                              style={{
                                marginHorizontal: 30,
                                height: 60,
                                width: 60,
                              }}
                              size="large"
                              color="#4e3e71"
                            />
                          ) : (
                            <FlatList
                              data={surgeons}
                              ListEmptyComponent={ListEmptyView()}
                              keyExtractor={(item: any) => item.id}
                              renderItem={({ item }) => (
                                <TouchableOpacity
                                  style={styles.onselect}
                                  onPress={() =>
                                    setNames((preVal: any) => ({
                                      ...preVal,
                                      surgeon:
                                        item.attributes.first_name +
                                        " " +
                                        item.attributes.last_name,
                                      surgeon_id: item.id,
                                    }))
                                  }
                                >
                                  <Text style={styles.listText}>
                                    {item.attributes.first_name +
                                      " " +
                                      item.attributes.last_name}
                                  </Text>
                                </TouchableOpacity>
                              )}
                            />
                          )}
                        </View>
                      )}
                    </View>
                  </>
                  <Text style={styles.heading1}>Select CPT Code</Text>
                  <View>
                    <TextInput
                      placeholder="Search CPT Code"
                      placeholderTextColor="#b2bac6"
                      style={styles.input}
                      onFocus={() =>
                        setShowlist((preVal: any) => ({ ...preVal, cpt: true }))
                      }
                      onBlur={() =>
                        setShowlist((preVal: any) => ({
                          ...preVal,
                          cpt: false,
                        }))
                      }
                      onChangeText={(queryText) =>
                        handleSearch(queryText, "cpt")
                      }
                      value={Names.cpt}
                    />
                    {showList.cpt && (
                      <View>
                        {loader ? (
                          <ActivityIndicator
                            style={{
                              marginHorizontal: 30,
                              height: 60,
                              width: 60,
                            }}
                            size="large"
                            color="#4e3e71"
                          />
                        ) : (
                          <FlatList
                            data={cptCodes}
                            ListEmptyComponent={ListEmptyView()}
                            keyExtractor={(item: any) => item.id}
                            renderItem={({ item }) => (
                              <TouchableOpacity
                                style={styles.onselect}
                                onPress={() =>
                                  setNames((preVal) => ({
                                    ...preVal,
                                    cpt: item.attributes.code,
                                  }))
                                }
                              >
                                <Text style={styles.listText}>
                                  {item.attributes.code}
                                </Text>
                              </TouchableOpacity>
                            )}
                          />
                        )}
                      </View>
                    )}
                  </View>
                  <Text style={styles.heading1}>Select Start Date</Text>
                  <View style={styles.pickerContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        style={{ marginLeft: 10 }}
                        openTo="date"
                        format="MM/dd/yyyy"
                        views={["year", "month", "date"]}
                        value={selectedDate.start}
                        onChange={(date: any) =>
                          handleDateChange(date, "start")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </View>
                  <Text style={styles.heading1}>Select End Date</Text>
                  <View style={styles.pickerContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        style={{ marginLeft: 10 }}
                        openTo="date"
                        format="MM/dd/yyyy"
                        views={["year", "month", "date"]}
                        value={selectedDate.end}
                        onChange={(date: any) => handleDateChange(date, "end")}
                      />
                    </MuiPickersUtilsProvider>
                  </View>
                  <TouchableOpacity
                    style={styles.reportBtn}
                    onPress={() => handleSubmit()}
                  >
                    <Text style={styles.reportText}>Get Report </Text>
                  </TouchableOpacity>
                </View>
              )}
            </Formik>
            {loading ? (
              <ActivityIndicator
                style={{ margin: "auto", height: 100, width: 100 }}
                size="large"
                color="#4e3e71"
              />
            ) : (
              <View style={styles.tableView}>
                {apiReport.length ? (
                  <>
                    <Text style={styles.heading3}>
                      Data from date range{" "}
                      {new Date(selectedDate.start).toLocaleDateString()} -{" "}
                      {new Date(selectedDate.end).toLocaleDateString()}
                    </Text>

                    <View>
                      <ScrollView showsHorizontalScrollIndicator={true}>
                        {tableRenderer()}
                      </ScrollView>
                    </View>
                    <TouchableOpacity
                      style={styles.reportBtn}
                      onPress={(e) => exportToCsv(e)}
                    >
                      <Text style={styles.reportText}>Export to CSV</Text>
                    </TouchableOpacity>
                  </>
                ) : (
                  <View style={styles.emptyMob}>
                    <Text style={styles.heading}>No Record Found</Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      ) : (
        <View style={styles.desktopContainer}>
          <DesktopHeader
            heading="Generate Bookings Report"
            navigation={navigation}
          />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={styles.outerCardMain}>
              <View style={styles.outerCardWeb}>
                <View style={styles.innerWebRow}>
                  <Formik
                    initialValues={{
                      status: "",
                    }}
                    validationSchema={validation_Schema}
                    onSubmit={(values: any, actions: any) => {
                      getBookings(values);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                      handleSubmit,
                    }) => (
                      <View style={styles.formikView}>
                        <Text style={styles.heading1}>Select Status</Text>
                        <View style={styles.pickerContainer}>
                          <Picker
                            selectedValue={values.status}
                            style={styles.picker}
                            onValueChange={handleChange("status")}
                          >
                            <Picker.Item value="" label="Select Status :-" />
                            <Picker.Item value="confirmed" label="Active" />
                            <Picker.Item value="pending" label="In-active" />
                          </Picker>
                        </View>
                        <>
                          <Text style={styles.heading1}>Select Surgeon</Text>

                          <View>
                            <TextInput
                              placeholder="Search Surgeon "
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onFocus={() =>
                                setShowlist((preVal: any) => ({
                                  ...preVal,
                                  surgeon: true,
                                }))
                              }
                              onBlur={() =>
                                setShowlist((preVal: any) => ({
                                  ...preVal,
                                  surgeon: false,
                                }))
                              }
                              onChangeText={(queryText) =>
                                handleSearch(queryText, "surgeon")
                              }
                              value={Names.surgeon}
                            />
                            {showList.surgeon && (
                              <View>
                                {loader ? (
                                  <ActivityIndicator
                                    style={{
                                      marginHorizontal: 30,
                                      height: 60,
                                      width: 60,
                                    }}
                                    size="large"
                                    color="#4e3e71"
                                  />
                                ) : (
                                  <FlatList
                                    data={surgeons}
                                    ListEmptyComponent={ListEmptyView()}
                                    keyExtractor={(item: any) => item.id}
                                    renderItem={({ item }) => (
                                      <TouchableOpacity
                                        style={styles.onselect}
                                        onPress={() =>
                                          handlePress(item, "surgeon")
                                        }
                                      >
                                        <Text style={styles.listText}>
                                          {item.attributes.first_name +
                                            " " +
                                            item.attributes.last_name}
                                        </Text>
                                      </TouchableOpacity>
                                    )}
                                  />
                                )}
                              </View>
                            )}
                          </View>
                        </>

                        <Text style={styles.heading1}>Select CPT Code</Text>
                        <View>
                          <TextInput
                            placeholder="Search CPT Code  "
                            placeholderTextColor="#b2bac6"
                            style={styles.input}
                            onFocus={() =>
                              setShowlist((preVal: any) => ({
                                ...preVal,
                                cpt: true,
                              }))
                            }
                            onBlur={() =>
                              setShowlist((preVal: any) => ({
                                ...preVal,
                                cpt: false,
                              }))
                            }
                            onChangeText={(queryText) =>
                              handleSearch(queryText, "cpt")
                            }
                            value={Names.cpt}
                          />
                          {showList.cpt && (
                            <View>
                              {loader ? (
                                <ActivityIndicator
                                  style={{
                                    marginHorizontal: 30,
                                    height: 60,
                                    width: 60,
                                  }}
                                  size="large"
                                  color="#4e3e71"
                                />
                              ) : (
                                <FlatList
                                  data={cptCodes}
                                  ListEmptyComponent={ListEmptyView()}
                                  keyExtractor={(item: any) => item.id}
                                  renderItem={({ item }) => (
                                    <TouchableOpacity
                                      style={styles.onselect}
                                      onPress={() =>
                                        setNames((preVal) => ({
                                          ...preVal,
                                          cpt: item.attributes.code,
                                        }))
                                      }
                                    >
                                      <Text style={styles.listText}>
                                        {item.attributes.code}
                                      </Text>
                                    </TouchableOpacity>
                                  )}
                                />
                              )}
                            </View>
                          )}
                        </View>
                        <Text style={styles.heading1}>Select Start Date</Text>
                        <View style={styles.pickerContainer}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              style={{ marginLeft: 10 }}
                              openTo="date"
                              format="MM/dd/yyyy"
                              views={["year", "month", "date"]}
                              value={selectedDate.start}
                              onChange={(date: any) =>
                                handleDateChange(date, "start")
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </View>
                        <Text style={styles.heading1}>Select End Date</Text>
                        <View style={styles.pickerContainer}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              style={{ marginLeft: 10 }}
                              openTo="date"
                              format="MM/dd/yyyy"
                              views={["year", "month", "date"]}
                              value={selectedDate.end}
                              onChange={(date: any) =>
                                handleDateChange(date, "end")
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </View>
                        <TouchableOpacity
                          style={styles.reportBtn}
                          onPress={() => handleSubmit()}
                        >
                          <Text style={styles.reportText}>Get Report </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </Formik>
                  {loading ? (
                    <ActivityIndicator
                      style={{ margin: "auto", height: 100, width: 100 }}
                      size="large"
                      color="#4e3e71"
                    />
                  ) : (
                    <View style={styles.tableView}>
                      {apiReport.length ? (
                        <>
                          <Text style={styles.heading3}>
                            Data from date range{" "}
                            {new Date(selectedDate.start).toLocaleDateString()}{" "}
                            - {new Date(selectedDate.end).toLocaleDateString()}
                          </Text>
                          <View>
                            <ScrollView
                              showsHorizontalScrollIndicator={false}
                              style={{ overflow: "scroll", height: 450 }}
                            >
                              {tableRenderer()}
                            </ScrollView>
                          </View>
                          <View style={styles.generateBtn}>
                            <TouchableOpacity
                              style={styles.reportBtn}
                              onPress={(e) => exportToCsv(e)}
                            >
                              <Text style={styles.reportText}>
                                Export to CSV
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </>
                      ) : (
                        ListEmptyViewTable()
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  outerCardMain: { flex: 0.85, overflowY: "scroll" },
  generateBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  emptyMob: { display: "flex", margin: "auto", paddingVertical: 50 },
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  topWebHeading: {
    borderBottomWidth: 2,
    borderBottomColor: "#ededed",
    paddingHorizontal: 26,
    paddingBottom: 20,
  },
  webHeading: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#4e3e71",
  },
  innerWebRow: {
    display: "flex",
    flexDirection: "row",
  },
  scrollTableWeb: {
    flex: 1,
    flexDirection: "column",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  headingh: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  heading: {
    marginTop: 30,
    marginHorizontal: 20,
    color: "#b2bac6",
    fontSize: 16,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  heading2: {
    margin: "auto",
    color: "#b2bac6",
    fontSize: 18,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  onselect: {
    maxWidth: "60%",
  },
  listText: {
    color: "#4e3e71",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: "#e5e5e5",
    borderBottomWidth: 1,
    marginHorizontal: 2,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#e5e5e5",
    borderBottomWidth: 1,
  },
  tableHeading: {
    borderRadius: 6,
    paddingHorizontal: 5,
    flex: 1,
    minWidth: 120,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    outlineStyle: "none",
    height: 50,
    borderRadius: 6,
    maxWidth: "60%",
    paddingLeft: 10,
    borderColor: "#e5e5e5",
    borderWidth: 1,
  },
  tableHeadingText: {
    color: "#4e3e71",
    fontSize: 16,
    minWidth: 120,
    fontWeight: "bold",
    paddingVertical: 10,
    paddingHorizontal: 2,
    backgroundColor: "#e5e5e5",
    borderLeftWidth: 1,
    borderLeftColor: "grey",
  },
  heading1: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    paddingVertical: 10,
  },
  heading3: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    paddingVertical: 20,
    textAlign: "center",
  },
  reportBtn: {
    maxWidth: "60%",
    backgroundColor: "#4e3e71",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 6,
    marginVertical: 10,
  },
  tableData: {
    color: "#4e3e71",
    flex: 0.8,
    width: Scale(20),
    textAlign: "left",
    fontSize: 14,
    paddingVertical: 3,
    backgroundColor: "pink",
  },
  formikView: {
    flex: 0.3,
    flexDirection: "column",
  },
  tableView: {
    flex: 0.7,
    flexDirection: "column",
  },
  reportText: {
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  outerCard: {
    flex: 1,
    margin: 20,
  },
  outerCardWeb: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 15,
    margin: 15,
    flexDirection: "column",
    minHeight: "auto",
  },
  pickerContainer: {
    borderWidth: 1,
    borderRadius: 6,
    maxWidth: "60%",
    borderColor: "#e5e5e5",
    paddingVertical: 2,
  },
  picker: {
    height: 50,
    color: "#4e3e71",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
});
// Customizable Area End
