import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      TextInput,
      View,
      Image,
      ScrollView,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import CustomPopUp from "../../../framework/src/CustomPopUp"
import {getAxiosAPI, deleteAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock"
import scale, { verticalScale } from "../../../framework/src/Scale";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [loader, setLoader] = useState(false);

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      const getHospitals =  async (search: any = '') => {
            setLoader(true)
    let url = `/account_block/accounts/account_detail`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.attributes.facilities.data)
          }
        })
          };
      useEffect(() => { 
            getHospitals()
            }, []);

            const DeleteFacility = async (id:any) => {
                  let url = `/bx_block_hospitals/facilities/${id}`
                  await deleteAxiosAPI(url).then((res: any) => {
                        if(res.error){
                          let msg = getErrorMessage(res.data.errors[0])
                          setPopup(true)
                          setMessage(msg)
                          setLoader(false)
                        } else {
                          getHospitals()
                        }
                      })
              
            }


      const goToNextPage = (item: any) => {
            localStorage.setItem('previous_booking', JSON.stringify(item));
            navigation.navigate('SurgeryDetails')
      }

     

      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes.hospital.data.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
            } else {
                  setsearchData(data);
            }
      };


      const ListEmptyView = () => {
            return (
                  <View>
                        <Text style={styles.heading}>No Facility Found</Text>
                  </View>

            )
      }

      const ClosePopUp = () => {
            setPopup(false)
          }
  
      return (
            <View style={styles.desktopContainer}>
                  {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
                  <DesktopHeader heading={'My Facility'}  navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>My Facility </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Enter Facility Name "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>
                                          <TouchableOpacity style={styles.button} onPress={() => navigation.navigate("AddFacility")}>
                                                <Text style={styles.buttonText}>Add Facility</Text>
                                          </TouchableOpacity>
                                    </View>
                              </View>

                              {loader ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    : <View style={styles.card}>
                                    <View style={{ flex: 1, }}>
                                          {
                                                (query.length > 0 && searchData.length > 0) ?
                                                      <View style={styles.add}>
                                                            <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                      </View>
                                                      :
                                                      (query.length > 0 && searchData.length === 0) ?
                                                            <View style={styles.add}>
                                                                  <Text style={styles.resultText}>Searched Facility not found</Text>
                                                            </View> : null
                                          }

                                          <FlatList
                                                style={{ height: 410 }}
                                                ListEmptyComponent={ListEmptyView()}
                                                data={query.length < 1 ? data : searchData}
                                                keyExtractor={(item: any) => item.id}
                                                horizontal={false}
                                                numColumns={3}
                                                renderItem={({ item }) => (
                                                      <View>
                                                      <View style={styles.listItem}>
                                                          <View style={{flexDirection: 'row' , flex: 0.9}}>
                                                            <img src={item.attributes.hospital.data?.attributes.logo ? item.attributes.hospital.data.attributes.logo : require("../assets/hospital copy.png")} style={{ height: 56, width: 56}}/> 
                                                                <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                                                 <Text style={styles.names}>{item?.attributes?.hospital?.data?.attributes?.name}</Text>
                                                                      <Text style={styles.role} numberOfLines={2}>{item?.attributes?.hospital?.data?.attributes?.address}</Text>
                                                                  </View>
                                                          </View>
                                                          {/* {
                                                            role_id === '1' ?  */}
                                                            <TouchableOpacity onPress={() => DeleteFacility(item?.attributes?.hospital?.data?.id)} style={{marginVertical: 'auto', flex: 0.1}}>
                                                            <img src={require("../assets/delete.png")}  style={{height: 26, width: 26 }}/>      
                                                      </TouchableOpacity>
                                                      {/* : null
                                                          } */}
                                                         
                                                      </View>
                                                  </View>
                                                )}
                                          />
                                    </View>

                              </View> }

                        </View>
                        </View>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 10,
            paddingHorizontal: 20,
            marginHorizontal: 20,
            marginVertical: 20,
            backgroundColor: '#4e3e71',
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
      listItem: {
            height: 88,
            marginHorizontal:12,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(65),
            
          },
          names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11
            
          },
      paraDis: {
            color: '#8e9aab',
            paddingHorizontal: 15,
            fontSize: 14
      },
      cardHeading: {
            paddingHorizontal: 15,
            color: '#4e3e71',
            fontSize: 17,
      },
      bookingList: {
            padding: 20,
            width: 400,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
      },
      statusBTn: {
            paddingVertical: 5,
            paddingHorizontal: 10,
            fontSize: 12,
            backgroundColor: '#4e3e71',
            color: '#fff',
            borderRadius: 12
      },
      bookingList2: {
            paddingHorizontal: 20,
            paddingVertical: 15,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: '100%'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'

      }

})