import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getErrorMessage,
  patchAxiosAPI,
  postAxiosAPI,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../framework/src/CustomPopUp";

const validation_Schema = yup.object({
  otp: yup
    .string()
    .required("OTP is required")
    .matches(/^[0-9]+$/, "Only numbers are allowed")
    .min(4, "Must be atleast 4 digits"),
});

export default function ConfirmByOtp({
  handleClose,
  navigation,
}: {
  handleClose: any;
  navigation: any;
}) {
  const otpData = JSON.parse(
    localStorage.getItem("EditingProfileOtpData") || "{}"
  );

  const [Token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const [counter, setCounter] = useState<any>(60)


  const ClosePopUp = () => {
    if (message === "Profile Updated Successfully! ") {
      handleClose();
    } else {
      setPopup(false);
    }
  };

  const isTimer = localStorage.getItem("isTimer");

  setTimeout(function() {
    if (!isTimer && counter >= 0) {
      setCounter(counter - 1);
      localStorage.setItem("timer", counter);
    } else {
      localStorage.removeItem("timer");
    }
  }, 1000);

  const resendCode = async () => {
    setCounter(60);
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let value = otpData.sendedAt;
    if (value.match(regexEmail)) {
      let postData1 = {
        email: value,
      };
      let url = "/account_block/accounts/update_email_phone";
      await patchAxiosAPI(url, postData1).then((res: any) => {
        if (res.error) {
          let msg = getErrorMessage(res.data.errors[0]);
          setMessage(msg);
          setPopup(true)
        } else {
          setToken(res.data.email_otp.meta.token);
        }
      });
    } else {
      let postData1 = {
        full_phone_number: value,
      };
      let url = "/account_block/accounts/update_email_phone";
      await patchAxiosAPI(url, postData1).then((res: any) => {
        if (res.error) {
          let msg = getErrorMessage(res.data.errors[0]);
          setMessage(msg);
          setPopup(true)
        } else {
          setToken(res.data.sms_otp.meta.token);
        }
      });
    }
  };

  const submitData = async (otp: any) => {
    setLoader(true);
    let postData;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let value = otpData.sendedAt;
    if (value.match(regexEmail)) {
       postData = {
        email: otpData.sendedAt,
        token: Token === "" ? otpData.token : Token,
        otp_code: parseInt(otp),
    }
    } else {
      postData = {
      full_phone_number: otpData.sendedAt,
       token: Token === "" ? otpData.token : Token,
       otp_code: parseInt(otp),
   }
    }
    let url = `/account_block/otp_confirmations/update_profile_otp_confirm`;
    await postAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setMessage(msg);
        setPopup(true);
         localStorage.removeItem("timer");
        setLoader(false);
      } else {
         localStorage.removeItem("timer");
        if (window.screen.width < 950) {
          navigation.navigate("Profile");
        } else {
          
          setLoader(false);
          setPopup(true);
          setMessage("Profile Updated Successfully! ");
        }
      }
    });
  };
  return (
    <Formik
      initialValues={{ otp: "" }}
      validationSchema={validation_Schema}
      onSubmit={(values, actions) => {
        submitData(values.otp);
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
      }) => (
        <View
          style={
            window.screen.width < 950
              ? styles.container
              : styles.desktopContainer
          }
        >
          {popup && (
            <CustomPopUp
              ClosePopUp={ClosePopUp}
              btnText={"Back"}
              message={message}
            />
          )}
          <View
            style={window.screen.width < 950 ? styles.card : styles.cardDesktop}
          >
            {window.screen.width < 950 ? (
              <img
                src={require("../assets/illustration-register-copy@3x.png")}
                style={{
                  width: 216,
                  height: 234,
                  position: "absolute",
                  top: -165,
                  left: 180,
                  zIndex: 99,
                }}
              />
            ) : null}
            <View style={styles.bottomvalues}>
              <Text style={styles.heading1}>Verify Code</Text>
              <Text style={styles.lableText}>
                We have sent you the code at{" "}
                "{otpData.sendedAt}"
              </Text>
            </View>

            <OtpInput
              numInputs={4}
              inputStyle={{
                margin: 10,
                height: 50,
                width: 50,
                borderRadius: 5,
                fontSize: 20,
                backgroundColor: "#e8fafe",
              }}
              onChange={handleChange("otp")}
              value={values.otp}
            />
            {touched.otp && errors.otp ? (
                  <Text style={{ color: "red" }}>{errors.otp}</Text>
                ) : null}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <Text
                  style={{
                    color: "rgb(72, 100, 132)",
                    paddingTop: 20,
                    fontSize: 18,
                  }}
                >
                  Did not receive the code?
                </Text>
                {counter > 0 && !isTimer ? (
                  <View>
                    <Text style={styles.resendCode_unActive}>Resend Code</Text>
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => resendCode()}
                    disabled={false}
                  >
                    <Text style={styles.resendCode}>Resend Code</Text>
                  </TouchableOpacity>
                )}
              </View>
              <View>
                {counter > 0 && !isTimer ? (
                  <Text
                    style={{ fontSize: 16, color: "#112950", paddingTop: 22 }}
                  >
                    {counter < 10 ? "00:0" + counter : "00:" + counter}
                  </Text>
                ) : null}
              </View>
            </View>
            <TouchableOpacity
              disabled={loader ? true : false}
              onPress={() => handleSubmit()}
            >
              <View style={loader ? styles.buttonViewDisable : styles.button}>
                <Text style={styles.buttonText}>Continue</Text>
                {loader && (
                  <ActivityIndicator
                    style={styles.loader}
                    size="large"
                    color="#2e2442"
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Formik>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column-reverse",
    backgroundColor: "#4e3e71",
  },
  loader: { position: "absolute", left: 0, right: 0, bottom: 6 },
  desktopContainer: {
    flex: 1,
    backgroundColor: "#ffff",
  },
  card: {
    position: "relative",
    borderTopRightRadius: 24,
    backgroundColor: "#e8fafe",
    paddingHorizontal: 24,
  },
  cardDesktop: {
    backgroundColor: "white",
    margin: 20,
  },
  resendCode: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontWeight: "bold",
  },
  resendCode_unActive: {
    color: "#767676",
    paddingVertical: 5,
    fontWeight: "bold",
  },
  bottomvalues: {
    paddingBottom: 20,
  },
  lableText: {
    color: "#4e3e71",
    justifyContent: "center",
    fontSize: 18,
  },
  heading: {
    marginVertical: 5,
    marginHorizontal: "auto",
    color: "#0579ed",
    fontSize: 30,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  heading1: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 96,
    paddingBottom: 20,
  },
  OtpInputView: {
    marginVertical: 10,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 150,
  },
  buttonViewDisable: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#aa9cc9",
    marginBottom: 38,
    marginTop: 150,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
});