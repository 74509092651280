// Customizable Area Start
import React, {useState, useEffect} from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import NewHeader from "./Header";
import Footer from "./NewFooter";
import DesktopAllBookings from "./DesktopAllBookings";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import { asyncLocalStorage, getTimeStemp } from "../../../framework/src/Utilities";
import { hideName, getBookingDate, getBookingTime } from "../../../framework/src/Utilities";
import {getAxiosAPI, getErrorMessage} from "../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../framework/src/CustomPopUp";



export default function Dashboard({ navigation}: { navigation: any}) {
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [userData, setUserData] = useState<any>({})
  const [message, setMessage] = useState("");
const [popup, setPopup] = useState(false)

  const [open , setOpen] = useState(false);




  const getOrdersForSelectDate = async (date: any) => {
    setLoading(true)
    setSelectedDate(date)
    const startDate = getTimeStemp(date)
    const endDate = startDate + 86399999
    let url = `/bx_block_order/orders?start_day=${startDate}&end_day=${endDate}`
    await getAxiosAPI(url).then((res: any) => {
      if(res.error){
        let msg = getErrorMessage(res.data.errors[0])
        setPopup(true)
        setMessage(msg)
        setLoading(false)
      } else {
        setLoading(false)
        setData(res.data.data.sort(
          function (a: any, b: any) 
          { return 
            (a.attributes.booking_datetime > b.attributes.booking_datetime)
             ? 1 : ((b.attributes.booking_datetime > a.attributes.booking_datetime) 
             ? -1 : 0); }))
      
      }
    });
  }

  const saveData = async (data : any) => {
    await asyncLocalStorage.setItem("userStatus", data.status)
    setUserData(data)
    localStorage.setItem('loggedinUserInfo', JSON.stringify(data))
  }



  const getUserData = async () => {
    let url = '/account_block/accounts/account_detail'
    await getAxiosAPI(url).then((res: any) => {
      if(res.error){
        let msg = getErrorMessage(res.data.errors[0])
        setPopup(true)
        setMessage(msg)
        setLoading(false)
      } else {
        saveData(res.data.data.attributes)
        setLoading(false)
      }
    });

  }


  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if(token){
      let date = new Date()
      getOrdersForSelectDate(date)
      getUserData()
    }else{
      window.localStorage.clear();
      window.location.href = "/";
    }

  
  }, []);

  const handleClose = () => {
    window.localStorage.clear();
    window.location.href = "/";
  }

  const ListEmptyView = () => {
    let date = new Date()
    let dateToShow = new Date(selectedDate).toDateString()
    if (date === selectedDate) {
      return (
        <View style={{ marginBottom: 80 }}>
          <Text style={styles.heading}>No Bookings For Today</Text>
          <TouchableOpacity onPress={() => navigation.navigate('ExBooking',{check_param: "test"})}>
            <Text style={styles.heading2}>See All Bookings</Text>
          </TouchableOpacity>
        </View>

      )   
    } else {
      return (
        <View style={{ marginBottom: 80 }}>
          <Text style={styles.heading}>No Bookings Found</Text>
          {/* <Text style={styles.heading3}>{dateToShow}</Text> */}
          {window.screen.width < 950 && <TouchableOpacity onPress={() => navigation.navigate('ExBooking',{check_param: "test"})}>
            <Text style={styles.heading2}>See All Bookings</Text>
          </TouchableOpacity>}
        </View>
      )
      }
     
    }

    const role_id = JSON.parse(localStorage.getItem('role') || '{}')
   
      const BookingStatus = (item: any) => {
    if (item.attributes.confirmed_by_bc === true &&
    item.attributes.confirmed_by_material_manager === true &&
    item.attributes.confirmed_by_sales_rep === true) {
      return "Confirmed"
    } else {
      return "Pending"
    }
    }
      
    
    const ClosePopUp = () => {
      setPopup(false)
      window.localStorage.clear();
      window.location.href = "/";
    }

  const changeRoute = (route: any) => {
    // state({ left: false })
    navigation.navigate(route)
  }
  
  const goToNextPage = (item: any) => {
    localStorage.setItem('previous_booking', JSON.stringify(item));
    navigation.navigate('SurgeryDetails')
  }
    
  const profile_image = localStorage.getItem('Profile_image')
    
    return (
      //Merge Engine DefaultContainer
       window.screen.width < 950 ? 
       <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
         {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
         <View style={{ flex: 0.92,flexDirection: 'column'}}>
         <View style={{backgroundColor: '#4e3e71',}}>
         <NewHeader userData={userData} navigation={navigation}/>
         </View>
         <View style={{flexDirection: 'row', paddingVertical: 10}}>
         <TouchableOpacity onPress={() =>  navigation.navigate('Profile')}>
                   <img src={userData.profile_image ? userData.profile_image : require("../assets/avatar-chat1.png")} style={{ height: 48, width: 48, borderRadius: 24, marginLeft: 24}} />
                   </TouchableOpacity>
               <View style={{flexDirection: 'column', paddingHorizontal: 12}}>
                     <Text style={{ color: '#4e3e71', fontSize: 17 }}>Hi {userData.first_name && userData.first_name + " " + userData.last_name},</Text>
                 <Text style={{color: '#4e3e71', fontSize: 14}}>Welcome!</Text>          
               </View>
           </View>
        <View style={styles.calendar}>
         <Calendar
         onChange={(date: any) => getOrdersForSelectDate(date)}
         value={selectedDate}
         className="calenderNew"
       />
         </View>
         <TouchableOpacity onPress={() => navigation.navigate('ExBooking')}>
       <View style={styles.button}>
         <Text style={styles.buttonText}>See All Surgeries </Text>
       </View>
     </TouchableOpacity>
      <Text style={{
       fontSize: 18,
       color: '#4e3e71',
      paddingHorizontal: 24,}}>Surgeries on selected date </Text>
      <View style={{ flex: 1}}>
      {loading ?  
        <ActivityIndicator 
        style={{margin: 'auto', height: 100, width: 100,}} 
        size="large" 
        color="#4e3e71" 
        /> :
        <ScrollView>
        <FlatList
                data={data}
                ListEmptyComponent={ListEmptyView()}
                keyExtractor={(item:any) => item.id}
                renderItem={({ item }) => (
                  <TouchableOpacity onPress={() => goToNextPage(item)}>
                        <View style={{marginVertical: 8}}>
                          <View style={styles.bookingList}>
                              <View style={{flexDirection: 'row'}}>
                                  <img 
                                 src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                  style={{height: 70, width: 70, borderRadius: 12}} />
                                  <View style={{flexDirection: 'column', marginVertical: 'auto'}}>
                                      <Text style={styles.cardHeading}>{role_id == '4' ? hideName(item.attributes?.first_name, item.attributes?.last_name) : item.attributes?.first_name+" "+item.attributes?.last_name} </Text>
                                      <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                                  </View>
                              </View>
                              <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15}}>
                                  <Text style={{color: '#b2bac6', fontSize: 12}}>Surgery Date & time:</Text>
                                  <Text style={{color: '#5e6f88', fontSize: 12}}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                              </View>
                          </View>
                              <View style={styles.bookingList2}>
                                  <Text style={styles.statusBTn}>{BookingStatus(item)}</Text>
                                  <Text style={{fontSize: 12, color: '#4e3e71'}}>View Details</Text>
                                </View>  
                        </View>
                        </TouchableOpacity>
                )}
                />
          
        </ScrollView>
        }
      </View>
        </View>
         {/* FOOTER */}
         <View style={{flex: 0.08, flexDirection: 'row', justifyContent: 'center'}}>
         {userData.status ? <Footer userData={userData}  navigation={navigation} /> : null}
           </View>
          
       </View>
        :
        <View style={styles.desktopContainer}>
          {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
          <DesktopHeader heading={'Dashboard'} navigation={navigation} />
          <View style={styles.bottomDesktopCard}>
      {/* <DesktopMenue navigation={navigation} />  */}
            {userData.status ? 
    <DesktopMenue navigation={navigation} /> 
        : null}
            <View style={{flex: 0.85, backgroundColor: 'white'}}>
              <View style={styles.desktopCard}>
                <View style={{flex: 0.69, flexDirection: 'column'}}>
                  <View style={{ borderColor: 'grey', borderRadius: 12, borderWidth: 1, padding: 5 ,margin: 10 }}>  
                    <Text style={{
                       fontSize: 16,
                        color: '#4e3e71',
                    }}>Select Date</Text>
                    <View style={{ margin: 10,padding: 5,  borderColor: 'grey', borderRadius: 12,borderWidth: 1, }}>
                      <View>
                         <Calendar
                          onChange={(date: any) => getOrdersForSelectDate(date)}
                          value={selectedDate}
                          className="calenderDesktop"
                        />
                        </View>
                    </View>
                    <Text style={{
                       fontSize: 16,
                        color: '#4e3e71',
                    }}>Surgery on Selected Date</Text>
                    <View style={{ maxHeight: 220}}>
                     
                      {loading ? <ActivityIndicator style={{ margin: 'auto', height: 100, width: 100, }} size="large" color="#4e3e71" /> :
                        <FlatList
                          data={data}
                          ListEmptyComponent={ListEmptyView()}
                        keyExtractor={(item:any) => item.id}
                        renderItem={({ item }) => (
                         
                                <TouchableOpacity onPress={() => goToNextPage(item)}>
                                      <View style={{marginVertical: 15}}>
                                        <View style={styles.bookingList}>
                                            <View style={{flexDirection: 'row'}}>
                                                <img src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                                style={{height: 70, width: 70, borderRadius: 12}} />
                                                <View style={{flexDirection: 'column', marginVertical: 'auto'}}>
                                                    <Text style={styles.cardHeading}>{role_id == '4' ? hideName(item.attributes?.first_name, item.attributes?.last_name) : item.attributes?.first_name+" "+item.attributes?.last_name} </Text>
                                                    <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                                                </View>
                                            </View>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15}}>
                                                <Text style={{color: '#b2bac6', fontSize: 12}}>Surgery Date & time:</Text>
                                                <Text style={{color: '#5e6f88', fontSize: 12}}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                                            </View>
                                        </View>
                                            <View style={styles.bookingList2}>
                                                <Text style={styles.statusBTn}>{BookingStatus(item)}</Text>
                                                <Text style={{fontSize: 12, color: '#4e3e71'}}>View Details</Text>
                                              </View>  
                                      </View>
                            </TouchableOpacity>
                        )}/>}
                    </View>
                  </View>
                </View>
                <View style={{flex: 0.3,}}>
                  <View style={{ borderColor: 'grey', borderRadius: 12, borderWidth: 1, paddingVertical: 10, margin: 10, marginLeft: 0 , minHeight: '84%'}}>
                    <Text style={{
                      fontSize: 16,
                      paddingHorizontal: 15,
                        color: '#4e3e71',
                    }}>All Bookings</Text>
                    <View>
                      <DesktopAllBookings navigation={navigation} />
                    </View>
                   </View>
                </View>
              </View>
            </View>
          </View>
          
         
        </View>
      //Merge Engine End DefaultContainer
    );
  }


// Customizable Area Start
const styles = StyleSheet.create({
  desktopContainer: {
    flex: 1,
    flexDirection: 'column'
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: 'row'

  },
  menuWrapper: {
    // paddingTop: 
  },
  menuName: {
    color: 'white',
    fontSize: 15,
    paddingHorizontal: 5,
    marginVertical: 'auto'
  },
  desktopMenu: {
    flex: 0.15,
    flexDirection: 'column',
    backgroundColor: '#4e3e71',
    paddingTop: 20

  },
  leftHeader: {
    flex: 0.15,
    color: '#4e3e71',
    fontSize: 30,
    padding: 'auto',
    backgroundColor: '#e8fafe'
  },
  rightHeader: {
    flex: 0.85,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 26,
    backgroundColor: '#4e3e71'
  },
  desktopHeader: {
    flexDirection: 'row',
    height: 80
  },
  containerNew:{
    flex: 0.92,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  statusBTn:{
    paddingVertical: 5, 
    paddingHorizontal: 10,
    fontSize: 12,
    backgroundColor: '#4e3e71', 
    color: '#fff', 
    borderRadius: 12
  },
  bookingList: {
    padding: 15,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
   borderWidth: 1,
   borderTopLeftRadius: 12,
   borderTopRightRadius: 12
  },
  bookingList2: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
   borderWidth: 1,
   borderBottomLeftRadius: 12,
   borderBottomRightRadius: 12,
   flexDirection: 'row',
   justifyContent: 'space-between'
  },
  desktopCard: {
    flex: 1,
    flexDirection: 'row'
  },
  TopView: {
    // flex: 0.55,
  },
  bottomView: {
    // flex: 0.45
  },
  outerCard : {
    flex: 1,
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 15,
    backgroundColor: '#fff',
    marginHorizontal: 5,
    marginVertical: 6,
  },
  heading: {
    marginTop: 100,
    marginHorizontal: 'auto',
    color: '#b2bac6',
    fontSize: 24,
    fontWeight: 'bold',
    flexDirection : 'row',
    justifyContent: 'center'
  },
  heading2: {
    marginHorizontal: 'auto',
    color: '#4e3e71',
    fontSize: 20,
    fontWeight: 'bold',
    flexDirection : 'row',
    justifyContent: 'center'
  },
  heading3: {
    marginHorizontal: 'auto',
    color: '#4e3e71',
    fontSize: 12,
    fontWeight: 'bold',
    flexDirection : 'row',
    justifyContent: 'center'
  },
  innerCard: {
    margin:10,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: "#eff8ff",
    padding: 25,

  },
  paraDis: {   
    color: '#8e9aab',
    paddingHorizontal: 15,
    fontSize: 14
  },
  para: {
    marginVertical: 35,    
    color: '#0778df',
    marginLeft: 60,
  },
  cardHeading: {
    paddingHorizontal: 15,
    color: '#4e3e71',
    fontSize: 17,
},   
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 20
  },
  headerText: {
    color: '#0579ed',
    fontSize: 20
  },
  calendar: {
    flexDirection: 'row',
    justifyContent: 'center',
  
  },
  button: {
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 50,
    marginHorizontal: 24,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  buttonH: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 24,
    backgroundColor: '#ffff',
    // marginBottom: 10,
    // marginTop: 10
  },
  buttonTextH: {
    color: '#4e3e71',
    fontSize: 17,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    //padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffff"
  },
  dashboardItemView: {
    flex: 1,
    marginHorizontal: 5,
    marginVertical: hp("2%"),
    width: Platform.OS === "web" ? "80vw" : wp("90%"),
    maxWidth: Platform.OS === "web" ? 600 : wp("90%"),
    borderRadius: hp("3%"),
    backgroundColor: "#ffffff",
    shadowColor: "#c3c3c3",
    shadowOffset: {
      width: 2,
      height: 3
    },
    shadowOpacity: 0.6,
    shadowRadius: 5.62,
    elevation: 6
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
