// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      TextInput,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform,
      Picker,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import CMFPA from "./CMFP-1";
import scale, { verticalScale } from "../../../framework/src/Scale";
import ProgressBar from "./StepperComponent";
import { getAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const order = JSON.parse(localStorage.getItem('order') || '{}')
      const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand') || '[]')

      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [brand_id, setbrand_id] = useState()
      const [loader, setLoader] = useState(false);
      const [offset, setOffset] = useState(0);

      const getListOfManufac = async (search: any = '') => {
            setLoader(true)
            let url = `/bx_block_catalogue/brands?search=${search}&limit=6&offset=${offset}`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    setLoader(false)
                  } else {
                        setData(res.data.data)
                        setLoader(false)                  
                  }
                })
            }

            useEffect(() => { 
                  getListOfManufac();
                }, [offset]);


      const handleSearch = (text : any) => {
            setQuery(text)
            getListOfManufac(text)
          };

      const handleChange = (item: any) => {
            let newdata: any = data.map((x: any) => {

                  if (x.id === item.id) {
                        x.ischecked = true
                  } else {
                        x.ischecked = false
                  }
                  return x
            })
            setData(newdata)
            setbrand_id(item.id)
            // setHospital_name(item.attributes.name)

      };
      const navigateTo = () => {

      const order = JSON.parse(localStorage.getItem('order') || '{}')
      order.manfacturer_id = brand_id
      localStorage.setItem('order', JSON.stringify(order))
      localStorage.setItem('selectedBrand', JSON.stringify(data))
      navigation.navigate('SelectMaterialBrandCatergory')
      }


      const [selectedValue, setSelectedValue] = useState("Manufacturer");

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={`Choose ${selectedValue.split(" ")[0]}`} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                       
                        <View style={styles.outerCard}>
                        <View><ProgressBar activeStepCount={2}/></View>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Choose {selectedValue.split(" ")[0]}</Text>
                              <View style={{flexDirection: 'row'}}>
                              <View style={styles.searchBox}>
                                    <Picker
                                          selectedValue={selectedValue}
                                          style={styles.picker}
                                          onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
                                    >
                                          <Picker.Item label="Manufacturer" value="Manufacturer" />
                                          <Picker.Item label="Procedure List" value="Procedure List" />

                                    </Picker>
                                    <TextInput
                                          style={styles.input}
                                          placeholder="Search "
                                          autoCapitalize="none"
                                          autoCorrect={false}
                                          clearButtonMode="always"
                                          placeholderTextColor="#939ca3"
                                          value={query}
                                          onChangeText={queryText => handleSearch(queryText)}
                                    />
                                    <TouchableOpacity onPress={() => setQuery('')}>
                                          <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                    </TouchableOpacity>
                                   
                              </View>
                              <View style={styles.filterIcon}>
                                    <TouchableOpacity>
                                          <img src={require("../assets/filtericon.png")} style={{ height: 30, width: 30, paddingRight: 15 }} />
                                    </TouchableOpacity>
                                    </View>
                                    </View>
                              <View>
                                    {selectedValue === 'Manufacturer' ? 
                                          <View>
                                                { loader ? <ActivityIndicator
                                                      style={{ margin: 'auto', height: 100, width: 100, }}
                                                      size="large" color="#4e3e71" />
                                                      :
                                                <View style={styles.card}>
                                                      <View style={{ flex: 1 }}>
                                                            {
                                                                  (query.length > 0 && data.length > 0) ?
                                                                        <View style={styles.add}>
                                                                              <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                                        </View>
                                                                        :
                                                                        (query.length > 0 && data.length === 0) ?
                                                                              <View style={styles.add}>
                                                                                    <Text style={styles.resultText}>Searched Manufacturer not found, {'\n'}
                                                                                    </Text>
                                                                              </View> : null
                                                            }

                                                            <FlatList
                                                                  data={data}
                                                                  // style={{ height: 330 }}
                                                                  horizontal={false}
                                                                  numColumns={3}
                                                                  keyExtractor={(item: any) => item.id}
                                                                  renderItem={({ item }) => (
                                                                        <TouchableOpacity onPress={() => handleChange(item)}  >
                                                                              <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                                                                    <View style={{ flexDirection: 'row', flex: 0.9 }}>
                                                                                          <img src={require("../assets/manifac_img.png")} style={{ height: 56, width: 56 }} />
                                                                                          <View style={{ flexDirection: 'column', marginVertical: 'auto', }}>
                                                                                                <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>
                                                                                                <Text style={styles.role} numberOfLines={3}>{item.attributes.address}</Text>
                                                                                          </View>
                                                                                    </View>
                                                                                    <View style={{ marginVertical: 'auto', flex: 0.1 }}>
                                                                                          <img src={item.ischecked ? require("../assets/radio_active.png") : require("../assets/radio_unactive.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                                                                                    </View>
                                                                              </View>
                                                                        </TouchableOpacity>

                                                                  )}
                                                            />
                                                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                                                  {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-6)}><Text style={{ color: '#4e3e71'}}>{"< Prev"}</Text></TouchableOpacity>}
                                                                  {data.length < 6 ? null :  <TouchableOpacity onPress={() => setOffset(offset+6)}><Text style={{ color: '#4e3e71'}}>{"Next >"}</Text></TouchableOpacity>}
                                                            </View>
                                                      </View>
                                                      
                                                      {
                                                            brand_id ?
                                                                  <TouchableOpacity onPress={() => navigateTo()} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                        <View style={styles.button}>
                                                                              <Text style={styles.buttonText}>Continue</Text>
                                                                        </View>
                                                                  </TouchableOpacity>
                                                                  :
                                                                  
                                                                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                        <View style={styles.button0}>
                                                                              <Text style={styles.buttonText0}>Continue</Text>
                                                                        </View>
                                                                  </View>
                                                                  
                                                      }
                                                     
                                                </View>
                                                }
                                          </View> :
                                          <View>
                                                <CMFPA navigation={navigation}/>
                                          </View>
                                    }
                              </View>
                        </View>
                        </View>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button0: {
            borderRadius: 12,
            paddingVertical: 10,
            paddingHorizontal: 20,
            marginVertical: 5,
            backgroundColor: '#aa9cc9',
            // width: '20%',
            marginHorizontal: 5
      },
      buttonText0: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      listItem: {
            height: 80,
            // width: 380,
            marginHorizontal: 16,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      listItem_active: {
            height: 80,
            // width: 380,
            marginHorizontal: 16,
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(50),

      },
      names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11,
            width: scale(60),

      },

      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      filterIcon: {
            height: 48,
            width: 48,
            borderRadius: 24,
            padding: 8,
            borderWidth: 1,
            borderColor: '#4e3e71',
            marginVertical: 'auto',
            marginHorizontal: 'auto'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      picker: {
            height: 50,
            width: 160,
            color: 'white',
            paddingHorizontal: 15,
            backgroundColor: '#4e3e71',
            borderRadius: 50,
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: 950
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 10,
            margin: 10,
            flexDirection: 'column'

      }

})
// Customizable Area End