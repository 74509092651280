import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../../../framework/src/StorageProvider";
import React from "react";
// Customizable Area End

export interface Props {
  navigation: any;
  // id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  manufacturerName: string;
  materialCategory: string;
  materialName: string;
  division: string;
  catlogNo: string;
  barcode: string;
  gtinNo: string;
  description: any;
  price: any;
  imageData: any;
  discount_price: any;
  imagePath: string;
  errors: any;
  manufacturerList: any;
  materailCategorieList: any;
  loader: boolean;
  brandLoader: boolean;
  categoryLoader: boolean;
  popup: boolean;
  message: string;
  // Customizable Area End
}

interface SS {
  id: any;
}
 const EditMaterial = JSON.parse(localStorage.getItem('editMaterialData') || '{}')
export default class NewMaterialController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddMaterialId: any;
  apigetManufacturerNameId: any;
  apigetMaterailCategorieId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      manufacturerName: "",
      materialCategory: "",
      materialName: "",
      division: "",
      catlogNo: "",
      barcode: "",
      gtinNo: "",
      imagePath: EditMaterial?.attributes?.image ? EditMaterial?.attributes?.image : "",
      price: "",
      description:"",
      imageData: "",
      discount_price: "",
      errors: [],
      manufacturerList: [],
      materailCategorieList: [],
      loader: false,
      brandLoader: true,
      categoryLoader: false,
      popup: false,
      message: ''
    };

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAddMaterialId !== null &&
      this.apiAddMaterialId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAddMaterialId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({message: "Matrial added successfully!"})
        this.materialAddedSuccessfully();
      } else {
        //Check Error Response
        this.materialAddedFailed();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetManufacturerNameId !== null &&
      this.apigetManufacturerNameId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetManufacturerNameId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getManufacturerNameSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.getManufacturerNameFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetMaterailCategorieId !== null &&
      this.apigetMaterailCategorieId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetMaterailCategorieId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getMaterailCategoriesSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.getMaterailCategoriesNameFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formikRef : any = React.createRef();
  

  async componentDidMount() {
    super.componentDidMount();
    this.getManufacturerName();
    const EditMaterial = JSON.parse(localStorage.getItem('editMaterialData') || '{}')
    if(Object.keys(EditMaterial).length){
      this.formikRef.current.setValues({
        manufacturerName: EditMaterial.attributes.brand.data.id,
        materialCategory: EditMaterial.attributes.category.data.id,
        materialName: EditMaterial.attributes.name,
        division:  EditMaterial.attributes.division,
        description: EditMaterial.attributes.description,
        catlogNo:  EditMaterial.attributes.catalog_no,
        barcode:  EditMaterial.attributes.barcode ,
        gtinNo:  EditMaterial.attributes.gtin_no ,
        price: EditMaterial.attributes.price,
        discount_price:  EditMaterial.attributes.discount_price
      });
    }
  }
  getManufacturerName = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetManufacturerNameId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_catalogue/brands"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getManufacturerNameSuccess = (data: any) => {
    this.setState({ manufacturerList: data , brandLoader: false});
  };

  getManufacturerNameFail = () => {};
  getMaterailCategories = async (brandId: string) => {
    this.setState({categoryLoader: true})
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetMaterailCategorieId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories?brand_id=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getMaterailCategoriesSuccess = (data: any) => {
    this.setState({ materailCategorieList: data, categoryLoader: false });
  };

  getMaterailCategoriesNameFail = () => {};


  materialAddedSuccessfully = () => {
    this.resetForm()
    this.showAlert("Success", "Material created successfully");
    // await this.resetForm()
  };
  materialUpdatedSuccessfully = () => {
    this.showAlert("Success", "Material updated successfully");
    // await this.resetForm()
  };

  materialAddedFailed = () => {};
  resetForm = () => {
    this.setState({
      manufacturerName: "",
      materialCategory: "",
      materialName: "",
      division: "",
      catlogNo: "",
      barcode: "",
      gtinNo: "",
      imagePath: ""
    });
    // window.location.reload()
    setTimeout(() => {
      this.props.navigation.goBack();
    }, 3000);
  };
  // Customizable Area End
}
