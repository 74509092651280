import React, { useEffect } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";

const UserActivityReport = ({ navigation }: any) => {

  return window.screen.width < 950 ? (
    <View>
      <View style={styles.headingView}>
        <TouchableOpacity
          style={styles.leftArrowIconWrapper}
          onPress={() => navigation.goBack()}
        >
          <img
            src={require("../assets/leftArrow1.png")}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
        <Text style={styles.heading}>User Activity Report</Text>
      </View>
      <View style={styles.mainviewMobile}>
        <Text style={styles.innerHeading}>Select Report</Text>
        <TouchableOpacity
          style={styles.buttons}
          onPress={() => navigation.navigate("GenerateReports")}
        >
          <img
            src={require("../assets/clipboard.png")}
            style={{
              height: 30,
              width: 27,
            }}
          />
          <Text style={styles.text}>Bookings Report</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.buttons}
          onPress={() => navigation.navigate("SessionReports")}
        >
          <img
            src={require("../assets/clipboard.png")}
            style={{
              height: 30,
              width: 27,
            }}
          />
          <Text style={styles.text}>Sessions Report</Text>
        </TouchableOpacity>
      </View>
    </View>
  ) : (
    <View style={styles.desktopContainer}>
      <DesktopHeader heading="User Activity Report" navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View style={styles.outerCard}>
            <View style={styles.mainview}>
              <View style={styles.innerHV}>
                <Text style={styles.innerHeading}>Select Report</Text>
              </View>
              <View style={styles.btnDiv}>
                <TouchableOpacity
                  style={styles.webButtons}
                  onPress={() => navigation.navigate("GenerateReports")}
                >
                  <img
                    src={require("../assets/clipboard.png")}
                    style={{
                      height: 30,
                      width: 27,
                    }}
                  />
                  <Text style={styles.text}>Bookings Report</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.webButtons}
                  onPress={() => navigation.navigate("SessionReports")}
                >
                  <img
                    src={require("../assets/clipboard.png")}
                    style={{
                      height: 30,
                      width: 27,
                    }}
                  />
                  <Text style={styles.text}>Sessions Report</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default UserActivityReport;

const styles = StyleSheet.create({
  innerHV: {
    borderBottomWidth: 2,
    borderBottomColor: "#ededed",
    paddingHorizontal: 26,
    paddingBottom: 20,
  },
  btnDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  outerCard: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 15,
    margin: 15,
    flexDirection: "column",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  innerHeading: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#4e3e71",
  },
  mainview: {
    margin: 20,
    paddingVertical: 26,
    borderRadius: 21,
    boxShadow: "0 2px 26px 0 rgba(209, 209, 209, 0.25)",
    backgroundColor: "#fff",
  },
  mainviewMobile: {
    margin: 20,
    padding: 26,
    borderRadius: 21,
    boxShadow: "0 2px 26px 0 rgba(209, 209, 209, 0.25)",
    backgroundColor: "#fff",
  },
  text: {
    fontSize: 22,
    color: "#babbc4",
    paddingHorizontal: 10,
    paddingVertical: "auto",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    borderColor: "#c3bcbc",
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginVertical: 18,
  },
  webButtons: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    borderColor: "#c3bcbc",
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginVertical: 18,
    marginHorizontal: 5,
    width: "48%",
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 24,
    backgroundColor: "#4e3e71",
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
});
