import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
  Button,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";
// import OTPInputView from '@twotalltotems/react-native-otp-input'
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import ResendOTP from "./ResendOTP";
import { Formik } from "formik";
import * as yup from "yup";
import "./react-native-otp-textinput/index.css";
import Timer from "./OtpTimer";
import GlobalCss from "../../../framework/src/GlobalCss";

export const validation_Schema = yup.object({
  otp: yup
    .string()
    .required("Opt is required")
    .matches(/^[0-9]+$/, "Only numbers are allowed")
    .min(4, "must be atleast 4 digits"),
});

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const activated = localStorage.getItem("activated");
    if (token && activated === "true") {
      console.log("User Authenticated -----------------");
      this.props.navigation.goBack();
    } else {
      console.log("User Not Authenticated -----------------");
    }
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      window.screen.width < 950 ? (
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={validation_Schema}
          onSubmit={(values, actions) => {
            console.log(values);
            this.setState({ otp: values.otp });
            this.submitOtp();
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            // Customizable Area Start 
            <View style={styles.container}>
              <TouchableOpacity
                style={styles.leftArrowIconWrapper}
                onPress={() => this.props.navigation.goBack()}
              >
                <img
                  src={require("../assets/backIcon.png")}
                  style={{
                    height: 24,
                    width: 24,
                  }}
                />
              </TouchableOpacity>
              <View style={styles.card}>
                <img
                  src={require("../assets/illustration-register-copy@3x.png")}
                  style={{
                    width: 216,
                    height: 234,
                    position: "absolute",
                    top: -165,
                    left: 180,
                    zIndex: 99,
                  }}
                />
                <Text style={styles.upperText}>Verify Code</Text>
                <Text
                  style={styles.titleWhySignUp} //UI Engine::From Sketch
                >
                  "Please check your email{" "}
                  {window.localStorage.getItem("signup_mail")} for the
                  validation code, so your life starts to get easier."{}
                </Text>

                <View style={styles.OtpInputView}>
                  <OtpInput
                    numInputs={4}
                    //value={this.state.otp}
                    inputStyle={{
                      margin: 10,
                      height: 50,
                      width: 50,
                      borderRadius: 5,
                      fontSize: 20,
                      backgroundColor: "#e8fafe",
                    }}
                    containerStyle="otpConntainer"
                    isInputNum={true}
                    //onChange={(otp: string) => this.setState({otp: otp})}
                    //onBlur={handleBlur('otp')}
                    onChange={handleChange("otp")}
                    value={values.otp}
                  />
                </View>
                {touched.otp && errors.otp ? (
                  <Text style={{ color: "red", paddingTop: 20 }}>
                    {errors.otp}
                  </Text>
                ) : null}

                {this.state.showError ? (
                  <Text style={{ color: "red", paddingTop: 20 }}>
                    {" "}
                    Please Enter Vaild OTP{" "}
                  </Text>
                ) : null}
                <Timer />

                {/* // {...this.btnSubmitOTPProps} */}
                <TouchableOpacity
                  disabled={this.state.loader}
                  onPress={() => handleSubmit()}
                >
                  <View
                    style={
                      this.state.loader ? styles.unactive_button : styles.button
                    }
                  >
                    <Text style={styles.buttonText}>Continue</Text>
                    {this.state.loader ? (
                      <ActivityIndicator
                        style={GlobalCss.loader}
                        size="large"
                        color="#2e2442"
                      />
                    ) : null}
                  </View>
                </TouchableOpacity>
              </View>

              {/* Customizable Area End */}
              {/* Merge Engine UI Engine Code */}
            </View>
            // Customizable Area End
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={validation_Schema}
          onSubmit={(values, actions) => {
            console.log(values);
            this.setState({ otp: values.otp });
            this.submitOtp();
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <View style={styles.desktopContainer}>
              <ImageBackground
                source={require("../assets/group.png")}
                resizeMode="cover"
                style={styles.image}
              >
                <View style={styles.upmedlogo}>
                  <img
                    src={require("../assets/upmed.png")}
                    style={{ height: 56, width: 140, paddingBottom: 20 }}
                  />
                  {/* Customizable Area Start */}
                  <View style={styles.desktopCard}>
                    <Text style={styles.upperTextWeb}>Verify Code</Text>
                    <Text
                      style={styles.titleWhySignUp} //UI Engine::From Sketch
                    >
                      "Please check your email{" "}
                      {window.localStorage.getItem("signup_mail")} for the
                      validation code, so your life starts to get easier."
                      {/*UI Engine::From Sketch*/}
                    </Text>

                    <View style={styles.OtpInputView}>
                      <OtpInput
                        numInputs={4}
                        inputStyle={{
                          margin: 10,
                          height: 50,
                          width: 50,
                          borderRadius: 5,
                          fontSize: 20,
                          backgroundColor: "#e8fafe",
                        }}
                        isInputNum={true}
                        containerStyle="otpConntainer"
                        onChange={handleChange("otp")}
                        value={values.otp}
                      />
                    </View>
                    {touched.otp && errors.otp ? (
                      <Text style={{ color: "red", paddingTop: 20 }}>
                        {errors.otp}
                      </Text>
                    ) : null}

                    {this.state.showError ? (
                      <Text style={{ color: "red", paddingTop: 20 }}>
                        {" "}
                        Please Enter Vaild OTP{" "}
                      </Text>
                    ) : null}
                    <Timer />

                    {/* // {...this.btnSubmitOTPProps} */}
                    <TouchableOpacity
                      disabled={this.state.loader}
                      onPress={() => handleSubmit()}
                      accessible={false}
                    >
                      <View
                        style={
                          this.state.loader
                            ? styles.unactive_buttonWeb
                            : styles.buttonWeb
                        }
                      >
                        <Text style={styles.buttonText}>Continue</Text>
                        {this.state.loader ? (
                          <ActivityIndicator
                            style={GlobalCss.loader}
                            size="large"
                            color="#2e2442"
                          />
                        ) : null}
                      </View>
                    </TouchableOpacity>
                  </View>
                  {/* Customizable Area End */}
                  {/* Merge Engine UI Engine Code */}
                </View>
              </ImageBackground>
            </View>
          )}
        </Formik>
      )
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column-reverse",
    backgroundColor: "#4e3e71",
  },
  leftArrowIconWrapper: {
    position: "absolute",
    left: 25,
    top: 25,
  },
  upmedlogo: {
    marginHorizontal: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  desktopContainer: {
    flex: 1,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  desktopCard: {
    backgroundColor: "#e8fafe",
    maxWidth: 450,
    margin: "auto",
    borderRadius: 40,
    padding: 26,
  },
  card: {
    position: "relative",
    borderTopRightRadius: 24,
    backgroundColor: "#e8fafe",
    paddingHorizontal: 24,
  },
  OtpInputView: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  BottomText: {
    marginVertical: 20,
  },
  OtpInput: {
    margin: 10,
  },
  upperText: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 96,
    paddingBottom: 20,
  },
  upperTextWeb: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 56,
    paddingBottom: 20,
  },
  resendCode: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontWeight: "bold",
  },
  titleWhySignUp: {
    color: "#4e3e71",
    // justifyContent: 'center',
    fontSize: 18,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: 64,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1,
  },
  unactive_button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#8e7bb7",
    marginBottom: 38,
    marginTop: 150,
  },
  unactive_buttonWeb: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#8e7bb7",
    marginBottom: 38,
    marginTop: 60,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 150,
  },
  buttonWeb: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 60,
  },
  desktopbutton: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 20,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10,
  },
});
// Customizable Area End
