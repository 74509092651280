import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import scale, { verticalScale } from "../../../framework/src/Scale";
import ProgressBar from "./StepperComponent";
import {
  getAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../framework/src/Utilities";
import {
  ListEmptyView,
  showErrorMsg,
} from "../../../framework/src/CustomPopUp";


export default function SelectedFacilities({
  navigation,
}: {
  navigation: any;
}) {
  const order = JSON.parse(localStorage.getItem("order") || "{}");

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [saveHospital, setSaveHospital] = useState<any>({});
  const [hospital_id, setHospital_id] = useState(
    order.hospital_id ? order.hospital_id : ""
  );
  const [hospital_name, setHospital_name] = useState("");

  const [fetchData, setFetchData] = useState([]);
  const selectedFacility = JSON.parse(
    localStorage.getItem("selectedFacility") || "[]"
  );

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const getHospitals = async () => {
    setLoader(true);
    let url = `/bx_block_hospitals/facilities`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setPopup(true);
        setMessage(msg);
        setLoader(false);
      } else {
        setLoader(false);
        setData(
          res.data.data.sort((a: any, b: any) =>
            SortData(
              a.attributes.name.toLowerCase(),
              b.attributes.name.toLowerCase()
            )
          )
        );
      }
    });
  };

  useEffect(() => {
    if (selectedFacility.length > 0) {
      setData(selectedFacility);
    } else {
      getHospitals();
    }
  }, []);

  const handleSearch = (text: any) => {
    setQuery(text);
    if (text !== "") {
      const newData = data.filter((name: any) => {
        return Object.values(name.attributes)
          .join(" ")
          .toLowerCase()
          .includes(text.toLowerCase());
      });
      setsearchData(newData);
    } else {
      setsearchData(data);
    }
  };

  const handleChange = (item: any) => {
    let newdata: any = data.map((x: any) => {
      if (x.id === item.id) {
        x.ischecked = true;
      } else {
        x.ischecked = false;
        setHospital_id("");
      }
      return x;
    });
    setsearchData(newdata);
    setData(newdata);
    setHospital_id(item.id);
    setHospital_name(item.attributes.name);
    setSaveHospital(item);
  };

  const navigateToFacility = () => {
    localStorage.setItem("comingFromBookingFlow", "yes");
    navigation.navigate("AddFacility");
  };

  const navigateTo = () => {
    const order = JSON.parse(localStorage.getItem("order") || "{}");
    order.hospital_id = hospital_id ? hospital_id : order.hospital_id;
    order.hospital_name = hospital_name ? hospital_name : order.hospital_name;
    order.savedHospital = saveHospital;
    localStorage.setItem("order", JSON.stringify(order));
    localStorage.setItem("selectedFacility", JSON.stringify(data));
    navigation.navigate("BookingInfo");
  };
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.headingView}>
          <TouchableOpacity
            style={styles.leftArrowIconWrapper}
            onPress={() => navigation.goBack()}
          >
            <img
              src={require("../assets/leftArrow1.png")}
              style={{
                height: 24,
                width: 24,
              }}
            />
          </TouchableOpacity>
          <Text style={styles.heading}>Select Your Facilities</Text>
          <TouchableOpacity onPress={() => navigateToFacility()}>
            <img
              src={require("../assets/addround.png")}
              style={{ height: 24, width: 24, paddingRight: 24 }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View>
        <ProgressBar activeStepCount={1} />
      </View>
      <View style={styles.searchBox}>
        <TextInput
          style={styles.input}
          placeholder="Search "
          autoCapitalize="none"
          autoCorrect={false}
          clearButtonMode="always"
          placeholderTextColor="#939ca3"
          value={query}
          onChangeText={(queryText) => handleSearch(queryText)}
        />
        <TouchableOpacity onPress={() => setQuery("")}>
          <img
            src={
              query === ""
                ? require("../assets/search.png")
                : require("../assets/cross.png")
            }
            style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }}
          />
        </TouchableOpacity>
      </View>
      {loader ? (
        <ActivityIndicator
          style={{ margin: "auto", height: 100, width: 100 }}
          size="large"
          color="#4e3e71"
        />
      ) : (
        <View style={styles.card}>
          <ScrollView style={{ flex: 1 }}>
            {query.length > 0 && searchData.length > 0 ? (
              <View style={styles.add}>
                <Text
                  style={styles.resultText}
                >{`Showing result "${query}"`}</Text>
              </View>
            ) : query.length > 0 && searchData.length === 0 ? (
              <View style={styles.add}>
                <Text style={styles.resultText}>
                  Searched facility not found, {"\n"}
                  <Text
                    style={{
                      color: "#4e3e71",
                      fontWeight: "bold",
                      paddingHorizontal: 50,
                    }}
                    onPress={() => navigation.navigate("AddNewFacility")}
                  >
                    Add Facility{" "}
                  </Text>
                </Text>
              </View>
            ) : null}

            <FlatList
              data={query.length < 1 ? data : searchData}
              keyExtractor={(item: any) => item.id}
              ListEmptyComponent={ListEmptyView()}
              renderItem={({ item }) => (
                <TouchableOpacity
                  disabled={
                    item.attributes.booking_coordinator &&
                    item.attributes.material_manager
                      ? false
                      : true
                  }
                  onPress={() => handleChange(item)}
                >
                  <View
                    style={
                      item.ischecked ? styles.listItem_active : styles.listItem
                    }
                  >
                    <View style={{ flexDirection: "row", flex: 0.9 }}>
                      <img
                        src={
                          item.attributes.logo
                            ? item.attributes.logo
                            : require("../assets/hospital copy.png")
                        }
                        style={{ height: 56, width: 56 }}
                      />
                      <View
                        style={{
                          flexDirection: "column",
                          marginVertical: "auto",
                        }}
                      >
                        <Text style={styles.names}>{item.attributes.name}</Text>
                        <Text style={styles.role} numberOfLines={2}>
                          {item.attributes.address}
                        </Text>
                        <Text style={styles.disableMsg} numberOfLines={2}>
                          {showErrorMsg(item)}
                        </Text>
                      </View>
                    </View>
                    {item.attributes.booking_coordinator &&
                      item.attributes.material_manager && (
                        <View style={{ marginVertical: "auto", flex: 0.1 }}>
                          <img
                            src={
                              item.ischecked
                                ? require("../assets/radio_active.png")
                                : require("../assets/radio_unactive.png")
                            }
                            style={{
                              height: 26,
                              width: 26,
                              backgroundColor: "#4e3e71",
                              borderRadius: "50%",
                            }}
                          />
                        </View>
                      )}
                  </View>
                </TouchableOpacity>
              )}
            />
          </ScrollView>
        </View>
      )}

      {hospital_id === "" ? (
        <View>
          <View style={styles.button0}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </View>
      ) : (
        <TouchableOpacity onPress={() => navigateTo()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffff",
    flexDirection: "column",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  listItem: {
    height: 88,
    marginHorizontal: 26,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem_active: {
    height: 88,
    marginHorizontal: 26,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  role: {
    color: "#b2bac6",
    paddingHorizontal: 11,
    width: scale(220),
  },
  disableMsg: {
    color: "red",
    paddingHorizontal: 11,
    width: scale(250),
  },
  names: {
    color: "#4e3e71",
    fontSize: 15,
    paddingHorizontal: 11,
  },

  checkboxContainer: {
    flex: 0.1,
    paddingRight: 10,
    paddingTop: 100,
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: "row",
    justifyContent: "center",
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 24,
    backgroundColor: "#4e3e71",
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  crossIcon: {
    position: "absolute",
    top: 40,
    right: 10,
    elevation: 10,
  },
  searchBox: {
    flexDirection: "row",
    marginHorizontal: 26,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  searchIcon: {
    paddingLeft: 30,
    paddingVertical: 10,
  },
  input: {
    outlineStyle: "none",
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
  },
  card: {
    flex: 1,
    marginTop: 20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard1: {
    flex: 1,
    flexDirection: "row",
    marginTop: 20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#eff8ff",
    marginHorizontal: 30,
    marginVertical: 6,
    position: "relative",
    justifyContent: "space-between",
  },
  innerCard1: {
    flex: 0.25,
    height: 120,
    backgroundColor: "white",
    margin: 10,
    borderRadius: 10,
  },
  innerCard2: {
    flex: 0.2,
    backgroundColor: "#eff8ff",
  },
  nameaddress: {
    flex: 0.6,
    backgroundColor: "#eff8ff",
  },

  addressText: {
    marginVertical: 20,
    marginLeft: 20,
  },
  cardHeading: {
    marginTop: 20,
    color: "#0579ed",
    fontSize: 20,
    fontWeight: "bold",
    justifyContent: "center",
  },
  para: {
    color: "#939ca3",
  },
  paraDis: {
    color: "#0579ed",
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
    marginTop: 10,
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#aa9cc9",
    marginBottom: 10,
    marginTop: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
});
