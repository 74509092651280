import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      TextInput,
      Image,
      ScrollView,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale, { verticalScale } from "../../../framework/src/Scale";
import { getAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../framework/src/Utilities";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const order = JSON.parse(localStorage.getItem('order') || '{}');
      const [offset, setOffset] = useState(0);
      const [loader, setLoader] = useState(false);
      const role_id = localStorage.getItem('role') || ''

      useEffect(() => {
            getListOfCategories();
      }, [offset]);

      const getListOfCategories = async (search: any = '') => {
            setLoader(true)
            let url = `/bx_block_categories/categories?search=${search}&limit=9&offset=${offset}&brand_ids=${order.manfacturer_id}`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                              setLoader(false)
                            } else {
                              setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase() ,b.attributes.name.toLowerCase())))
                              setLoader(false)
                        }
                          })
            }
      const handleSearch = (text : any) => {
            setQuery(text)
            getListOfCategories(text)
          };

      const ListEmptyView = () => {
      return (
            <View>
            <Text style={styles.heading2}>No Record Found</Text>
            </View>
            )}

      const handleChange = (item: any) => {
            const order = JSON.parse(localStorage.getItem('order') || '{}')
            order.category_id = item.id
            localStorage.setItem('order', JSON.stringify(order))
            navigation.navigate('CatalougeMaterials')

      };

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Category List'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Select Category  </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    {role_id === '1' ? null : <View style={styles.searchBox}>
                                          
                                          <TextInput
                                                style={styles.input}
                                                placeholder="Search "
                                                autoCapitalize="none"
                                                autoCorrect={false}
                                                clearButtonMode="always"
                                                placeholderTextColor="#939ca3"
                                                value={query}
                                                onChangeText={queryText => handleSearch(queryText)}
                                          />
                                          <TouchableOpacity onPress={() => setQuery('')}>
                                                <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                          </TouchableOpacity>

                                    </View> }
                                 
                              </View>
                              {loader ? <ActivityIndicator
                                                style={{ margin: 'auto', height: 100, width: 100, }}
                                                size="large" color="#4e3e71" />
                                                :
                                                <View style={styles.card}>
                                                      <ScrollView style={{ flex: 1 }}>
                                                            {
                                                                  (query.length > 0 && data.length > 0) ?
                                                                        <View style={styles.add}>
                                                                              <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                                        </View>
                                                                        :
                                                                        (query.length > 0 && data.length === 0) ?
                                                                              <View style={styles.add}>
                                                                                    <Text style={styles.resultText}>Searched Manufacturer not found {'\n'}
                                                                                    </Text>
                                                                              </View> : null
                                                            }

                                                            <FlatList
                                                                  data={data}
                                                                  horizontal={false}
                                                                  ListEmptyComponent={ListEmptyView()}
                                                                  numColumns={3}
                                                                  keyExtractor={(item: any) => item.id}
                                                                  renderItem={({ item }) => (
                                                                        <TouchableOpacity onPress={() => handleChange(item)}>
                                                                              <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                                                                    <View style={{ flexDirection: 'row', flex: 0.9 }}>
                                                                                          <img src={require("../assets/manifac_img.png")} style={{ height: 56, width: 56 }} />
                                                                                          <View style={{ flexDirection: 'column', marginVertical: 'auto', }}>
                                                                                                <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>
                                                                                                {
                                                                                                      item.attributes.materials.data.slice(0, 2).map((x: any, y: any) => {
                                                                                                            return (
                                                                                                                  <Text style={styles.role} numberOfLines={2}>{y + 1}) {x.attributes.name} </Text>

                                                                                                            )
                                                                                                      })
                                                                                                }
                                                                                          </View>
                                                                                    </View>
                                                                                 
                                                                              </View>
                                                                        </TouchableOpacity>

                                                                  )}
                                                            />
                                                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                                            {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"< Prev"}</Text></TouchableOpacity>}
                                                            {data.length < 9 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"Next >"}</Text></TouchableOpacity>}
                                                      </View>
                                                      </ScrollView>
                                                </View>
                              }
                                      
                        </View>
                        </View>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      heading2: {
            marginVertical: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection : 'row',
            justifyContent: 'center'
          },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button0: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#aa9cc9',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      listItem: {
            height: 88,
            width: 360,
            marginHorizontal: 16,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      listItem_active: {
            height: 88,
            width: 360,
            marginHorizontal: 16,
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(50),

      },
      names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11,
            width: scale(60),

      },

      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      filterIcon: {
            height: 48,
            width: 48,
            borderRadius: 24,
            padding: 8,
            borderWidth: 1,
            borderColor: '#4e3e71',
            marginVertical: 'auto',
            marginHorizontal: 'auto'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      picker: {
            height: 50,
            width: 160,
            color: 'white',
            paddingHorizontal: 15,
            backgroundColor: '#4e3e71',
            borderRadius: 50,
      },
      searchBox: {
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            width: 950
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 25,
            margin: 15,
            flexDirection: 'column'

      }

})