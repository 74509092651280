import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    TextInput,
    FlatList,
    ScrollView,
} from 'react-native';
import DesktopDesign from "./DektopHistory";
import { getBookingDate, getBookingTime, hideName} from "../../../framework/src/Utilities";
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';



export default function History({ navigation }: { navigation: any }) {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const [searchData, setsearchData] = useState([]);
    const [filterBookings, setFilterBookings] = useState([]);
    const [filtring, setFiltering] = useState(false);
    const [loader, setLoader] = useState(true);


    const role_id = localStorage.getItem('role')
    const getorders = async () => {
        let url = `/bx_block_order/orders`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                setLoader(false)
              } else {
                    setData(res.data.data.filter((x: any) => x.attributes.booking_datetime < new Date().getTime().toString()))}
                    setLoader(false)
              })
    }
    useEffect(() => {
      
        const userStatus = localStorage.getItem('userStatus')
        if (role_id === '2' || role_id === '3') {
            if(userStatus === 'pending'){
              navigation.goBack()
            }else {
                getorders()
            }
             
          } else {
            getorders()
          }
   
    }, []);


    
    const goToNextPage = (item: any) => {
        localStorage.setItem('previous_booking', JSON.stringify(item));
        navigation.navigate('SurgeryDetails')
    }

    const handleSearch = (text: any) => {
        setQuery(text)
        if (text !== "") {
            const newData = data.filter((name: any) => {
                return Object.values(name.attributes)
                    .join(" ")
                    .toLowerCase()
                    .includes(text.toLowerCase());
            });
            setsearchData(newData);
        } else {
            setsearchData(data);
        }
    };



  


    const ListEmptyView = () => {
        return (
            <View>
                <Text style={styles.heading2}>No Booking Found</Text>
            </View>

        )
    }

    const BookingStatus = (item: any) => {
        if (item.attributes.confirmed_by_bc === true &&
            item.attributes.confirmed_by_material_manager === true &&
            item.attributes.confirmed_by_sales_rep === true) {
            return "Confirmed"
        } else {
            return "Pending"
        }
    }


    return (
        window.screen.width < 950 ? 
        <View style={styles.container}>
            <View style={styles.headingView}>
                <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                    <img src={require("../assets/leftArrow1.png")} style={{
                        height: 24, width: 24,
                    }} />
                </TouchableOpacity>
                <Text style={styles.heading}>History</Text>

            </View>
      
                <View style={styles.searchBox}>
                    <TextInput
                        style={styles.input}
                        placeholder="Search "
                        autoCapitalize="none"
                        autoCorrect={false}
                        clearButtonMode="always"
                        placeholderTextColor="#939ca3"
                        value={query}
                        onChangeText={queryText => handleSearch(queryText)}
                    />
                    <TouchableOpacity onPress={() => setQuery('')}>
                        <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                    </TouchableOpacity>
                </View>
            
            {loader ? <ActivityIndicator
                style={{ margin: 'auto', height: 100, width: 100, }}
                size="large" color="#4e3e71" />
                :
                <View style={{ flex: 1 }}>
                    <ScrollView>
                        <FlatList
                            data={query.length < 1 ? filtring ? filterBookings : data : searchData}
                            keyExtractor={(item: any) => item.id}
                            ListEmptyComponent={ListEmptyView()}
                            renderItem={({ item }: { item: any }) => (
                                <TouchableOpacity onPress={() => goToNextPage(item)}>
                                    <View style={{ marginVertical: 15 }}>
                                        <View style={styles.bookingList}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <img src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                                style={{ height: 70, width: 70, borderRadius: 12 }} />
                                                <View style={{ flexDirection: 'column', marginVertical: 'auto' }}>
                                                    <Text style={styles.cardHeading}>{role_id == '4' ? hideName(item.attributes?.first_name, item.attributes?.last_name) : item.attributes?.first_name+" "+item.attributes?.last_name} </Text>
                                                    <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15 }}>
                                                <Text style={{ color: '#b2bac6', fontSize: 12 }}>Surgery Date & time:</Text>
                                                <Text style={{ color: '#5e6f88', fontSize: 12 }}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.bookingList2}>
                                            <Text style={styles.statusBTn}>{BookingStatus(item)}</Text>
                                            <Text style={{ fontSize: 12, color: '#4e3e71' }}>View Details</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                        />
                    </ScrollView>
                </View>
            }


            </View>
            :
            <DesktopDesign navigation={ navigation} />
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#ffff",
        flexDirection: 'column'
    },
    leftArrowIconWrapper: {
        width: 24,
        height: 24,
        position: "absolute",
        left: 24
    },
    dates: {
        flexDirection: 'row',
        marginHorizontal: 'auto',
    },
    heading2: {
        marginVertical: 100,
        marginHorizontal: 'auto',
        color: '#b2bac6',
        fontSize: 24,
        fontWeight: 'bold',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    bookingList: {
        padding: 20,
        marginHorizontal: 20,
        borderColor: '#e5e5e5',
        borderWidth: 1,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12
    },
    bookingList2: {
        paddingHorizontal: 20,
        paddingVertical: 15,
        marginHorizontal: 20,
        borderColor: '#e5e5e5',
        borderWidth: 1,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    statusBTn: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontSize: 12,
        backgroundColor: '#4e3e71',
        color: '#fff',
        borderRadius: 12
    },
    outerCard: {
        flexDirection: 'row',
        marginTop: 20,
        borderRadius: 15,
        elevation: 3,
        backgroundColor: '#fff',
        marginHorizontal: 20,
        marginVertical: 6,
    },
    innerCard: {
        margin: 10,
        borderRadius: 15,
        elevation: 3,
        backgroundColor: "#eff8ff",
        padding: 25,

    },
    paraDis: {
        color: '#8e9aab',
        paddingHorizontal: 15,
        fontSize: 14
    },
    para: {
        marginVertical: 35,
        color: '#0778df',
        marginLeft: 60,
    },
    cardHeading: {
        paddingHorizontal: 15,
        color: '#4e3e71',
        fontSize: 17,
    },
    searchBox: {
        flexDirection: 'row',
        marginHorizontal:26,
        marginVertical: 10,
        borderWidth: 1,
        borderColor: '#8a8a8a',
        borderRadius: 50

    },
    input: {
outlineStyle: 'none',
        flex: 1,
        paddingLeft: 10,
        // width: 250,
        height: 48,
        color: '#4e3e71',
        fontSize: 16,
        paddingStart: 20
    },
    searchBox2: {
        marginHorizontal: 20,
        borderRadius: 50,
        borderWidth: 2,
    },
    searchIcon: {
        paddingLeft: 10
    },
    headingView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 27,
        backgroundColor: '#4e3e71',
        marginBottom: 20,

    },
    heading: {
        color: '#e8fafe',
        fontSize: 20,
        marginHorizontal: 'auto'
    },
    filterIcon: {
        height: 48,
        width: 48,
        borderRadius: 24,
        padding: 8,
        borderWidth: 1,
        borderColor: '#4e3e71',
        marginVertical: 10,
        marginHorizontal: 11
    },
    button: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginHorizontal: 5,
        backgroundColor: 'white',
    },
    buttonText: {
        color: '#0778df',
        fontSize: 15,
        textAlign: 'center',
    },
    button3: {
        borderRadius: 5,
        paddingVertical: 5,
        paddingHorizontal: 5,
        marginHorizontal: 5,
        backgroundColor: 'white',
    },
    buttonText3: {
        // color: '#0778df',
        fontSize: 15,
        textAlign: 'center',
    },
    button2: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginHorizontal: 5,
        backgroundColor: '#0778df',
    },
    buttonText2: {
        color: 'white',
        fontSize: 15,
        textAlign: 'center',
    },

})


