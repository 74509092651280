import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as yup from "yup";
import { getRegex } from "../../../framework/src/Utilities";
import { getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  countryName: string;
  regionName: string;
  query: string;
  checked: boolean;
  searchData: any[];
  apiData: any[];
  hospitalsName: any[];
  arrayHolder: any[];
  token: string;
  hospitals: string;
  showInput: boolean;
  showAmbulatory: boolean;
  ambulatory: string;
  OR: string;
  address: any;
  zip: any;
  is_hospital: any;
  is_or: any;
  is_ambulatory: any;
  selectedValue: any;
  open: any;
  nameError: any;
  role_Name: any;
  isPhoneValid: any;
  loader: boolean;
  passwordValidation: any;
  userIp: any;
  userInvitesData: any;
  popup: boolean;
  message: string;
  passScore: any;
  passFeedback: any;
  weakPassword: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  hospitalApiCallId: string = "";
  getUserInvitation: string = "";
  inviteUserApiCallId: string = "";
  userApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      countryName: "",
      regionName: "",
      query: "",
      checked: false,
      searchData: [],
      arrayHolder: [],
      token: "",
      apiData: [],
      hospitalsName: [],
      address: "",
      zip: "",
      is_hospital: null,
      hospitals: "Yes",
      showInput: false,
      showAmbulatory: false,
      ambulatory: "Yes",
      OR: "Yes",
      is_or: null,
      is_ambulatory: null,
      selectedValue: "",
      open: false,
      nameError: "",
      role_Name: "",
      isPhoneValid: true,
      loader: false,
      passwordValidation: "",
      userIp: "",
      userInvitesData: {},
      popup: false,
      message: "",
      passScore: 0,
      passFeedback: '',
      weakPassword: false
      // Customizable Area End
    };

    // Customizable Area Start

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
          this.setState({ passwordValidation: responseJson.data });
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            localStorage.removeItem("isTimer");
            localStorage.removeItem("timer");
            window.localStorage.setItem(
              "authToken",
              responseJson.email_otp.meta.token
            );
            this.props.navigation.navigate("OTPInputAuth");
            this.setState({ loader: false });
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            console.log(responseJson);
            this.setState({ loader: false });
          }

          this.parseApiCatchErrorResponse(errorReponse);
          console.log(responseJson);
          this.setState({ loader: false });
        }
      
  
      if (apiRequestCallId === this.getUserInvitation) {
        if (!responseJson.errors) {
          localStorage.setItem("role", responseJson.data.attributes.role_id);
          this.formikRef.current.setValues({
            fname: responseJson.data.attributes.name,
            lname: "",
            email: responseJson.data.attributes.email,
            password: "",
            rePassword: "",
            fullphone: "",
          });
          this.setState({ userInvitesData: responseJson.data.attributes });
        } else {
          let msg = getErrorMessage(responseJson.errors[0]);
          this.setState({ message: msg, popup: true });
        }
      }
  }

    // Customizable Area End
  }

  // Customizable Area Start

  //------------------New Functions-------------------------------------------------------------------

  handlePasswordChange = (pass: any) => {
    const { passwordValidation } = this.state;
    if (Object.keys(passwordValidation).length) {
      try {
        const regexBase = new RegExp(
          "^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]{" +
            passwordValidation.special_char +
            "," +
            5 +
            "})(?=.*[A-Z]{" +
            passwordValidation.up_case +
            "," +
            5 +
            "})(?=.*[a-z]{" +
            passwordValidation.lower_case +
            "," +
            10 +
            "}).{" +
            passwordValidation.min_length +
            "," +
            passwordValidation.max_length +
            "}$"
        );
        const specialChars = `!@#$%^&*()_+=[\]{}|\\:',./?><~\``.substring(
          0,
          passwordValidation.special_char
        );
        const regex = new RegExp(
          `^(?=.*[a-z])(?=.*[A-Z]{${passwordValidation.up_case},})(?=.*\\d{${passwordValidation.min_number},})(?=.*[${specialChars}]{${passwordValidation.special_char},})[a-zA-Z\\d${specialChars}] {8,}$`
        );
        console.log({ isValid: regexBase.test(pass), passwordValidation });
        this.setState({ password: pass });

        return regex.test(pass);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("empty schema ====> ");
    }
  };

  validationSchema = () => {
    const { passwordValidation } = this.state;
    let validation_Schema;
    if (passwordValidation) {
      const newRegx = getRegex(passwordValidation);
      validation_Schema = yup.object({
        fname: yup
          .string()
          .required("First name is required")
          .matches(
            /[a-zA-Z]/,
            "Only alphabets are allowed with minimum length 1"
          ),
        lname: yup
          .string()
          .required("Last name is required")
          .matches(
            /[a-zA-Z]/,
            "Only alphabets are allowed with minimum length 1"
          ),
        email: yup
          .string()
          .email("Please enter a valid Email")
          .matches(
            /^([a-zA-Z][\w+-]+(?:\.\w+)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/,
            "Invalid email address"
          )
          .required("Email is required"),
        fullphone: yup
          .string()
          .required("Phone is required")
          .min(10, "Must be at least 10 digits"),
        password: yup
          .string()
          .required("Please enter a password")
          .matches(newRegx.regEx, newRegx.message)
          .min(
            passwordValidation.min_length,
            `Password must be at least ${passwordValidation.min_length} characters`
          )
          .max(
            passwordValidation.max_length,
            `Maximum ${passwordValidation.max_length} characters allowed`
          ),
        rePassword: yup
          .string()
          .required("Please confirm your password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup
              .string()
              .oneOf([yup.ref("password")], "Passwords must match"),
          }),
      });
    } else {
      console.log("state empty ==>");
      validation_Schema = yup.object({
        fname: yup
          .string()
          .required("First name is required")
          .matches(
            /[a-zA-Z]/,
            "Only alphabets are allowed with minimum length 1"
          ),
        lname: yup
          .string()
          .required("Last name is required")
          .matches(
            /[a-zA-Z]/,
            "Only alphabets are allowed with minimum length 1"
          ),
        email: yup
          .string()
          .email("Please enter a valid Email")
          .required("Email is required"),
        fullphone: yup
          .string()
          .required("Phone is required")
          .min(10, "must be atleast 10 digits"),
        password: yup
          .string()
          .required("Please enter a password")
          .matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#@*^$%&? "])[a-zA-Z0-9!#@*^$%&?]{8,}$/,
            "Requires at least 1 uppercase letter, 1 lowercase letter, 1 number, and one special character, with a length of at least 8 characters"
          )
          .min(8, "Password must be at least 8 characters")
          .max(16, "Maximum 16 characters allowed "),
        rePassword: yup
          .string()
          .required("Please confirm your password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup
              .string()
              .oneOf([yup.ref("password")], "Passwords must match"),
          }),
      });
    }

    return validation_Schema;
  };

  handleSearch = (text: any) => {
    this.setState({ query: text });
    this.setState({ checked: true });
    if (text !== "") {
      const newData = this.state.hospitalsName.filter((name: any) => {
        return Object.values(name)
          .join(" ")
          .toLowerCase()
          .includes(text.toLowerCase());
      });
      this.setState({ searchData: newData });
      //console.log(newData);
    } else {
      this.setState({ searchData: this.state.hospitalsName });
    }
  };

  navigateToNext = (item: any) => {
    console.log(item);
    // this.props.navigation.navigate('FailityName', item)
    const msg: Message = new Message(
      getName(MessageEnum.NavigationFacilityNameMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  getUserInvitesDetails(token: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const userInvites = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserInvitation = userInvites.messageId;

    userInvites.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_invite/invities/get_user_from_token"
    );

    userInvites.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    userInvites.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(userInvites.id, userInvites);
  }

  handelhospital = (e: any) => {
    this.setState({ hospitals: e.target.value });
    console.log(this.state.hospitals);
  };

  handelOR = (e: any) => {
    this.setState({ OR: e.target.value });
  };

  handelambulatory = (e: any) => {
    this.setState({ ambulatory: e.target.value });
  };


  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };


  role_name = () => {
    const role_id = this.state.userInvitesData.role_id
      ? this.state.userInvitesData.role_id
      : window.localStorage.getItem("role");
    if (role_id === "1") {
      this.setState({ role_Name: "Surgeon" });
    } else if (role_id === "2") {
      this.setState({ role_Name: "Material Manager" });
    } else if (role_id === "3") {
      this.setState({ role_Name: "Booking Coordinator" });
    } else {
      this.setState({ role_Name: "Sales Representative" });
    }
  };

  ClosePopup = () => {
    console.log("called");
    window.location.href = "/";
  };

  formikRef: any = React.createRef();
  //------------------New Functions Ends--------------------------------------------------------------
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  createAccount(): boolean {
    this.setState({ loader: true });

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const data = {
      first_name: this.state.firstName.trim(),
      last_name: this.state.lastName.trim(),
      full_phone_number: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.reTypePassword,
      role_id: window.localStorage.getItem("role"),
      device_ids: localStorage.getItem("FCMtoken"),
      ip_address: this.state.userIp,
    };

    console.log(data);
    window.localStorage.setItem("signup_mail", this.state.email);

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // console.log(this.createAccountApiCallId);

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }



  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };


  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };


  txtInputZipCodePrpos = {
    onChangeText: (text: string) => {
      this.setState({ zip: text });

      //@ts-ignore
      this.txtInputZipCodePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  // Customizable Area End
}