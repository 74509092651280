import React, { useState, useEffect } from "react";
import {
      View,
      StyleSheet,
      TouchableOpacity,
      Image,
      Text,
      TextInput,
      ScrollView,
      Picker,
      ActivityIndicator
} from "react-native";
import Dropzone from "react-dropzone";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { getErrorMessage, getAxiosAPI, postAxiosAPI, patchAxiosAPI, putAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../../../framework/src/CustomPopUp";
import { SortData } from "../../../../../framework/src/Utilities";
import GlobalCss from "../../../../../framework/src/GlobalCss";

const validation_Schema = yup.object({
      manufacturerName: yup.string().required("Manufacturer name is required"),
      materialCategory: yup.string().required("Material category is required"),
      materialName: yup.string().required("Material name is required"),
      gtinNo: yup
            .string()
            .length(14, "Gtin no should be 14 characters")
            .required("Gtin no is required")
});
export default function NewMaterial({ navigation }: { navigation: any }) {

      const EditMaterial = JSON.parse(localStorage.getItem('editMaterialData') || '{}')
      const role_id = localStorage.getItem('role') || ''

      const [brand, setBrand] = useState([])
      const [category, setCategory] = useState([]);
      const [imagePath, setImagePath] = useState(EditMaterial?.attributes?.image ? EditMaterial?.attributes?.image : '');

      const [imageData, setImageData] = useState<any>(null);
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false);
      const [loader, setLoader] = useState({
            brand: true,
            category: false,
            submitBtn: false
      });

      const ClosePopUp = () => {
            setPopup(false)
      }

      const [image, setImage] = useState<any>(null)
      const [imageDemo, setImageDemo] = useState<any>(null)
      const onImageChange = (event: any) => {
            if (!event.target.files[0].type.match(/^image\//)) {
                  setMessage("File type must be image/*")
                  setPopup(true)
            } else {
                  setImagePath(URL.createObjectURL(event.target.files[0]))
                  setImageDemo(URL.createObjectURL(event.target.files[0]));
                  setImage(event.target.files[0])
                  setImageData(event.target.files[0])
            }
      }

      useEffect(() => {
            const getBrand = async () => {
                  let url = `/bx_block_catalogue/brands`
                  await getAxiosAPI(url).then((res: any) => {
                        if (res.error) {
                              let msg = getErrorMessage(res.data.errors[0])
                              setLoader((pre: any) => ({ ...pre, brand: false }))
                        } else {
                              setLoader((pre: any) => ({ ...pre, brand: false }))
                              setBrand(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
                        }
                  })
            };
            getBrand();
      }, []);

      const getMaterailCategories = async (brandID: any) => {
            setLoader((pre: any) => ({ ...pre, category: true }))
            let url = `/bx_block_categories/categories?brand_ids=${brandID}`
            await getAxiosAPI(url).then((res: any) => {
                  if (res.error) {
                        let msg = getErrorMessage(res.data.errors[0])
                        setLoader((pre: any) => ({ ...pre, category: false }))
                  } else {
                        setLoader((pre: any) => ({ ...pre, category: false }))
                        setCategory(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
                  }
            })
      }

      const _onHandleDrop = (acceptedFiles: any, rejectedFiles: any, event: any) => {
            if (rejectedFiles.length) {
                  setMessage(rejectedFiles[0].errors[0].message)
                  setPopup(true)
            } else {
                  const reader = new FileReader();
                  reader.onabort = () => console.log("file reading was aborted");
                  reader.onerror = () => console.log("file reading has failed");
                  reader.onload = () => {
                        const binaryStr: any = reader.result;
                        setImagePath(binaryStr)
                  };
                  reader.readAsDataURL(acceptedFiles[0]);
                  setImageData(acceptedFiles[0]);
            }
      };

      const handleAddMaterial = async (values: any, actions: any) => {
            setLoader((pre: any) => ({ ...pre, submitBtn: true }))
            if (imageData) {
                  var postData = new FormData();
                  postData.append("material[name]", values.materialName);
                  postData.append("material[division]", values.division);
                  postData.append("material[catalog_no]", values.catlogNo);
                  postData.append("material[barcode]", values.barcode);
                  postData.append("material[gtin_no]", values.gtinNo);
                  postData.append("category_id", values.materialCategory);
                  postData.append("material[image]", imageData ? imageData : null, imageData.name ? imageData.name : null);
                  postData.append("brand_id", values.manufacturerName);
                  postData.append("material[description]", values.description);
                  postData.append("material[price]", values.price);
                  postData.append("material[discount_price]", values.discount_price);
            } else {
                  var postData = new FormData();
                  postData.append("material[name]", values.materialName);
                  postData.append("material[division]", values.division);
                  postData.append("material[catalog_no]", values.catlogNo);
                  postData.append("material[barcode]", values.barcode);
                  postData.append("material[gtin_no]", values.gtinNo);
                  postData.append("category_id", values.materialCategory);
                  // postData.append("material[image]", imageData ? imageData : null  , imageData.name ? imageData.name : null );
                  postData.append("brand_id", values.manufacturerName);
                  postData.append("material[description]", values.description);
                  postData.append("material[price]", values.price);
                  postData.append("material[discount_price]", values.discount_price);
            }

            if (Object.keys(EditMaterial).length && role_id === '4') {
                  let url = `/bx_block_materials/materials/${EditMaterial.id}`;
                  await putAxiosAPI(url, postData).then((res: any) => {
                        if (res.error) {
                              let msg = getErrorMessage(res.data.errors[0])
                              setMessage(msg);
                              setPopup(true)
                              setLoader((pre: any) => ({ ...pre, submitBtn: false }))
                        } else {
                              localStorage.setItem("selectedMaterial", JSON.stringify(res.data.data))
                              setMessage("Material Updated Successfully");
                              setPopup(true)
                              setLoader((pre: any) => ({ ...pre, submitBtn: false }))
                              setTimeout(() => {
                                    navigation.goBack()
                              }, 2000)
                        }
                  });
            } else {
                  let url = "/bx_block_materials/materials";
                  await postAxiosAPI(url, postData).then((res: any) => {
                        if (res.error) {
                              let msg = getErrorMessage(res.data.errors[0])
                              setMessage(msg);
                              setPopup(true)
                              setLoader((pre: any) => ({ ...pre, submitBtn: false }))
                        } else {
                              setMessage("Material Added Successfully");
                              setPopup(true)
                              actions.resetForm()
                              setLoader((pre: any) => ({ ...pre, submitBtn: false }))
                              setImageData(null)
                              setImagePath('')
                        }
                  });
            }

      }

      return (
            <ScrollView>
                  <View style={styles.container}>
                        {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message} />}
                        <View style={styles.subWrapper}>
                              <View style={{ flexDirection: 'row' }}>
                                    <Dropzone onDrop={_onHandleDrop} accept="image/*">
                                          {({ getRootProps, getInputProps }) => (
                                                <View style={styles.imageContainer}>
                                                      {imagePath === "" ? (
                                                            <div {...getRootProps()}>
                                                                  <View style={styles.uploadIconWrapper}>
                                                                        <Image
                                                                              source={require("../../../assets/upload.png")}
                                                                              style={styles.uploadIcon}
                                                                        />
                                                                  </View>
                                                                  <Text style={styles.dragAndDropText}>
                                                                        Drag and Drop or Browse to upload
                                                                  </Text>
                                                                  <input {...getInputProps()} />{" "}
                                                            </div>
                                                      ) : (
                                                            <>
                                                                  <Image
                                                                        source={{ uri: imagePath }}
                                                                        style={{
                                                                              width: "100%",
                                                                              height: "100%",
                                                                              resizeMode: "center"
                                                                        }}
                                                                  />

                                                            </>

                                                      )}
                                                </View>
                                          )}
                                    </Dropzone>
                                    <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />

                                    <label style={{ height: 50 }} htmlFor="file">
                                          {
                                                imagePath &&
                                                <TouchableOpacity style={GlobalCss.buttonView}>
                                                      <Text style={GlobalCss.buttonText}>Change image</Text>
                                                </TouchableOpacity>
                                          }
                                    </label>


                              </View>
                              <Formik
                                    initialValues={{
                                          manufacturerName: EditMaterial?.attributes?.brand.data.id ? EditMaterial?.attributes?.brand.data.id : "",
                                          materialCategory: EditMaterial?.attributes?.category.data.id ? EditMaterial?.attributes?.category.data.id : "",
                                          materialName: EditMaterial?.attributes?.name ? EditMaterial?.attributes?.name : "",
                                          division: EditMaterial?.attributes?.division ? EditMaterial?.attributes?.division : "",
                                          description: EditMaterial?.attributes?.description ? EditMaterial?.attributes?.description : "",
                                          catlogNo: EditMaterial?.attributes?.catalog_no ? EditMaterial?.attributes?.catalog_no : "",
                                          barcode: EditMaterial?.attributes?.barcode ? EditMaterial?.attributes?.barcode : "",
                                          gtinNo: EditMaterial?.attributes?.gtin_no ? EditMaterial?.attributes?.gtin_no : "",
                                          price: EditMaterial?.attributes?.price ? EditMaterial?.attributes?.price : "",
                                          discount_price: EditMaterial?.attributes?.discount_price ? EditMaterial?.attributes?.discount_price : ""
                                    }}
                                    validationSchema={validation_Schema}
                                    onSubmit={(values, actions) => {
                                          handleAddMaterial(values, actions);
                                    }}
                              >
                                    {({
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          values,
                                          handleSubmit,
                                          setFieldValue
                                    }) => (
                                          <>

                                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                                      <View style={{ flex: 0.48, flexDirection: 'column' }}>

                                                            {
                                                                  Object.keys(EditMaterial).length && role_id == '4' ?
                                                                        <>
                                                                              <View style={styles.textInputStyle}>
                                                                                    <Picker
                                                                                          selectedValue={values.manufacturerName}
                                                                                          style={styles.disablepicker}
                                                                                          enabled={false}

                                                                                    >
                                                                                          <Picker.Item value="" label={EditMaterial.attributes.brand.data.attributes.name} />
                                                                                    </Picker>
                                                                              </View>
                                                                              <View style={styles.textInputStyle}>
                                                                                    <Picker
                                                                                          selectedValue={values.manufacturerName}
                                                                                          style={styles.disablepicker}
                                                                                          enabled={false}

                                                                                    >
                                                                                          <Picker.Item value="" label={EditMaterial.attributes.category.data.attributes.name} />
                                                                                    </Picker>
                                                                              </View>
                                                                        </>
                                                                        :
                                                                        <>
                                                                              <View style={styles.textInputStyle}>
                                                                                    <Picker
                                                                                          selectedValue={values.manufacturerName}
                                                                                          style={[
                                                                                                values.manufacturerName === ""
                                                                                                      ? styles.disablepicker
                                                                                                      : styles.enablePicker
                                                                                          ]}
                                                                                          onValueChange={e => {
                                                                                                setFieldValue("manufacturerName", e);
                                                                                                getMaterailCategories(e);
                                                                                          }}
                                                                                          enabled={loader.brand ? false : true}

                                                                                    >
                                                                                          <Picker.Item value="" label={loader.brand ? "Loading..." : "Select Manufacturer Name"} />
                                                                                          {
                                                                                                brand.length ?
                                                                                                      brand.map((item: any) => {
                                                                                                            return (
                                                                                                                  <Picker.Item
                                                                                                                        label={item.attributes.name}
                                                                                                                        value={item.id}
                                                                                                                        key={item.id}
                                                                                                                  />
                                                                                                            );
                                                                                                      }) : null}
                                                                                    </Picker>
                                                                              </View>
                                                                              <Text style={{ color: "red" }}>
                                                                                    {touched.manufacturerName && errors.manufacturerName
                                                                                          ? errors.manufacturerName
                                                                                          : ""}
                                                                              </Text>
                                                                              <View style={styles.textInputStyle}>
                                                                                    <Picker
                                                                                          selectedValue={values.materialCategory}
                                                                                          style={[
                                                                                                values.materialCategory === ""
                                                                                                      ? styles.disablepicker
                                                                                                      : styles.enablePicker
                                                                                          ]}
                                                                                          onValueChange={handleChange("materialCategory")}
                                                                                          enabled={loader.category ? false : true}
                                                                                    >
                                                                                          <Picker.Item value="" label={loader.category ? "Loading..." : "Select Material Category"} />
                                                                                          {category &&
                                                                                                category.length > 0 &&
                                                                                                category.map((item: any) => {
                                                                                                      return (
                                                                                                            <Picker.Item
                                                                                                                  label={item.attributes.name}
                                                                                                                  value={item.id}
                                                                                                                  key={item.id}
                                                                                                            />
                                                                                                      );
                                                                                                })}
                                                                                    </Picker>
                                                                              </View>
                                                                              <Text style={{ color: "red" }}>
                                                                                    {touched.materialCategory && errors.materialCategory
                                                                                          ? errors.materialCategory
                                                                                          : ""}
                                                                              </Text>
                                                                        </>
                                                            }
                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Catlog No"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.catlogNo}
                                                                  onBlur={handleBlur("catlogNo")}
                                                                  onChangeText={handleChange("catlogNo")}
                                                            />
                                                            <Text style={{ color: "red" }}>
                                                                  {touched.catlogNo && errors.catlogNo ? errors.catlogNo : ""}
                                                            </Text>
                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Gtin No"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.gtinNo}
                                                                  onBlur={handleBlur("gtinNo")}
                                                                  onChangeText={handleChange("gtinNo")}
                                                            />
                                                            <Text style={{ color: "red" }}>
                                                                  {touched.gtinNo && errors.gtinNo ? errors.gtinNo : ""}
                                                            </Text>
                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Material Name"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.materialName}
                                                                  onBlur={handleBlur("materialName")}
                                                                  onChangeText={handleChange("materialName")}
                                                            />
                                                            <Text style={{ color: "red" }}>
                                                                  {touched.materialName && errors.materialName
                                                                        ? errors.materialName
                                                                        : ""}
                                                            </Text>

                                                      </View>
                                                      <View style={{ flex: 0.48, flexDirection: 'column' }}>

                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Division"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.division}
                                                                  onBlur={handleBlur("division")}
                                                                  onChangeText={handleChange("division")}
                                                                  editable={Object.keys(EditMaterial).length && role_id === '4' ? false : true}
                                                            />
                                                            <Text style={{ color: "red" }}>
                                                                  {touched.division && errors.division ? errors.division : ""}
                                                            </Text>



                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Barcode"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.barcode}
                                                                  onBlur={handleBlur("barcode")}
                                                                  onChangeText={handleChange("barcode")}
                                                                  editable={Object.keys(EditMaterial).length && role_id === '4' ? false : true}
                                                            />
                                                            <Text style={{ color: "red" }}>
                                                                  {touched.barcode && errors.barcode ? errors.barcode : ""}
                                                            </Text>
                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  keyboardType="numeric"
                                                                  maxLength={10}
                                                                  placeholder="Price"
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.price}
                                                                  onBlur={handleBlur("price")}
                                                                  onChangeText={handleChange("price")}
                                                            />

                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Discounted Price"
                                                                  placeholderTextColor="#b2bac6"
                                                                  keyboardType="numeric"
                                                                  maxLength={10}
                                                                  value={values.discount_price}
                                                                  onBlur={handleBlur("discount_price")}
                                                                  onChangeText={handleChange("discount_price")}
                                                            />
                                                            <TextInput
                                                                  style={styles.textInputStyle}
                                                                  placeholder="Description"
                                                                  multiline={true}
                                                                  // maxLength={5}
                                                                  numberOfLines={3}
                                                                  placeholderTextColor="#b2bac6"
                                                                  value={values.description}
                                                                  onBlur={handleBlur("description")}
                                                                  onChangeText={handleChange("description")}
                                                            />

                                                      </View>

                                                </View>

                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                      <TouchableOpacity
                                                            style={styles.acceptManagerButton2}
                                                            onPress={() => navigation.goBack()}
                                                      >
                                                            <Text style={styles.acceptManagerText2}>
                                                                  Cancel
                                                            </Text>
                                                      </TouchableOpacity>
                                                      <TouchableOpacity
                                                            style={loader.submitBtn ? styles.acceptManagerButtonDisable : styles.acceptManagerButton}
                                                            onPress={() => handleSubmit()}
                                                            disabled={loader.submitBtn}
                                                      >
                                                            <Text style={styles.acceptManagerText}>
                                                                  Save & Finish
                                                            </Text>
                                                            {loader.submitBtn && <ActivityIndicator style={{ position: 'absolute', right: 95, top: 8 }} size="large" color="#2e2442" />}
                                                      </TouchableOpacity>
                                                </View>

                                          </>
                                    )}
                              </Formik>
                        </View>
                  </View>
            </ScrollView>
      );
}


const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff"
      },
      headerWrapper: {
            backgroundColor: "#4e3e71",
            height: 72,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
      leftArrowIcon: {
            width: 24,
            height: 24
      },
      headerTitle: {
            color: "#e8fafe",
            fontSize: 20
      },
      subWrapper: {
            width: "100%",
            paddingHorizontal: 24,
            paddingTop: 10
      },
      textInputStyle: {
            width: "100%",
            outlineStyle: 'none',
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginVertical: 10,
            borderColor: "#e5e5e5",
            color: '#4e3e71',
            borderBottomWidth: 1,
            // fontSize: 15,
            // marginTop: 22
      },
      acceptManagerButton: {
            marginHorizontal: 24,
            height: 54,
            paddingHorizontal: 60,
            backgroundColor: "#4e3e71",
            borderRadius: 12,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            marginBottom: 10
      },
      acceptManagerButtonDisable: {
            marginHorizontal: 24,
            height: 54,
            paddingHorizontal: 60,
            backgroundColor: "#aa9cc9",
            borderRadius: 12,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            marginBottom: 10
      },
      acceptManagerButton2: {
            marginHorizontal: 24,
            height: 54,
            paddingHorizontal: 80,
            backgroundColor: "#fff",
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#4e3e71',
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            marginBottom: 10
      },
      disablepicker: {
            // width: "100%",
            borderColor: "#fff",
            color: "#b2bac6"
      },
      enablePicker: {
            width: "100%",
            borderColor: "#fff",
            color: "#000"
      },
      acceptManagerText: { fontSize: 17, color: "#e8fafe" },
      acceptManagerTextDisable: { fontSize: 17, color: "#4e3e71" },
      acceptManagerText2: { fontSize: 17, color: "#4e3e71" },
      imageContainer: {
            height: 150,
            borderWidth: 2,
            borderColor: "rgba(143, 146, 161, 0.2)",
            width: "50%",
            borderRadius: 16,
            marginBottom: 10,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(143, 146, 161, 0.05)",
            borderStyle: "dashed",
            flexDirection: 'row'
            // position: 'relative'
      },
      uploadIconWrapper: {
            borderRadius: 16,
            width: 48,
            height: 48,
            backgroundColor: "rgba(143,146,161,0.5)",
            opacity: 0.5,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginHorizontal: "auto"
      },
      uploadIcon: {
            height: 24,
            width: 24,
            alignItems: "center",
            justifyContent: "center"
      },
      dragAndDropText: { fontSize: 14, color: "#4e3e71" }
});
