// Customizable Area Start
import React ,{useState, useEffect } from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
      ActivityIndicator,
      FlatList,
      ScrollView, } from 'react-native';
import scale, { verticalScale } from "../../../framework/src/Scale";
import CustomPopUp from "../../../framework/src/CustomPopUp"
import DesktopDesign from "./DesktopUserFacility";
import {getAxiosAPI, deleteAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock"


export default function UserFacility({navigation} : {navigation: any}) {
      const [data, setData] = useState([]);
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const role_id = localStorage.getItem('role')
      const [loader, setLoader] = useState(false);

      const ClosePopUp = () => {
        setPopup(false)
      }

      const list = JSON.parse(localStorage.getItem('userFacilities') || '[]')


  const getHospitals =  async (search: any = '') => {
    setLoader(true)
    let url = `/account_block/accounts/account_detail`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(res.data);
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.attributes.facilities.data)
          }
        })
  };
      useEffect(() => { 
            getHospitals();
          }, [])

      const DeleteFacility = async (id:any) => {
        let url = `/bx_block_hospitals/facilities/${id}`
        await deleteAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(res.data);
                setPopup(true)
                setMessage(msg)
                setLoader(false)
              } else {
                getHospitals()
              }
            })
      }

      const navigateTo = () => {

        localStorage.setItem('comingfromProfile', 'yes')
        navigation.navigate('AddFacility')
      }
    

      return(
        window.screen.width < 950 ? 
            <View style={styles.container}>
               {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
          <View style={styles.headingView}>
            <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.navigate('Dashboard')}>
              <img src={require("../assets/leftArrow1.png")} style={{
                height: 24, width: 24,
              }} />
            </TouchableOpacity>
            <Text style={styles.heading}>Facilities</Text>
            {
              role_id === '3' ? null : 
              <TouchableOpacity onPress={() => navigateTo()}>
              <img  src={require("../assets/add copy.png")}  style={{height: 24, width: 24, paddingRight: 24}} />
      </TouchableOpacity> 
            }
          
          </View>
      { loader ? <ActivityIndicator
          style={{ margin: 'auto', height: 100, width: 100, }}
          size="large" color="#4e3e71" />
          : data.length < 1 ?

      <View style={styles.errormsg}>
        <Text style={styles.lableText}> You have not added any Facility  </Text>
        <TouchableOpacity onPress={() => navigateTo()}>
          <Text style={{marginHorizontal: 'auto', color: '#4e3e71', fontSize: 20, fontWeight: 'bold'}}>Add Facility</Text>
        </TouchableOpacity>
      </View>
      
        : <ScrollView style={{flex: 1, marginTop: 20}}>
     
                    <FlatList
                              data={data}
                              keyExtractor={(item: any) => item.id}
                              renderItem={({ item }) => (
                                <TouchableOpacity>
                                    <View style={styles.listItem}>
                                        <View style={{flexDirection: 'row' , flex: 0.9}}>
                                          <img src={item.attributes.hospital?.data?.attributes.logo ? item.attributes.hospital?.data?.attributes.logo :  require("../assets/hospital copy.png")} style={{ height: 56, width: 56}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                               <Text style={styles.names}>{item?.attributes?.hospital?.data?.attributes?.name}</Text>
                                                    <Text style={styles.role} numberOfLines={2}>{item?.attributes?.hospital?.data?.attributes?.address}</Text>
                                                </View>
                                        </View>
                                          <TouchableOpacity onPress={() => DeleteFacility(item?.attributes?.hospital?.data?.id)} style={{marginVertical: 'auto', flex: 0.1}}>
                                          <img src={require("../assets/delete.png")}  style={{height: 26, width: 26 }}/>      
                                    </TouchableOpacity>
                                    </View>
                                </TouchableOpacity>
                        
                              )}
                              /> 
      </ScrollView>}
            </View>
          :
          <View>
            <DesktopDesign navigation={navigation}/>
          </View>
      )

}
const styles = StyleSheet.create({
      container: {
            flex: 1,
            padding: 1,
            backgroundColor: "#ffff"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
          listItem: {
            height: 88,
            marginHorizontal:26,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(220),
            
          },
          names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11
            
          },
          lableText:{
            marginHorizontal: 'auto',
            fontSize: 18,
            textAlign: "left",
            color: '#4e3e71'
      },
      centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
      },
      modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
      },
      button: {
        borderRadius: 12,
        paddingVertical: 15,
        paddingHorizontal: 50,
        marginHorizontal: 20,
        backgroundColor: '#4e3e71',
        marginBottom: 10,
        marginTop: 10
      },
      buttonText: {
        color: '#e8fafe',
        fontSize: 17,
        textAlign: 'center',
      },
      buttonOpen: {
        backgroundColor: "#F194FF",
      },
      buttonClose: {
        backgroundColor: "#2196F3",
      },
      textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
      },
      modalText: {
        marginBottom: 15,
        textAlign: "center"
      },
          errormsg: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
          },  
          image: {
            flex: 0.2,
            height: 60,
            margin: 10,
            borderRadius: 10,
            backgroundColor: "#eff8ff"
          },
          card: {
            flex: 1,
            marginVertical: 10,
            marginHorizontal: 20,
            borderRadius: 10,
            flexDirection: 'row',
            backgroundColor: "#fff",

          },
          name: {
            flex: 0.6,
            marginVertical: "auto",
            marginHorizontal: 10,
            borderRadius: 10,
            color: '#007af2',
            fontSize: 20
          },
          icon:{
            flex: 0.2,
            marginLeft: 10,
            marginVertical: 'auto'
          },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 24,
            backgroundColor: '#4e3e71',
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
         
})  
// Customizable Area End