import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Text,
  ActivityIndicator,
  TextInput,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";

import { Formik } from "formik";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import "../../email-account-registration/src/react-intl-tel-input/index.css"
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (

        window.screen.width < 950 ? 
      <View style={styles.container}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
             <img src={require("../assets/backIcon.png")} style={{
               height: 24, width: 24,
             }} />
           </TouchableOpacity>
      <View style={styles.card}>
          <img src={require("../assets/illustration-register-copy@3x.png")} style={{
        width: 216,
        height: 234,
        position: 'absolute',
        top: -165,
        left: 180,
        zIndex: 99
        }}/>
              {/* headline */}
              <View style={styles.headline}>
                <Text style={styles.titleText}>
                  {this.labelTextIsAccountRecovery}
                </Text>
                    <Text style={styles.stepText}>Please enter your 
                    <Text style={{fontWeight: 'bold'}}> email address or phone number </Text> 
                    to reset your password </Text>
            
            </View>
              {this.state.phoneField  ? (
            
                <View style={styles.phoneInput}>
                        <PhoneInput
               isValid={(value:any, country:any) => {
                if (value.match(/12345/)) {
                  return 'Invalid value: '+value+', '+country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
               country={'in'}
               buttonClass="flagsdropdown"
               containerClass="PhoneInput"
               inputClass="PhoneInputInput"
              placeholder="Enter phone number"
              value={this.state.userInput}             
               onChange={value => this.setPhoneValue(value)}
              // onBlur={handleBlur('fullphone')}
              />
              <TouchableOpacity disabled={this.state.loader}  onPress={() => this.goToOtpAfterPhoneValidation(this.state.phoneValue)}>
              <View style={this.state.loader ? styles.unactive_button : styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          {this.state.loader ? <ActivityIndicator style={{position: 'absolute', right: 160}} size="large" color="#2e2442" /> : null }
      </View>
    </TouchableOpacity>
                </View>
              ) :    <View>
              <TextInput
              testID={"txtInputLastName"}
              style={styles.inputWeb}
              onChangeText={(text) => 
                this.handleText(text)}
              placeholder="Enter Number or Email"
              placeholderTextColor="#b2bac6"
               //Merge Engine::From BDS - {...this.testIDProps}
            /> 
           <TouchableOpacity disabled={this.state.loader}  onPress={() => this.goToOtpAfterEmailValidation(this.state.emailValue)}>
              <View style={this.state.loader ? styles.unactive_button : styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          {this.state.loader ? <ActivityIndicator style={{position: 'absolute', right: 160}} size="large" color="#2e2442" /> : null }
      </View>
    </TouchableOpacity>
          </View>}
          </View>
        </View>
        :
        <View style={styles.desktopContainer}>
           <ImageBackground source={require('../assets/group.png')} resizeMode="cover" style={styles.image}>
            <View style={styles.upmedlogo}>
              <img src={require("../assets/upmed.png")} style={{ height: 56, width: 140, paddingBottom: 20}} />
      
            <View style={styles.desktopCard}>
              <View style={styles.dcard}>
              <View style={styles.headline}>
                <Text style={styles.titleText}>
                  {this.labelTextIsAccountRecovery}
                </Text>
                    <Text style={styles.stepText}>Please enter your 
                    <Text style={{fontWeight: 'bold'}}> email address or phone number </Text> 
                    to reset your password </Text>
            
            </View>
              {this.state.phoneField  ? (
            
                <View style={styles.phoneInput}>
                        <PhoneInput
               country={'in'}
               buttonClass="flagsdropdown"
               containerClass="PhoneInput"
               inputClass="PhoneInputInput"
              placeholder="Enter phone number"
              value={this.state.userInput}             
               onChange={value => this.setPhoneValue(value)}
              />
              <TouchableOpacity disabled={this.state.loader}  onPress={() => this.goToOtpAfterPhoneValidation(this.state.phoneValue)}>
              <View style={this.state.loader ? styles.unactive_button : styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          {this.state.loader ? <ActivityIndicator style={{position: 'absolute', right: 160}} size="large" color="#2e2442" /> : null }
      </View>
    </TouchableOpacity>
                </View>
              ) :    <View>
              <TextInput
              testID={"txtInputLastName"}
              style={styles.inputWeb}
              onChangeText={(text) => 
                this.handleText(text)}
              placeholder="Enter Number or Email"
              placeholderTextColor="#b2bac6"
               //Merge Engine::From BDS - {...this.testIDProps}
            /> 
          <TouchableOpacity disabled={this.state.loader}  onPress={() => this.goToOtpAfterEmailValidation(this.state.emailValue)}>
              <View style={this.state.loader ? styles.unactive_button : styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          {this.state.loader ? <ActivityIndicator style={{position: 'absolute', right: 160}} size="large" color="#2e2442" /> : null }
      </View>
    </TouchableOpacity>
          </View>}
          </View>
            </View>
            </View>
              </ImageBackground>
        </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
   desktopContainer: {
      flex: 1,
  },
    image: {
    flex: 1,
    justifyContent: "center"
  },
  leftArrowIconWrapper:{
    position: 'absolute',
    top: 34,
    left: 34
  },
  upmedlogo: {
    margin: 'auto',
    alignItems: 'center'
  },
    desktopCard: {
    backgroundColor: '#e8fafe',
    margin: 'auto',
      borderRadius: 40,
      height: 470,
      width: 450,
    padding: 20
  },
  inputWeb: {
    flex: 1,
    marginVertical: 20,
    paddingVertical: 15,
    paddingStart: 10,
    fontSize: 18,
    color: '#4e3e71',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5'
  },
  container: {
    flex: 1,
    flexDirection: 'column-reverse',
    backgroundColor: "#4e3e71",
  },
  card: {
    position: 'relative',
    borderTopRightRadius: 24,
    backgroundColor: '#e8fafe',
    paddingHorizontal: 24
  },
  dcard: {
    // position: 'relative',
    borderTopRightRadius: 24,
    // backgroundColor: '#e8fafe',
    paddingHorizontal: 24
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  desktopbutton: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#4e3e71',
    marginBottom: 38,
    marginTop: 20
  },
  unactive_button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#8e7bb7',
    marginBottom: 38,
    marginTop: 20
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#4e3e71',
    marginBottom: 38,
    marginTop: 20
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    marginVertical: 10
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "column",
    alignItems: "stretch",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    marginVertical: 20,
    
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 96,
    paddingBottom: 20
  },

  stepText: {
    color: '#4e3e71',
    justifyContent: 'center',
    fontSize: 18,
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  }
});
