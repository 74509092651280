import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Picker,
  ActivityIndicator
} from 'react-native';
import { getErrorMessage, postAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';
import { Formik } from "formik";
import * as yup from "yup";
import CustomPopUp from "../../../framework/src/CustomPopUp";
import GlobalCss from '../../../framework/src/GlobalCss';

const validation_Schema = yup.object({
  name: yup.string().required("Name is required").matches(/^[a-zA-Z ]+$/, "Only characters are allowed").min(3, "must be atleast 3 characters"),
  email: yup.string()
    .email("Please enter a valid Email")
    .required("Email is required"),
  role_id: yup.string().required("role_id is required")
})




export default function InviteUser({ navigation, handleClose }: { navigation: any, handleClose: any }) {

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)
  const [loading, setLoading] = useState(false);

  const ClosePopUp = () => {
    setPopup(false)
  }

  const callAPI = async (values: any, actions: any) => {
    setLoading(true)
    const postData = {
      invite: values
    }
    let url = "/bx_block_invite/invities"
    await postAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0])
        setPopup(true)
        setMessage(msg)
        setLoading(false)
      } else {
        actions.resetForm()
        setLoading(false)
        setPopup(true)
        setMessage(`Email with link Sent to ${values.email}`)
      }
    });
  }

  return (

    <Formik
      initialValues={{ name: '', email: '', role_id: '' }}
      validationSchema={validation_Schema}
      onSubmit={(values, actions) => {
        callAPI(values, actions)
      }}
    >
      {
        ({ errors, touched, handleChange, handleBlur, values, handleSubmit }) => (
          <View style={styles.container}>
            {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message} />}
            {window.screen.width < 950 ? <View style={styles.headingView}>
              <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                <img src={require("../assets/leftArrow1.png")} style={{
                  height: 24, width: 24,
                }} />
              </TouchableOpacity>
              <Text style={styles.heading}>Invite User</Text>
            </View> : null}
            <View style={{ flexDirection: 'column', paddingHorizontal: 24, justifyContent: window.screen.width < 950 ? 'space-between' : 'center' }}>
              <View>

                <Text style={{ color: '#4e3e71', fontSize: 24, paddingTop: 20 }}>Invite Members</Text>

                <TextInput
                  style={styles.input}
                  placeholder="Full Name"
                  onBlur={handleBlur('name')}
                  placeholderTextColor="#b2bac6"
                  onChangeText={handleChange('name')}
                  value={values.name}
                />
                {touched.name && errors.name ? <Text style={{ color: 'red', padding: 2 }} >{errors.name}</Text> : null}
                <TextInput
                  style={styles.input}
                  placeholder="Email ID"
                  onBlur={handleBlur('email')}
                  placeholderTextColor="#b2bac6"
                  onChangeText={handleChange('email')}
                  value={values.email}
                />
                {touched.email && errors.email ? <Text style={{ color: 'red', padding: 2 }} >{errors.email}</Text> : null}
                <View style={styles.pickerContainer}>
                  <Picker
                    selectedValue={values.role_id}
                    // onBlur={handleBlur('role_id')}
                    style={styles.picker}
                    onValueChange={handleChange('role_id')}
                  >
                    <Picker.Item value='' label='Type of Member' />
                    <Picker.Item label="Surgeon" value="1" />
                    <Picker.Item label="Material Manager" value="2" />
                    <Picker.Item label="Booking Coordinator" value="3" />
                    <Picker.Item label="Sales Representative" value="4" />
                  </Picker>
                </View>
                {touched.role_id && errors.role_id ? <Text style={{ color: 'red', padding: 2 }} >{errors.role_id}</Text> : null}

              </View>
              <View style={styles.signUpLogin}>
                {
                  window.screen.width < 950 ?
                    <TouchableOpacity onPress={() => handleSubmit()}>
                      <View style={styles.button}>
                        <Text style={styles.buttonText}>Invite User </Text>
                      </View>
                    </TouchableOpacity> :
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <TouchableOpacity onPress={() => handleClose()}>
                        <View style={styles.button1}>
                          <Text style={styles.buttonText1}>Cancel </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity disabled={loading} onPress={() => handleSubmit()}>
                        <View style={loading ? styles.unactive_button : styles.button}>
                          <Text style={styles.buttonText}>Invite User </Text>
                          {loading ? (
                        <ActivityIndicator
                          style={GlobalCss.loader}
                          size="large"
                          color="#2e2442"
                        />
                      ) : null}
                        </View>
                      </TouchableOpacity>
                    </View>
                }

              </View>
            </View>
          </View>

        )
      }

    </Formik>
  )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffff",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  input: {
    outlineStyle: 'none',
    flex: 1,
    marginVertical: 20,
    paddingVertical: 10,
    paddingLeft: 10,
    fontSize: 18,
    color: '#112950',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5'
  },
  pickerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5',
    paddingVertical: 10
  },
  signUpLogin: {

  },

  picker: {
    width: '100%',
    height: 50,
    color: '#4e3e71',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'white'
  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 27,
    backgroundColor: '#4e3e71',
    marginBottom: 20,

  },
  heading: {
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: window.screen.width < 950 ? 380 : 80
  },
  unactive_button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#8e7bb7',
    marginBottom: 10,
    marginTop: window.screen.width < 950 ? 380 : 80
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  button1: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 60,
    marginHorizontal: 20,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 80
  },
  buttonText1: {
    color: '#4e3e71',
    fontSize: 17,
    textAlign: 'center',
  },
});
