import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
} from "react-native";

export default function Footer({
  navigation,
  userData,
}: {
  navigation: any;
  userData: any;
}) {

  const [color, setColor] = useState<any>({
    id1: false,
    id2: false,
    id3: false,
    id4: false,
  });
  const changeRoute = (route: any) => {
    navigation.navigate(route);
  };

  useEffect(() => {
    const assignlist = () => {
      const user = JSON.parse(
        localStorage.getItem("loggedinUserInfo") || "{}"
      );
      let id = user.role_id
      if (id === 1) {
        setrouteList(routeListSurgeon);
      } else if (id === 2) {
        setrouteList(routeListMaterialManage);
      } else if (id === 3) {
        setrouteList(routeListBookingCoordinator);
      } else {
        setrouteList(routeListSalesRep);
      }
    };

    assignlist();
  }, []);

  const routeListSurgeon = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/home.png"),
    },
    {
      name: "Start Booking",
      routename: "CreateBooking",
      status: "confirmed",
      image: require("../assets/add.png"),
    },
    {
      name: "All Bookings",
      routename: "ExBooking",
      status: "confirmed",
      image: require("../assets/book.png"),
    },

    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      image: require("../assets/shopping.png"),
    },
  ];

  const routeListMaterialManage = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/homeIcon.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/add.png"),
    },
    {
      name: "All Bookings",
      routename: "ExBooking",
      status: userData.status,
      image: require("../assets/book.png"),
    },
    {
      name: "Validate",
      routename: "ValidateManager",
      status: userData.status,
      image: require("../assets/validate.png"),
    },
  ];

  const routeListBookingCoordinator = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/home.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/hospital.png"),
    },
    {
      name: "All Bookings",
      routename: "ExBooking",
      status: userData.status,
      image: require("../assets/book.png"),
    },
    {
      name: "Validate",
      routename: "ValidateBC",
      status: userData.status,
      image: require("../assets/validate.png"),
    },
  ];

  const routeListSalesRep = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/home.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/hospital.png"),
    },
    {
      name: "All Bookings",
      routename: "ExBooking",
      status: "confirmed",
      image: require("../assets/book.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      image: require("../assets/shopping.png"),
    },
  ];

  const [routeList, setrouteList] = useState<any>([]);

  return (
    <View style={styles.container}>
      <FlatList
        data={routeList}
        horizontal={true}
        scrollEnabled={true}
        keyExtractor={(item: any) => item.name}
        renderItem={({ item }) => {
          return (
            <View>
              {item.status === "pending" ? (
                <View>
                  <View style={styles.list}>
                    <View style={styles.innerdiv}>
                      <img src={item.image} style={{ height: 20, width: 18 }} />
                      <Text style={styles.text2}>{item.name}</Text>
                    </View>
                  </View>
                </View>
              ) : (
                <TouchableOpacity onPress={() => changeRoute(item.routename)}>
                  <View style={styles.list}>
                    <View style={styles.innerdiv}>
                      <img src={item.image} style={{ height: 20, width: 18 }} />
                      <Text style={styles.text}>{item.name}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    // marginHorizontal: 'auto',
    paddingVertical: 6,
    paddingHorizontal: 22,
    borderColor: "#000000",
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  list: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 9,
    // marginHorizontal: 'auto'
  },
  innerdiv: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
  },
  text: {
    color: "#486484",
    justifyContent: "center",
    fontSize: 13,
  },
  text2: {
    color: "#b2bac6",
    justifyContent: "center",
    fontSize: 13,
  },
});
