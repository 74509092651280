// Customizable Area Start
import React, {useState, useEffect} from "react";
import {
      ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CallIcon from '@material-ui/icons/Call';
import SharedNotes from "./SharedNotes";
import Dialog from '@material-ui/core/Dialog';
import STATUS from "./Tracking2"
import { hideName, ageWithMonth } from "../../../framework/src/Utilities";
import scale from "../../../components/src/Scale";
import { getAxiosAPI, getErrorMessage, patchAxiosAPI } from "../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../framework/src/CustomPopUp";

import { Checkbox } from "@material-ui/core";
import GlobalCss from "../../../framework/src/GlobalCss";
import ProposedDateTime from "./bookingcor/ProposedDate&Time";
import EditBooking from "./EditBookingDate&Time";
import { height } from "@mui/system";



export default function DesktopSurgeryDetails({ navigation, user }: { navigation: any, user: any }) {
      const role_id = localStorage.getItem("role")
      const [salesRep, setSalesRep] = useState<any>({})
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const [ reassignPopup, setReassignPopup] = useState(false);
      const [ propseDateTime, setPropseDateTime] = useState(false);
      const [ editDetailsBox, setEditDetailsBox] = useState(false);
      const [ reassignList, setReassignList] = useState<any>([]);
      const [materialManager, setMaterialmanager] = useState<any>({})

      const getSingleOrder = async() => {
            let b_id = JSON.parse(localStorage.getItem('previous_booking') || '{}')
            let url = `/bx_block_order/orders/${b_id.id}`
            await getAxiosAPI(url).then((res: any) => {
                  if(!res.error){
                        localStorage.setItem('previous_booking', JSON.stringify(res.data.data));
                        res.data.data.attributes.ordered_materials?.data?.filter((x: any) => x.attributes.assign_to_type === "AccountBlock::Account").slice(0, 1).map((x: any, y: any) => {
                        setSalesRep(x.attributes.assign_to.data.attributes)
                        })        
                        res.data.data.attributes.ordered_materials?.data?.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").slice(0, 1).map((x: any, y: any) => { 
                              setMaterialmanager(x.attributes.assign_to.data.attributes)
                        })        
                        let reasingData = user?.attributes?.ordered_materials.data?.filter((x:any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").map((x:any) => 
                        ({
                              name: x.attributes.materials.data.attributes.name,
                              id: x.id,
                              isChecked: false
                        }))
                        setReassignList(reasingData)
                  }
                })
      }
      useEffect(() => {
           getSingleOrder()
      }, [])

      const handleRessingCheckbox = (x: any) => {
            let newData = reassignList.map((item:any) => {
                  if(x.id === item.id){
                      item.isChecked = !item.isChecked  
                  }
                  return item
            })
            setReassignList(newData)
      }

      const handleReassignSubmit = async () => {
            let ids = reassignList.filter((x:any) => x.isChecked).map((x:any) =>parseInt(x.id)) 
            let postData = {
                  ordered_material_ids: ids
            }
            let url = `bx_block_materials/reassign_material`;
            await patchAxiosAPI(url, postData).then((res: any) => {
              if(!res.error){
                  setReassignPopup(false)
                  setPopup(true)
                  setMessage("Assigned Successfully")
                  getSingleOrder()
              }
            });
      }


      const ClosePopUp = () => {
            setPopup(false)
            window.location.reload();
      }
      
       const date = user.attributes.booking_datetime
      var d = new Date(date);

       const BookingStatus = (item: any) => {
            if (item.attributes.confirmed_by_bc === true &&
            item.attributes.confirmed_by_material_manager === true &&
            item.attributes.confirmed_by_sales_rep === true) {
                  return "Confirmed"
            } else {
                  return "Pending"
            }
      }
      const [open, setOpen] = React.useState(false);
      const [openStatus, setOpenStatus] = React.useState(false);

      const handleClose = () => {
            setOpen(false);
            setPropseDateTime(false)
      };
      const handleCloseEdit = () => {
            setEditDetailsBox(false)
      };
      const handleCloseStatus = () => {
            setOpenStatus(false);
      };

      const handleOpenStatus = () => {
            setOpenStatus(true)
      }
      
      
      const chats = (id: any) => {
            const chatApiParams = {
                  material_id : id,
                  order_id: user.id
            }
            localStorage.setItem('chatApiParams', JSON.stringify(chatApiParams))
            setOpen(true);
      }

      const [open2, setOpen2] = useState(false)

      const openMaterialDetails = (item: any) => {

            setOpen2(true)
            localStorage.setItem('MaterialDialogDetails', JSON.stringify(item))
      }
      const MaterialDialogDetails: any = JSON.parse(localStorage.getItem("MaterialDialogDetails") || '{}')
      const [open3, setOpen3] = useState(false)

      const [contactDetailsDrawer, setContactDetailsDrawer] = useState<any>({})
      const openContactDialog = (role: any, name: any, number: any) => {
            const data = {
                  role: role,
                  name: name,
                  number: number
            }
            setContactDetailsDrawer(data)
            setOpen3(true)
      }
      
      const BC_bookingConfirmaton = async () => {
            const postData = {
                  id: user.id,
                  order: {
                        confirmed_by_bc: true
                  }
            }
            let url = `/bx_block_order/orders/modify_order_by_bc`;
            await patchAxiosAPI(url, postData).then((res: any) => {
              if(!res.error){
                  setPopup(true)
                  setMessage("Surgery Confirmed")
                  navigation.goBack()
              }
            });
      }

     const editDetailsClick = () => {
      const data = {
            bookingId: user.id,
            bookingDateTime: user.attributes.booking_datetime,
            hospitalId: user.attributes.hospital.data.id
      }
      localStorage.setItem("editBookingProps", JSON.stringify(data))
      // navigation.navigate("EditBookingDateTime")
      setEditDetailsBox(true)
     }


      const DisplayBtn = () => {
            if (role_id === '1') {
                  return (
                        <TouchableOpacity onPress={() => handleOpenStatus()}>
                              <View style={styles.button1}>
                                    <Text style={styles.buttonText1}>Track Status</Text>
                              </View>
                        </TouchableOpacity>
                  )
            } else if (role_id === '2') {
                  if (user.attributes.confirmed_by_material_manager) {
                        return (
                              <View style={styles.button1}>
                                    <Text style={styles.buttonText1}> Surgery Confirmed </Text>
                              </View>
                        )
                  } else {
                        return (
                              <TouchableOpacity onPress={() => navigation.navigate('CheckOffMaterial')}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Checkoff Material</Text>
                                    </View>
                              </TouchableOpacity>  
                        )
                  }
            } else if (role_id === '3') {
                  if (user.attributes.confirmed_by_bc) {
                        return (
                        <View style={styles.button1}>
                              <Text style={styles.buttonText1}> Surgery Confirmed </Text>
                        </View>
                        )
                  } else {
                        return (
                              <TouchableOpacity onPress={() => BC_bookingConfirmaton()}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Confirm Surgery </Text>
                                    </View>
                              </TouchableOpacity>
                        )
                  }
            } else if (role_id === '4') {
                  if (user.attributes.confirmed_by_sales_rep) {
                        return (
                              <View style={styles.button1}>
                                    <Text style={styles.buttonText1}> Surgery Confirmed </Text>
                              </View>
                        )
                  } else {
                        return (
                              <TouchableOpacity onPress={() => navigation.navigate('CheckOffMaterial')}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Checkoff Material</Text>
                                    </View>
                              </TouchableOpacity>
                        )
                  }
            } else {
                  return null
            }
      }

      const renderMMDetails = () => {
            if(Object.keys(materialManager).length) {
                  return <View style={styles.detailsList3}>
                  <View style={{flexDirection: 'row'}}>
                  <View>
                  <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                  </View>
                  <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                        <Text style={{color: '#7f7f7f', fontSize: 14}}>{materialManager.first_name}  {materialManager?.last_name}</Text>
                        <Text style={{color: '#4e3e71', fontSize: 17}}>Material Manager</Text>
                  </View>

                  </View>
                  <Text >
                        
                  <TouchableOpacity onPress={() => openContactDialog("Material Manager", materialManager?.first_name + " " + materialManager?.last_name, materialManager?.full_phone_number )}>
                  <CallIcon
                  style={{
                  color: '#cce9e5',
                  fontSize: 20,
                  backgroundColor: '#4e3e71',
                  borderRadius: "50%",
                  padding: "5px",
                  justifyContent: 'center'}} />
                  </TouchableOpacity>
                  
                  </Text>
                              </View>
            } else return null
      }

      const renderSRDetails = () => {
            if(Object.keys(salesRep).length) {
                  return  <View style={styles.detailsList3}>
                  <View style={{flexDirection: 'row'}}>
                      <View>
                      <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                      </View>
                      <View style={{flexDirection: 'column', paddingHorizontal: 5}}>                            
                        <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{salesRep?.first_name + " " + salesRep?.last_name}</Text>
                            <Text style={{color: '#4e3e71', fontSize: 17}}>Sales Rep</Text>
                      </View>
                  </View>
                  <Text >
                  <TouchableOpacity onPress={() => openContactDialog("Sales Rep", salesRep?.first_name + " " + salesRep?.last_name, salesRep?.full_phone_number)}>
                  <CallIcon
                  style={{
                  color: '#cce9e5',
                  fontSize: 20,
                  backgroundColor: '#4e3e71',
                  borderRadius: "50%",
                  padding: "5px",
                  justifyContent: 'center'}} />            
                        </TouchableOpacity>
                  
                  </Text>
                  </View>
            } else return null
      }

      const handleProposeDateTime = () => {
            const proposeApiData = {
                  order_id: user.id,
                  surgeon_id: user.attributes.doctor.data.id,
                  bookingDateTime: user.attributes.booking_datetime
                };
                localStorage.setItem("proposeApiData", JSON.stringify(proposeApiData));
                setPropseDateTime(true)
      }

      return (
            <View style={styles.desktopContainer}>
                        {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
          <DesktopHeader heading={'Surgery Details'} navigation={navigation} />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={styles.mainData}>
            <View style={GlobalCss.outerCard}>
                        <Text style={GlobalCss.watermarkText}>CONFIDENTIAL</Text>
                  <View style={styles.card}>
                                    <View style={{ flex: 0.2, borderRadius: 12, margin: 15, padding: 15, borderWidth: 1, borderColor: '#7f7f7f' }}>
                                          <img 
                                          src={user.attributes.patient_image ? user.attributes.patient_image : user.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") 
                                           }
                                           style={{ borderRadius: 12, height:160 }} />
                                          <View style={styles.infoRow}>
                                                <Text style={{color: '#4e3e71', fontWeight: 'bold'}}>Information</Text>
                                                <Text style={{backgroundColor: '#4e3e71', color: '#fff', borderRadius: 8, padding: 8}}>{user.attributes.patient_type}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Patient name:</Text>
                                                <Text style={GlobalCss.purpleColor}>{role_id == '4' ? hideName(user.attributes?.first_name, user.attributes?.last_name) : user.attributes?.first_name+" "+user.attributes?.last_name}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Age:</Text>
                                                <Text style={GlobalCss.purpleColor}>{ageWithMonth(user.attributes.patient_age)}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Email:</Text>
                                                <Text style={GlobalCss.purpleColor}>{user.attributes.patient_email}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Phone:</Text>
                                                <Text style={GlobalCss.purpleColor}>{user.attributes.patient_phone}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Hospital name:</Text>
                                                <Text style={GlobalCss.purpleColor}>{user.attributes.hospital.data?.attributes.name}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Type:</Text>
                                                <Text style={GlobalCss.purpleColor}>{user.attributes.patient_type}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Date: </Text>
                                                <Text style={GlobalCss.purpleColor}>{d.toLocaleDateString()}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Time: </Text>
                                                <Text style={GlobalCss.purpleColor}>{d.toLocaleTimeString([], {timeStyle: 'short'})}</Text>
                                          </View>
                                           <View style={styles.infoRow}>
                                                <Text style={styles.colorGrey}>Status: </Text>
                                                <Text style={GlobalCss.purpleColor}>{BookingStatus(user)}</Text>
                                          </View>
                                    </View>
                                      {/* <TouchableOpacity>
                                          <View style={styles.button}>
                                          <Text style={styles.buttonText}>{d.toLocaleDateString()}</Text>
                                          </View>
                                    </TouchableOpacity> */}
                                    <View style={GlobalCss.watermarkRightCard}>
                                          <View style={{ width: '50%', margin: 10, borderRightWidth: 1, borderRightColor: '#7f7f7f', height: '100%' }}>
                                         
                                          <View>
                                          <Text style={{color: '#4e3e71', fontWeight: 'bold', fontSize: 16}}>Insurance Information: </Text>
                                          <Text style={styles.heading02}>1) Insurance carrier: <Text style={GlobalCss.purpleColor}>{ user.attributes.insurance_carrier}</Text></Text>
                                          <Text style={styles.heading02}>2) Policy/Member id: <Text style={GlobalCss.purpleColor}>{ user.attributes.policy_member_id}</Text></Text>
                                          <Text style={styles.heading02}>3) Authorization: <Text style={GlobalCss.purpleColor}>{ user.attributes.authorization}</Text></Text>
                                          <Text style={styles.heading02}>4) Benefits: <Text style={GlobalCss.purpleColor}>{ user.attributes.benefits}</Text></Text>
                                          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                          <Text style={styles.heading01}>Booking Information: </Text>
                                          {role_id === '1' && user.attributes.booking_datetime >= new Date().getTime() ? <TouchableOpacity onPress={() => editDetailsClick()}>
                                          <Text style={styles.heading04}>Edit Details</Text>
                                          </TouchableOpacity> : null }
                                          </View>
                                          
                                          <Text style={styles.heading02}>1) Patient Status: <Text style={GlobalCss.purpleColor}>{ user.attributes.patient_type}</Text></Text>
                                          <Text style={styles.heading02}>2) Latex Allergies: <Text style={GlobalCss.purpleColor}>{ user.attributes.latex_allergies}</Text></Text>
                                          <Text style={styles.heading02}>3) O.R. Table: <Text style={{ color: '#4e3e71' }}>{user.attributes.or_table}</Text></Text>
                                          <Text style={styles.heading01}>Postop</Text>
                                          <Text style={styles.heading02}>4) Physiotherapy: <Text style={{ color: '#4e3e71' }}>{user.attributes.physio_therapy ? "Yes" : "No"}</Text></Text>
                                          <Text style={styles.heading02}>5) Homecare: <Text style={{ color: '#4e3e71' }}>{user.attributes.homecare ? "Yes" : "No"}</Text></Text>
                                          </View> 

                                          <Text style={styles.heading01}>Imaging </Text>
                                          {
                                                user.attributes.imagings.map((x: any, y: any) => 
                                                      <Text style={styles.heading02}>{y+1}) {x}</Text>
                                                )
                                          }

                                          {
                                                role_id === '2' ? 
                                                <View>
                                                <Text style={styles.heading01}>Doctor Details </Text>
                                                                  <Text style={styles.heading02}>1) Phone Number: <Text style={{ color: '#4e3e71' }}>{"+"+user.attributes.doctor.data.attributes.country_code+" "+user.attributes.doctor.data.attributes.phone_number}</Text></Text>
                                                                  <Text style={styles.heading02}>2) Email: <Text style={{ color: '#4e3e71' }}>{user.attributes.doctor.data.attributes.email}</Text></Text>
                                                </View> : null
                                          }
                                          
                                          {user.attributes.ordered_materials.data.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").length ? 
                                          <>
                                          <Text style={styles.heading01}>Surgery equipment(Facility): </Text>
                                          {role_id === '2' && <TouchableOpacity
                                                
                                                onPress={() => setReassignPopup(true)}
                                          >
                                                <Text style={styles.heading01}>Reassign Material</Text>
                                          </TouchableOpacity>}
                                          </>
                                          : null}
                                          
                                          {
                                                 user.attributes.ordered_materials.data.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").map((x:any, y:any) => {
                                          return (
                                                <View style={styles.drawerItem}>
                                                      <TouchableOpacity onPress={() => openMaterialDetails(x)}>
                                                            <Text style={{ color: '#7f7f7f', flex: 0.5, fontSize: 17 }}>{y + 1}) Material {y + 1}</Text>
                                                      </TouchableOpacity>
                                                      <View style={{ backgroundColor: x.attributes.status === 'A' ? '#288600' : x.attributes.status === 'TBH' ? 'blue' : 'red', height: 16, width: 16, borderRadius: 8, marginVertical: 'auto', marginHorizontal: 10 }}></View>
                                               <TouchableOpacity style={{flex: 0.1}} onPress={() => chats(x.attributes.material_id)}>
                                                <img src={x.attributes.flagged ? require("../assets/chatIconRed.png") :  require("../assets/chatIcon.png")}  style={{ height: 15, width: 15, paddingTop: 5}}/>
                                               </TouchableOpacity>
                                                <Text style={{ color: '#7f7f7f', flex: 0.2,fontSize: 17, paddingLeft: 20}}>{x.attributes.quantity}</Text>

                                          </View>
                                          )
                                    })
                                                }
                                                
                                          </View>

                                          <View style={{width: '50%', margin: 10, height: '100%'}}>
                                                {user.attributes.ordered_materials.data.filter((x:any) => x.attributes.assign_to_type === "AccountBlock::Account").length ? <Text style={styles.heading01} >Surgery equipment(Salesrep)</Text> : null }
                                                
                                                 {
                                    user.attributes.ordered_materials.data.filter((x:any) => x.attributes.assign_to_type === "AccountBlock::Account").map((x:any,  y: any) => {
                                          return (
                                                <View style={styles.drawerItem}>
                                                      <TouchableOpacity onPress={() => openMaterialDetails(x)}>
                                                            <Text style={{ color: '#7f7f7f', flex: 0.5, fontSize: 17 }}>{y + 1}) Material {y + 1}</Text>
                                                      </TouchableOpacity>
                                                      <View style={{ backgroundColor: x.attributes.status === 'A' ? '#288600' : x.attributes.status === 'TBH' ? 'blue' : 'red', height: 16, width: 16, borderRadius: 8, marginVertical: 'auto', marginHorizontal: 10 }}></View>
                                                 <TouchableOpacity style={{flex: 0.1}} onPress={() => chats(x.attributes.material_id)}>
                                                <img src={x.attributes.flagged ? require("../assets/chatIconRed.png") :  require("../assets/chatIcon.png")}  style={{ height: 15, width: 15, paddingTop: 5}}/>
                                               </TouchableOpacity>
                                                <Text style={{ color: '#7f7f7f',fontSize: 17, flex: 0.2, paddingLeft: 20}}>{x.attributes.quantity}</Text>
                                          </View>
                                          )
                                    })
                                                }
                                                <View style={{ paddingHorizontal: 10, paddingVertical: 20, }} >
                                                      <Text style={{ color: '#4e3e71', fontWeight: 'bold', fontSize: 20 }}>Disposable List</Text>
                                                      <Text style={{ padding: 5, fontSize: 16, color: '#7f7f7f' }}>1) Drapes: <Text style={{ color: '#4e3e71' }}>{user.attributes.draps}</Text></Text>
                                                      <Text style={{ padding: 5, fontSize: 16, color: '#7f7f7f' }}>2) Sutures: <Text style={{ color: '#4e3e71' }}>{user.attributes.sutures}</Text></Text>
                                                      <Text style={{ padding: 5, fontSize: 16, color: '#7f7f7f' }}>3) Sutures Size: <Text style={{ color: '#4e3e71' }}>{user.attributes.sutures_size}</Text></Text>
                                                </View> 

                                               
                                                <View>
                                                <Text style={styles.heading01}>Contact Details: </Text>

                                                 <View style={styles.detailsList3}>
                                                <View style={{flexDirection: 'row'}}>
                                                <View>
                                                <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                                                </View>
                                                <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                                      <Text style={{color: '#7f7f7f', fontSize: 14}}>{user.attributes.booking_coordinator?.data?.attributes?.first_name} {user.attributes.booking_coordinator?.data?.attributes?.last_name}</Text>
                                                      <Text style={{color: '#4e3e71', fontSize: 17}}>Booking Co-ordinator </Text>
                                                </View>

                                                </View>
                                                <Text >
                                                
                                                <TouchableOpacity onPress={() => openContactDialog("Booking Co-ordinator", user.attributes.booking_coordinator.data.attributes?.first_name + " " + user.attributes.booking_coordinator.data.attributes?.last_name, user.attributes.booking_coordinator.data.attributes?.full_phone_number)}>
                                                <CallIcon
                                                style={{
                                                color: '#cce9e5',
                                                fontSize: 20,
                                                backgroundColor: '#4e3e71',
                                                borderRadius: "50%",
                                                padding: "5px",
                                                justifyContent: 'center'}} />
                                                </TouchableOpacity>
                                                </Text>
                                                </View>
                                               {renderMMDetails()}
                                                {renderSRDetails()}

                                                      </View> 
                                                {
                                                      role_id === '3' && new Date().getTime().toString() < user.attributes.booking_datetime ? 
                                                <View style={{ marginHorizontal: 50}}>
                                               {user.attributes.confirmed_by_material_manager ? null :  <TouchableOpacity onPress={() => navigation.navigate('CheckOffMaterial')}>
                                                      <View style={GlobalCss.buttonView}>
                                                            <Text style={GlobalCss.buttonText}>Take on MM role</Text>
                                                      </View>
                                                </TouchableOpacity>}
                                                </View> : null     
                                                }

                                                    {
                                                      role_id === '3' && new Date().getTime().toString() < user.attributes.booking_datetime ? 
                                                <View style={{ marginHorizontal: 50}}>
                                                <TouchableOpacity onPress={() => handleProposeDateTime()}>
                                                      <View style={styles.button1}>
                                                            <Text style={styles.buttonText1}>Propose Date & Time </Text>
                                                      </View>
                                                </TouchableOpacity>
                                                </View> : null     
                                                }
                                          </View>

                                    </View>
                                    <Dialog
                                          open={open3}
                                          onClose={handleClose}
                                    >

                                          <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-end' }} onPress={() => setOpen3(false)}>
                                                <img src={require("../assets/cancel.png")} style={{ height: 30, width: 30, margin: 10 }} />
                                          </TouchableOpacity>
                                          <View style={{ width: 280, height: 80, marginVertical: 30, marginHorizontal: 20, flexDirection: 'row' }}>
                                                <View>
                                                      <img src={require("../assets/personsmall.png")} style={{ height: 20, width: 20 }} />
                                                </View>
                                                <View style={{ flexDirection: 'column' }}>
                                                      <Text style={{ color: '#7f7f7f', fontSize: 17, width: scale(75) }}>{contactDetailsDrawer?.name}({contactDetailsDrawer?.role})</Text>
                                                      <Text style={{ color: '#4e3e71', fontSize: 15, paddingTop: 5 }}>{contactDetailsDrawer?.number}</Text>

                                                </View>

                                          </View>
                                    </Dialog>


                                    
                  </View>

                 <View style={{margin: 15, flexDirection: 'row', justifyContent: 'space-between'}}>
                      <TouchableOpacity onPress={() => navigation.goBack()}>
                              <View style={styles.button2}>
                              <Text style={styles.buttonText2}>Back</Text>
                              </View>
                                    </TouchableOpacity>
                                    <View>
                                          {new Date().getTime().toString() < user.attributes.booking_datetime ? DisplayBtn() : null }
                                    </View>
               
                 </View>
            </View>
            </View>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ width: 500, height: 800 }}>
                              <SharedNotes navigation={navigation} />
                        </View>

                  </Dialog>
                  <Dialog
                        open={openStatus}
                        onClose={handleCloseStatus}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ width: 350, height: 300 }}>
                              <STATUS navigation={navigation} />
                        </View>

                  </Dialog>
                  <Dialog
                        open={open2}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ marginBottom: 10 }}>
                              <View style={{ margin: 20, height: 200, width: 350 }}>
                                    <Text style={{ fontWeight: 'bold', paddingVertical: 15, paddingHorizontal: 'auto', color: '#4e3e71', fontSize: 20, borderBottomColor: 'grey', borderBottomWidth: 1 }}>{MaterialDialogDetails?.attributes?.materials?.data?.attributes?.name} </Text>

                                    <View style={styles.boxView}>
                                          <Text style={styles.boxtext} >Status:</Text>
                                          <Text style={styles.boxText2}>{MaterialDialogDetails?.attributes?.status === "A" ? "Available" : "Pending"}</Text>
                                    </View>
                                    <View style={styles.boxView}>
                                          <Text style={styles.boxtext}>Size:</Text>
                                          <Text style={styles.boxText2}>{MaterialDialogDetails?.attributes?.size}</Text>
                                    </View>
                                    <View style={styles.boxView}>
                                          <Text style={styles.boxtext}>Quantity: </Text>
                                          <Text style={styles.boxText2}>{MaterialDialogDetails?.attributes?.quantity} Pieces</Text>
                                    </View>
                                    <View style={styles.boxView}>
                                          <Text style={styles.boxtext}>Assigned To:</Text>
                                          <Text style={styles.boxText2}>{MaterialDialogDetails?.attributes?.assign_to_type === "BxBlockHospital::Hospital" ? "Facility" : "Sales Rep"}</Text>
                                    </View>


                              </View>
                              <TouchableOpacity onPress={() => chats(MaterialDialogDetails?.attributes?.material_id)}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Chat</Text>
                                    </View>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => setOpen2(false)}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Close</Text>
                                    </View>
                              </TouchableOpacity>
                        </View>

                  </Dialog>
                  <Dialog
                        open={reassignPopup}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <Text style={styles.heading03}>Surgery Equipment(Facility)</Text>
                        <ScrollView style={styles.reassignPopup}>
                        {reassignList.map((x:any,  y: any) => 
                        <View style={styles.reAssingList}>
                        <Text numberOfLines={2} style={styles.heading02}>{x.name}</Text>
                        <Checkbox
                                    checked={x.isChecked}
                                    onChange={() => handleRessingCheckbox(x)}
                                    name="Carm"
                                    style={{ color: "#4e3e71"}} 
                              />
                        </View>)}
                        </ScrollView>
                        <TouchableOpacity onPress={() => handleReassignSubmit()} style={GlobalCss.buttonView}>
                              <Text style={GlobalCss.buttonText}>Assign to sales rep</Text>
                        </TouchableOpacity>
                  </Dialog>
                  <Dialog
                        open={propseDateTime}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                  <View style={styles.proposeDT}>
                              <ProposedDateTime handleClose={handleClose} navigation={navigation}/>
                  </View>
                  </Dialog>

                  {/* EDIT DETAILS BOX */}
                  <Dialog
                        open={editDetailsBox}
                        onClose={handleCloseEdit}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={styles.EditDialouge}>
                              <EditBooking navigation={navigation}/>
                        </View>
                  </Dialog>
                  </View>
      )
}

                  
const styles = StyleSheet.create({
      mainData: { flex: 0.85, overflowY:"scroll"},
      watermarkText:{
            position:'absolute',
            top:'30%',
            left:'15%',
            transform:[{rotate: '-21deg'}],
            opacity:0.26,
            fontSize:136,
            zIndex: 1,
            fontWeight: '600',
            color: '#b0b0b6'
      },
      boxText2: { color: "#4e3e71", fontSize: 17 },
      boxView: { flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 },
      boxtext: { color: "#7f7f7f", fontSize: 17 },
        desktopContainer: {
    flex: 1,
    flexDirection: 'column'
      },
      colorGrey:{color: '#7f7f7f'},
      reAssingList:{
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      },
      proposeDT:{
      paddingHorizontal: 20,
      paddingVertical: 10
      },
      EditDialouge:{
            paddingHorizontal: 20,
            paddingVertical: 10,
            // height: 400,
            width: 400
      },
   desktopCard: {
    flex: 1,
    flexDirection: 'row'
      },
      reassignPopup: {
      height: 300,
      width: 400,
      padding: 20
      },
      heading01:{
            color: '#4e3e71', fontWeight: 'bold', fontSize: 16 
      },
      heading04:{
            color: '#4e3e71', fontWeight: 'bold', fontSize: 16, textDecorationLine: 'underline',
            marginHorizontal: 10,
      },
      heading03:{
            color: '#4e3e71', fontWeight: 'bold', fontSize: 18, paddingHorizontal: 20, paddingTop:20
      },
      heading02: { color: '#7f7f7f', paddingVertical: 5 },
            drawerItem: {
            paddingHorizontal: 5,
            flexDirection: 'row',
            marginVertical: 10,
            justifyContent: 'space-between'

      },
      detailsList3: {
      padding: 20,
      flexDirection: 'row',
      marginRight: 50,
      justifyContent: 'space-between'

      },
        button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
              backgroundColor: '#4e3e71',
              marginHorizontal: 20,
            marginVertical: 5
          },
          buttonText1: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
        button2: {
            borderRadius: 12,
            paddingVertical: 12,
              paddingHorizontal: 60,
              borderColor: '#4e3e71',
            borderWidth: 1,
            backgroundColor: '#fff',
          },
          buttonText2: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
          },
    bottomDesktopCard: {
    flex: 1,
    flexDirection: 'row',
      maxHeight :'90vh'
      },
    infoRow: {flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 5},
    button: {
            borderRadius: 18,
            paddingVertical: 5,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#4e3e71',
          },
          buttonText: {
            color: '#cce9e5',
            fontSize: 15,
            textAlign: 'center',
          },
      card: {
            flex: 1,
            flexDirection: 'row',
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            // padding: 15,
            margin: 15,
            flexDirection: 'column',
            position: 'relative'   
      }
})
// Customizable Area End
            