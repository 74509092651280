import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  enableField: boolean;
  // Customizable Area Start
  profileData: any;
  loader: boolean;
  editPage: boolean
  role: any;
  errorMessage: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.state = {  
      enableField: false,
      // Customizable Area Start
      profileData: {},
      loader: false,
      editPage: false,
      role: '',
      errorMessage: false
      // Customizable Area End
    };
  }



 

  getUserProfileData = async () => {
    this.setState({loader: true})
    let url;
    url = `/account_block/accounts/account_detail`
    await getAxiosAPI(url).then((response: any) => {
          if(response.error){
            let msg = getErrorMessage(response.data.errors[0])
            if(msg === 'Your session has been expired. Click here to login'){
              this.setState({errorMessage: true})
            }
            this.setState({loader: false})
          } else {
            this.setState({loader: false})
            localStorage.setItem('loggedinUserInfo', JSON.stringify(response.data.data.attributes))
            this.setState({profileData :response.data.data});
            this.setState({role :response.data.data.attributes.role_id});
          }
        })

  }

   editProfile = () => {
    localStorage.setItem('profileData', JSON.stringify(this.state.profileData))
    if (window.screen.width < 950) {
          this.props.navigation.navigate('EditProfile')
    } else {
          this.setState({editPage: true})
    }
    
}


  // Customizable Area Start
  // Customizable Area End
  
}
