/* App/Lib/GeneralHelpers.js */
import { Alert, Dimensions, Platform } from 'react-native';
//@ts-ignore
import _ from 'lodash';

import StorageProvider from './StorageProvider';
import Dialog from '@material-ui/core/Dialog';
import { deleteAxiosAPI } from './Blocks/AxiosAPIBlock';



export function getOS(): string {
  return Platform.OS;
}

/**
 * SCALING - SAME VIEW FOR TABLET AND IPHONE ADDED THIS SCALE IN HEIGHT, WIDTH, MARGIN, PADDING
 */
const { width, height, scale: deviceScale, fontScale } = Dimensions.get('window');
const baseWidth = 360;
const baseHeight = 700;

const scaleWidth = width / baseWidth;
const scaleHeight = height / baseHeight;
const scale = Math.min(scaleWidth, scaleHeight);

// const storageProvider = require('./StorageProvider');

export const scaleRatio = deviceScale;
export const deviceWidth = width;
export const deviceHeight = height;
export const deviceAspectRatio = width / height;
export const scaledSize = (size: any) => Math.ceil(size * scale);
export const widthFromPercentage = (per: number) => (width * per) / 100;
export const heightFromPercentage = (per: number) => (height * per) / 100;


function calcZoom(longitudeDelta: any) {
  // Omit rounding intentionally for the example
  return Math.log(360 / longitudeDelta) / Math.LN2;
}

function calcLongitudeDelta(zoom: any) {
  var power = Math.log2(360) - zoom;
  return Math.pow(2, power);
}

export const calculateDelta = () => {
  // Initial values
  var latitudeDelta = 0.004757;
  var longitudeDelta = 0.006866;

  var coef = latitudeDelta / longitudeDelta; // always the same no matter your zoom

  // Find zoom level
  var zoomLvlCalculated = calcZoom(longitudeDelta);
  //console.log(zoomLvlCalculated); // 15.678167523696594

  // Find longitudeDelta based on the found zoom  
  var longitudeDeltaCalculated = calcLongitudeDelta(zoomLvlCalculated); // 0.006865999999999988 which is the same like the initial longitudeDelta, if we omit the floating point calc difference
  //console.log('longitudeDeltaCalculated', longitudeDeltaCalculated);

  // Find the latitudeDelta with the coefficient
  var latitudeDeltaCalculated = longitudeDeltaCalculated * coef; //0.004756999999999992 which is the same like the initial latitudeDelta, if we omit the floating point calc difference
  //console.log('latitudeDeltaCalculated', latitudeDeltaCalculated);
  return {
    latitudeDelta: latitudeDeltaCalculated,
    longitudeDelta: longitudeDeltaCalculated
  }
}

/**
 * CHECKS IF THE PASSED VALUE IS EMPTY STRING OR NOT
 * RETURN `true` IF STRING IS EMPTY ELSE RETURN `false`
 */
export function isEmpty(val: any): boolean {
  let isValEmpty = true;
  if (!_.isNil(val) && _.trim(String(val)).length > 0) {
    isValEmpty = false;
  }
  return isValEmpty;
}

/**
 * CHECKS IF THE PASSED VALUE IS VALID EMAIL
 * RETURN `true` IF VALID ELSE RETURN `false`
 */
export function isEmail(fieldName: string, val: string) {
  //const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailReg = new RegExp("[^@]+[@][\\S]+[.][\\S]+");

  if (isEmpty(val)) {
    return { status: false, message: `Email address field cannot be empty.` };
  } else if (!emailReg.test(val)) {
    return { status: false, message: `Invalid email address.` };
  }
  return { status: true, message: "" };
}

/* To handle phone validation */
export function phoneValidate(fieldName: string, value: any) {
  console.log('phoneValidate');
  //const phoneRegex = /^962[0-9]{8,9}$/;
  //const phoneRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  if (value === "" || value === undefined || value === null) {
    return { status: false, message: `The ${fieldName} field cannot be left blank.` };
  } else if (!phoneRegex.test(value)) {
    return {
      status: false,
      message: `Please enter valid ${fieldName}`
    };
  }

  return { status: true, message: "" };
}

export function confirmPasswordValidate(
  fieldName: string,
  confirmPassword: string,
  fieldName2: string = 'password',
  password: string = ''
) {
  //const phoneRegex = /^962[0-9]{8,9}$/;
  if (confirmPassword === "" || confirmPassword === undefined || confirmPassword === null) {
    return { status: false, message: `The ${fieldName} field cannot be left blank.` };
  } else if (password && password !== confirmPassword) {
    return {
      status: false,
      message: `The ${fieldName} should be same as ${fieldName2}`
    };
  }

  return { status: true, message: "" };
}

export function passwordValidate(fieldName: string, password: string = '') {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (password === "" || password === undefined || password === null) {
    return { status: false, message: `The ${fieldName} field cannot be left blank.` };
  } else if (!passwordRegex.test(password)) {
    return {
      status: false,
      message: `The ${fieldName} should contain atleast 8 letters, one uppercase and one special character.`
    };
  }

  return { status: true, message: "" };
}

/* To handle required validation */
export function requireValidate(fieldName: string, value: any, isBool: boolean = false) {
  if (isBool) {
    if (value) {
      return { status: true, message: "" };
    }
    return { status: false, message: "" };
  }
  if (value === "" || value === undefined || value === null) {
    if (fieldName === "lmsUrl") {
      return { status: false, message: `Please select ${fieldName}` };
    }
    else {
      return { status: false, message: `The ${fieldName} field cannot be left blank.` };
    }
  }
  return { status: true, message: "" };
}

export const customAlert = (title: string = "", message: string = "", okOnPress: Function | any = null, cancelOnPress: Function | any = null) => {
  console.log("Called customAlert ==> ");

  const buttons: Array<any> = [];
  cancelOnPress ? buttons.push({ text: 'Cancel', onPress: () => cancelOnPress, style: 'cancel' }) : "";
  okOnPress ? buttons.push({ text: 'OK', onPress: () => okOnPress }) : buttons.push({ text: 'OK', onPress: () => console.log('Ok Pressed') })
  return (
    Alert.alert(
      title,
      message,
      buttons
    )
  );
}





export const hideName = (fname: any, lname: any) => {
  console.log({ fname, lname })
  if (fname.length < 2) {
    lname = lname.match(/.{1,2}/g)
    return '**' + " " + lname[0] + '*'.repeat(lname[1].length)
  } else if (lname.length < 2) {
    fname = fname.match(/.{1,2}/g)
    return fname[0] + '*'.repeat(fname[1].length) + " " + '**'
  } else if (fname.length < 2 && lname.length < 2) {
    return "** **"
  } else {
    fname = fname.match(/.{1,2}/g)
    lname = lname.match(/.{1,2}/g)
    return fname[0] + '*'.repeat(fname[1].length) + " " + lname[0] + '*'.repeat(lname[1].length)
  }
}


export const ageWithMonth = (timestamp: any) => {
  const dateOfBirth = new Date(timestamp * 1000);
  const ageInMonths = (new Date().getFullYear() - dateOfBirth.getFullYear()) * 12 + (new Date().getMonth() - dateOfBirth.getMonth());
  const ageInYears = Math.floor(ageInMonths / 12);
  const months = ageInMonths % 12;
  return ageInYears < 1 ? months + " months" : ageInYears + " years"
}

export const SortData = (a: any, b: any) => {
  return (a > b)
    ? 1 : ((b > a)
      ? -1 : 0);
}

export const getTimeStemp = (time: any) => {
  const now = new Date(time)
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0, 0, 0, 0
  ).getTime();
}

export const checkValues = (item: any, empty: any) => {
  return item ? item : empty
}

export const getRole = (id: any) => {
  if (id == 1) {
    return "Surgeon"
  } else if (id == 2) {
    return "Material Manager"
  } else if (id == 3) {
    return "Booking Coordinator"
  } else if (id == 4) {
    return "Sales Representative"
  } else {
    return " - "
  }
}




export async function setStorageData(key: string, data: any) {
  if (key && data) {
    await StorageProvider.set(key, data);
  }
}

export const logOut = async () => {
  const URL = '/account_block/accounts/logout'
  await deleteAxiosAPI(URL).then((responce) => {
    if(responce.error){
      customAlert("Something went Wrong", "try again after sometime ")
    } else {
      window.localStorage.clear();
      window.location.href = "/";
    }
  })
};

export const getUserIpAddress = async () => {
  let IpAddress;
  await fetch(
    "https://geolocation-db.com/json/")
    .then((res) => res.json())
    .then((json) => {
      // console.log(json)
      IpAddress = json.IPv4
    })
  return IpAddress
}

export const getParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
}


export const asyncLocalStorage = {
  setItem: async function (key: any, value: any) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key: any) {
    await null;
    return localStorage.getItem(key);
  }
};

export const handleApiData = (value  :any) => {
  return value === null ? "" : value;
}

export const handleCondition = (value: any, primary :any, secondary :any) => {
  return value ? primary : secondary;
}

export const handleOrCondition = (value: any,value2 :any,  primary :any, secondary :any) => {
  return value || value2 ? primary : secondary;
}

export const handleAndCondition = (value: any, value2 :any, primary :any, secondary :any) => {
  return value && value2 ? primary : secondary;
}

export const getBookingDate = (id: any) => {
  var d = new Date(id);
  let arr = d.toDateString().split(' ').slice(1, 3).join(' ')
  return arr
}

export const getSessionDate = (id: any) => {
  var d = new Date(id);
  let arr = d.toDateString().split(' ').slice(1, 4).join(' ')
  console.log(d.toDateString())
  return arr
}

export const getBookingTime = (id: any) => {
  var d = new Date(id);
  //@ts-ignore
  let arr = d.toLocaleTimeString('en-US').replace(/(.*)\D\d+/, '$1')
  return arr
}

export async function getStorageData(key: string, parseToJson: boolean = false) {
  if (StorageProvider && key) {
    const data = await StorageProvider.get(key) || null;
    if (parseToJson && data) {
      return JSON.parse(data)
    } else {
      return data;
    }
  }
  return null;
}

export async function removeStorageData(key: string) {
  if (StorageProvider && key) {
    await StorageProvider.remove(key);
  }
}

export async function logoutUser(logoutType: string = '') {
  console.log('logoutUser');
  if (StorageProvider) {
    await removeStorageData('authToken');
    await removeStorageData('profileData');
    await removeStorageData('deviceToken');
    await removeStorageData('role');
    await removeStorageData('catalogListData');
  }
  //NavigationService.resetTo('primaryStack', { screen: 'Login' });
  if (logoutType == 'force') {
    customAlert('Session expired', 'Your session has expired. Please login again to continue.');
    setTimeout(() => { resetTo('primaryStack', { screen: 'EmailAccountLoginBlock' }) }, 2000);
  } else {
    resetTo('primaryStack', { screen: 'EmailAccountLoginBlock' });
  }
}

// export function isEmpty(val: any): boolean {
export async function fetchImageUrl(url: any): Promise<string> {
  let imgUrl = await fetch(url)
    .then(function (response) {
      console.log("console -> response", response);
      if (!response.ok) {
        console.log("Rewrite error handling - Draft code");
        return url;
      }
      let responseJson = response.json();
      console.log("console -> responseJson", responseJson);
      response.json().then(function (data) {
        console.log("console -> data", data);
        return data.media_details.sizes.medium_large.source_url
      })
    })
    .catch(function (error) {
      return url;
    });
  return imgUrl;
}

/** 
 * Navigation Services Helper
 * */
let _navigator: any;

export function setNavigator(nav: any) {
  _navigator = nav;
}

// Gets the current screen from navigation state
export function getActiveRouteName(state: any): any {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
}

export function resetTo(routeName: any, params: object = {}) {
  _navigator.reset({
    index: 0,
    routes: [{ name: routeName, params }],
  });
}
/** Navigation Services Helper Ends*/

export const sessionMessage = 'Your session has been expired. Click here to login'

export function getRegex(dataSet: any) {
  const baseRegex: any = {
    start: "^",
    end: ".{" + dataSet.min_length + ",}$",
    special_char: "(?=.*[!@#$%^&*()])",
    up_case: "(?=.*[A-Z])",
    min_number: "(?=.*[0-9])",
    lower_case: "(?=.*[a-z])"
  }
  const errorMessage: any = {
    start: "Required atleast",
    end: ` with the length of atleast ${dataSet.min_length} characters`,
    special_char: " 1 special character",
    up_case: " 1 uppercase letter",
    min_number: " 1 number",
    lower_case: " 1 lowercase letter"
  }
  let regexArray: any = []
  let errorMsgArray: any = []
  const result = Object.entries(dataSet).filter(item => item[1] === 1).map(item => item[0]);
  result.forEach((x: any) => {
    regexArray.push(baseRegex[x])
    errorMsgArray.push(errorMessage[x])
  })
  let reqRegEx = regexArray.join('')
  let reqErrorMsg = errorMsgArray.join(',')
  const regEx = new RegExp(baseRegex.start + reqRegEx + baseRegex.end)
  return { regEx, message: errorMessage.start + reqErrorMsg + errorMessage.end }
}