// Customizable Area Start
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Picker } from "react-native";
import { Formik } from "formik";
import * as yup from "yup";
import DesktopDesign from "./DesktopSelectedMaterial";
import {
  getAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";

const validation_Schema = yup.object({
  assign_to: yup.string().required("Please Assign a User"),
  size: yup.string().required("Size is required"),
});

export default function SelectedMaterial({ navigation }: { navigation: any }) {
  const [salesRep, setSalesRep] = useState<any>(null);

  const order = JSON.parse(localStorage.getItem("order") || "{}");

  useEffect(() => {
    const getSalesRep = async () => {
      let url = `/bx_block_order/orders/sales_representative_list?brand_id=${
        order.manfacturer_id
      }&category_id=${order.category_id}`;
      await getAxiosAPI(url).then((res: any) => {
        if (res.error) {
          let msg = getErrorMessage(res.data.errors[0]);
        } else {
          setSalesRep(res.data.data[0]);
        }
      });
    };
    getSalesRep();
  }, []);

  const [count, setCount] = useState(1);

  const incCont = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };
  const decCont = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const material = JSON.parse(localStorage.getItem("selectedMaterial") || "{}");
  const addMaterialToList = (values: any) => {
    let selectedMaterial = {
      material_name: material.attributes.name,
      assign_to: values.assign_to,
      material_id: material.id,
      size: values.size,
      assign_to_type: "",
      assign_to_id: "",
      quantity: count,
      image: material.attributes.image,
      uniqueID: new Date().getTime(),
    };
    if (selectedMaterial.assign_to === "Facility") {
      selectedMaterial.assign_to_type = "BxBlockHospital::Hospital";
      selectedMaterial.assign_to_id = order.hospital_id;
      order.salesRep = salesRep
        ? salesRep.attributes.first_name + " " + salesRep.attributes.last_name
        : "Not Present";
    } else {
      selectedMaterial.assign_to_type = "AccountBlock::Account";
      selectedMaterial.assign_to_id = salesRep ? salesRep.id : "not presnet";
    }
    if (localStorage.getItem("isCptCodeFlow") === "yes") {
      let materialsList = JSON.parse(
        localStorage.getItem("selectedCodeList") || "[]"
      );
      // filter unique materials -----------------

      const filteredArr = materialsList.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.material_id === o.material_id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      filteredArr.push(selectedMaterial);
      localStorage.setItem("selectedCodeList", JSON.stringify(filteredArr));
      navigation.navigate("CMFPB");
    } else {
      let materialsList = JSON.parse(
        localStorage.getItem("selectedMaterialList") || "[]"
      );
      // filter unique materials -----------------
      const filteredArr = materialsList.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.material_id === o.material_id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      filteredArr.push(selectedMaterial);
      localStorage.setItem("selectedMaterialList", JSON.stringify(filteredArr));
      order.salesRep = salesRep
        ? salesRep.attributes.first_name + " " + salesRep.attributes.last_name
        : "Not Present";
      order.saleRep_id = salesRep ? salesRep.id : 0;
      order.saleRep_no = salesRep ? salesRep.attributes.phone_number : 0;
      localStorage.setItem("order", JSON.stringify(order));
      navigation.navigate("CartList");
    }
  };

  return window.screen.width < 950 ? (
    <Formik
      initialValues={{ assign_to: "", size: "", quantity: "" }}
      validationSchema={validation_Schema}
      onSubmit={(values, actions) => {
        addMaterialToList(values);
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
      }) => (
        <View style={styles.container}>
          <View style={styles.headingView}>
            <TouchableOpacity
              style={styles.leftArrowIconWrapper}
              onPress={() => navigation.goBack()}
            >
              <img
                src={require("../assets/leftArrow1.png")}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
            <Text numberOfLines={2} style={styles.heading}>
              {material.attributes.name}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View style={{ marginHorizontal: "auto" }}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("MaterialDetails")}
                >
                  <img
                    src={
                      material.attributes.image
                        ? material.attributes.image
                        : require("../assets/dummyMaterial.png")
                    }
                    style={{
                      height: 274,
                      width: 274,
                      borderRadius: 12,
                      paddingTop: 10,
                    }}
                  />
                </TouchableOpacity>
                <View style={styles.line} />
              </View>
              <View
                style={{
                  marginHorizontal: 24,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 24, color: "#4e3e71" }}>
                  {material.attributes.name}
                </Text>
                <View style={styles.quantity}>
                  <TouchableOpacity onPress={() => incCont()}>
                    <Text style={styles.btn}>+</Text>
                  </TouchableOpacity>

                  <Text style={styles.btn2}>
                    {count === 10 ? count : "0" + count}
                  </Text>

                  <TouchableOpacity onPress={() => decCont()}>
                    <Text style={styles.btn}>-</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.pickerContainer}>
                <Picker
                  selectedValue={values.assign_to}
                  style={styles.picker}
                  onValueChange={handleChange("assign_to")}
                >
                  <Picker.Item value={null} label="Assign To" />
                  <Picker.Item label="Facility" value="Facility" />
                  <Picker.Item
                    label="Sales Representative"
                    value="Sales Representative"
                  />
                </Picker>
                {touched.assign_to && errors.assign_to ? (
                  <Text style={{ color: "red", paddingLeft: 40 }}>
                    {errors.assign_to}
                  </Text>
                ) : null}
              </View>

              <View style={styles.pickerContainer}>
                <Picker
                  selectedValue={values.size}
                  style={styles.picker}
                  onValueChange={handleChange("size")}
                >
                  <Picker.Item value={null} label="Choose Size" />
                  <Picker.Item label="10mm" value="10mm" />
                  <Picker.Item label="20mm" value="20mm" />
                  <Picker.Item label="30mm" value="30mm" />
                  <Picker.Item label="40mm" value="40mm" />
                  <Picker.Item label="50mm" value="50mm" />
                  <Picker.Item label="60mm" value="60mm" />
                  <Picker.Item label="70mm" value="70mm" />
                  <Picker.Item label="80mm" value="80mm" />
                  <Picker.Item label="90mm" value="90mm" />
                  <Picker.Item label="100mm" value="100mm" />
                </Picker>
                {touched.size && errors.size ? (
                  <Text style={{ color: "red", paddingLeft: 40 }}>
                    {errors.size}
                  </Text>
                ) : null}
              </View>
            </View>
            <View>
              <TouchableOpacity onPress={() => handleSubmit()}>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>Add to List</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </Formik>
  ) : (
    <DesktopDesign navigation={navigation} />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
  },
  line: {
    width: 48,
    height: 5,
    bordeRadius: 100,
    backgroundColor: "#e5e5e5",
    marginHorizontal: "auto",
    marginVertical: 50,
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  quantity: {
    flexDirection: "row",
    height: 50,
    borderRadius: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    marginHorizontal: 10,
    justifyContent: "space-between",
  },
  btn: {
    fontSize: 30,
    color: "#4e3e71",
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  btn2: {
    fontSize: 20,
    color: "#4e3e71",
    padding: 2,
    backgroundColor: "#cce9e5",
    borderRadius: 4,
    margin: 5,
    paddingHorizontal: 10,
  },
  likeicon: {
    height: 32,
    width: 32,
    borderRadius: 16,
    padding: 7,
    borderWidth: 1,
    borderColor: "#e5e5e5",
    marginHorizontal: 11,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 18,
    marginHorizontal: 40,
    paddingHorizontal: 30,
  },
  pickerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
    paddingVertical: 10,
    marginHorizontal: 24,
  },
  picker: {
    width: "100%",
    height: 50,
    color: "#4e3e71",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
  color: {
    color: "#0579ed",
    fontSize: 20,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginVertical: 20,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  dropdown: {
    marginVertical: 20,
    marginHorizontal: 20,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 6,
  },
  listItem: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginVertical: 20,
  },
  itemImage: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    margin: 10,
  },
});
// Customizable Area End
