// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  View,
  TextInput,
  Image,
  ScrollView,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale, { verticalScale } from "../../../framework/src/Scale";
import Dialog from "@material-ui/core/Dialog";
import RequestMaterial from "./RequestMaterial";
import ProgressBar from "./StepperComponent";
import {
  getAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../framework/src/Utilities";
import { ListEmptyView } from "../../../framework/src/CustomPopUp";

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const order = JSON.parse(localStorage.getItem("order") || "{}");
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getListOfMaterials();
  }, [offset]);

  const getListOfMaterials = async (search: any = "") => {
    setLoader(true);
    let url = `/bx_block_materials/materials?search=${search}&limit=6&offset=${offset}&brand_id=${
      order.manfacturer_id
    }&category_id=${order.category_id}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.data.errors) {
        setLoader(false);
        setData([]);
      } else {
        setData(
          res.data.data.sort((a: any, b: any) =>
            SortData(
              a.attributes.name.toLowerCase(),
              b.attributes.name.toLowerCase()
            )
          )
        );
        setLoader(false);
      }
    });
  };

  const handleSearch = (text: any) => {
    setQuery(text);
    getListOfMaterials(text);
  };

  const navigateTo = (item: any) => {
    localStorage.setItem("selectedMaterial", JSON.stringify(item));
    navigation.navigate("SelectedMaterial");
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <View style={styles.desktopContainer}>
      <DesktopHeader heading={"Choose Materials"} navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View>
            <ProgressBar activeStepCount={3} />
          </View>
          <View style={styles.outerCard}>
            <Text
              style={{ fontSize: 20, color: "#4e3e71", fontWeight: "bold" }}
            >
              Select Material{" "}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.searchBox}>
                <TextInput
                  style={styles.input}
                  placeholder="Search "
                  autoCapitalize="none"
                  autoCorrect={false}
                  clearButtonMode="always"
                  placeholderTextColor="#939ca3"
                  value={query}
                  onChangeText={(queryText) => handleSearch(queryText)}
                />
                <TouchableOpacity onPress={() => setQuery("")}>
                  <img
                    src={
                      query === ""
                        ? require("../assets/search.png")
                        : require("../assets/cross.png")
                    }
                    style={{
                      height: 30,
                      width: 30,
                      paddingRight: 15,
                      paddingTop: 10,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <View>
                {loader ? (
                  <ActivityIndicator
                    style={{ margin: "auto", height: 100, width: 100 }}
                    size="large"
                    color="#4e3e71"
                  />
                ) : (
                  <View style={styles.card}>
                    <View style={{ flex: 1 }}>
                      {query.length > 0 && data.length > 0 ? (
                        <View style={styles.add}>
                          <Text
                            style={styles.resultText}
                          >{`Showing result "${query}"`}</Text>
                        </View>
                      ) : query.length > 0 && data.length === 0 ? (
                        <View style={styles.add}>
                          <Text style={styles.resultText}>
                            Searched Material not in the catalouge,{" "}
                            <TouchableOpacity onPress={() => handleOpen()}>
                              <Text
                                style={{
                                  textDecorationLine: "underline",
                                  fontSize: 20,
                                  color: "#4e3e71",
                                }}
                              >
                                Request Material
                              </Text>
                            </TouchableOpacity>
                          </Text>
                        </View>
                      ) : null}

                      <FlatList
                        data={data}
                        horizontal={false}
                        numColumns={6}
                        ListEmptyComponent={ListEmptyView()}
                        keyExtractor={(item: any) => item.id}
                        renderItem={({ item }) => (
                          <View>
                            {item.attributes.is_active ? (
                              <TouchableOpacity
                                onPress={() => navigateTo(item)}
                              >
                                <View style={styles.listm}>
                                  {item.attributes.image ? (
                                    <img
                                      src={item.attributes.image}
                                      style={{
                                        height: 150,
                                        width: 150,
                                        borderRadius: 12,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../assets/dummyMaterial.png")}
                                      style={{
                                        height: 150,
                                        width: 150,
                                        borderRadius: 12,
                                      }}
                                    />
                                  )}

                                  <View>
                                    <Text numberOfLines={3} style={styles.name}>
                                      {item.attributes.name}
                                    </Text>
                                  </View>
                                  <View style={styles.priceView}>
                                    <Text style={styles.discount_price}>
                                      {item.attributes.discount_price
                                        ? "$" + item.attributes.discount_price
                                        : "$0.00"}
                                    </Text>
                                    <Text style={styles.price}>
                                      {item.attributes.price
                                        ? "$" + item.attributes.price
                                        : "$0.00"}
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            ) : null}
                          </View>
                        )}
                      />
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {offset < 1 ? null : (
                          <TouchableOpacity
                            onPress={() => setOffset(offset - 7)}
                          >
                            <Text
                              style={{ color: "#4e3e71", fontWeight: "bold" }}
                            >
                              {"< Prev"}
                            </Text>
                          </TouchableOpacity>
                        )}
                        {data.length < 6 ? null : (
                          <TouchableOpacity
                            onPress={() => setOffset(offset + 7)}
                          >
                            <Text
                              style={{ color: "#4e3e71", fontWeight: "bold" }}
                            >
                              {"Next >"}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ borderRadius: 15 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <View style={{ width: 500, height: 480 }}>
          <RequestMaterial handleClose={handleClose} navigation={navigation} />
        </View>
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  name: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontSize: 16,
    maxWidth: scale(40),
  },
  price: {
    color: "#b2bac6",
    paddingVertical: 5,
    fontSize: 13,
    textDecorationLine: "line-through",
    width: scale(20),
  },
  discount_price: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontSize: 14,
    width: scale(20),
  },
  priceView: { flexDirection: "row", justifyContent: "space-between" },
  listm: {
    overflow: "hidden",
    shadowColor: "#b2bac6",
    shadowRadius: 5,
    shadowOpacity: 1,
    borderRadius: 12,
    padding: 16,
    borderColor: "#f2f4f5",
    borderWidth: 1,
    marginHorizontal: 8,
    marginVertical: 8,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#4e3e71",
    marginHorizontal: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#aa9cc9",
    marginHorizontal: 10,
  },
  buttonText0: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  listItem: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem_active: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  role: {
    color: "#b2bac6",
    paddingHorizontal: 11,
    width: scale(50),
  },
  names: {
    color: "#4e3e71",
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(60),
  },

  add: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
  },
  card: {
    flex: 1,
    marginTop: 10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  filterIcon: {
    height: 48,
    width: 48,
    borderRadius: 24,
    padding: 8,
    borderWidth: 1,
    borderColor: "#4e3e71",
    marginVertical: "auto",
    marginHorizontal: "auto",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  picker: {
    height: 50,
    width: 160,
    color: "white",
    paddingHorizontal: 15,
    backgroundColor: "#4e3e71",
    borderRadius: 50,
  },
  searchBox: {
    flexDirection: "row",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  input: {
    outlineStyle: "none",
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
    width: 950,
  },
  outerCard: {
    flex: 1,
    elevation: 3,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 25,
    margin: 15,
    flexDirection: "column",
  },
});
// Customizable Area End
