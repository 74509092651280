import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  TextInput,
  ActivityIndicator,
  ScrollView,
  FlatList,
} from "react-native";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarCSS.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import scale, { verticalScale } from "../../../framework/src/Scale";
import {
  getAxiosAPI,
  getErrorMessage,
  putAxiosAPI,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../framework/src/Utilities";
import CustomPopUp from "../../../framework/src/CustomPopUp";

export default function NewBooking({ navigation }: { navigation: any }) {
  const editBookingProps = JSON.parse(
    localStorage.getItem("editBookingProps") || "{}"
  );
  const editBookingPostData = JSON.parse(
    localStorage.getItem("editBookingPostData") || "{}"
  );
  const order = JSON.parse(localStorage.getItem("editBookingProps") || "{}");

  const [value, onChange] = useState(
    new Date(
      editBookingPostData.bookingDateTime
        ? editBookingPostData.bookingDateTime
        : editBookingProps.bookingDateTime
    )
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      editBookingPostData.bookingDateTime
        ? editBookingPostData.bookingDateTime
        : editBookingProps.bookingDateTime
    )
  );
  const [enableFacility, setEnableFacility] = useState(false);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const navigateTo = () => {
    const data = {
      bookingDateTime: Date.parse(
        value.toLocaleDateString() + " " + selectedDate.toLocaleTimeString()
      ),
      bookingId: editBookingProps.bookingId,
      hospitalId: editBookingProps.hospitalId,
    };
    localStorage.setItem("editBookingPostData", JSON.stringify(data));
    setEnableFacility(true);
    // navigation.navigate('EditBookingFacility')
  };

  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = useState(0);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [hospital_id, setHospital_id] = useState(
    order.hospitalId ? order.hospitalId : ""
  );


  const getHospitals = async (search: any = "") => {
    setLoader(true);
    let url = `/bx_block_hospitals/hospitals?search=${search}&limit=10&offset=${offset}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setLoader(false);
      } else {
        let newData = res.data.data.sort((a: any, b: any) =>
          SortData(
            a.attributes.name.toLowerCase(),
            b.attributes.name.toLowerCase()
          )
        );
        const finalData = newData.map((x: any) => {
          if (x.id === order.hospitalId) {
            x.ischecked = !x.ischecked;
          } else {
            x.ischecked = false;
          }
          return x;
        });
        setData(finalData);
        setLoader(false);
      }
    });
  };

  const submitChanges = async () => {
    const postData = {
      order: {
        booking_datetime: editBookingPostData.bookingDateTime,
        hospital_id: hospital_id,
      },
    };
    let url = `/bx_block_order/orders/${order.bookingId}`;
    await putAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0])
        setMessage(msg)
        setPopup(true)
      } else {
        const removeKey = ["editBookingProps", "editBookingPostData"];
        removeKey.forEach((k: any) => {
          localStorage.removeItem(k);
        });
        setPopup(true);
        setMessage("Booking Details Updated Successfully");
      }
    });
  };

  useEffect(() => {
    getHospitals();
  }, [offset]);

  const handleSearch = (text: any) => {
    setQuery(text);
    getHospitals(text);
  };

  const handleChange = (item: any) => {
    let newdata: any = data.map((x: any) => {
      if (x.id === item.id) {
        x.ischecked = !x.ischecked;
      } else {
        x.ischecked = false;
      }
      return x;
    });
    setsearchData(newdata);
    setData(newdata);
    setHospital_id(item.id);
  };

  const ClosePopUp = () => {
    setPopup(false);
    navigation.goBack();
  };

  return (
    <>
   {popup && <CustomPopUp 
      btnText={"Back"}
      ClosePopUp={() => setPopup(false)}
      message={message}
    />}
      {enableFacility ? (
        <View style={styles.container}>
          {popup && (
            <CustomPopUp
              ClosePopUp={ClosePopUp}
              btnText={"Back"}
              message={message}
            />
          )}
          <View>
            {window.screen.width < 950 && (
              <View style={styles.headingView}>
                <TouchableOpacity
                  style={styles.leftArrowIconWrapper}
                  onPress={() => setEnableFacility(false)}
                >
                  <img
                    src={require("../assets/leftArrow1.png")}
                    style={{
                      height: 24,
                      width: 24,
                    }}
                  />
                </TouchableOpacity>
                <Text style={styles.heading}>Edit Your Facility</Text>
              </View>
            )}
          </View>
          <View style={styles.searchBox}>
            <TextInput
              style={styles.input}
              placeholder="Search "
              autoCapitalize="none"
              autoCorrect={false}
              clearButtonMode="always"
              placeholderTextColor="#939ca3"
              value={query}
              onChangeText={(queryText: any) => handleSearch(queryText)}
            />
            <TouchableOpacity onPress={() => setQuery("")}>
              <img
                src={
                  query === ""
                    ? require("../assets/search.png")
                    : require("../assets/cross.png")
                }
                style={{
                  height: 30,
                  width: 30,
                  paddingRight: 15,
                  paddingTop: 10,
                }}
              />
            </TouchableOpacity>
          </View>
          {loader ? (
            <ActivityIndicator
              style={{ margin: "auto", height: 100, width: 100 }}
              size="large"
              color="#4e3e71"
            />
          ) : (
            <View style={styles.card}>
              <ScrollView style={{ flex: 1 }}>
                {query.length > 0 && data.length > 0 ? (
                  <View style={styles.add}>
                    <Text
                      style={styles.resultText}
                    >{`Showing result "${query}"`}</Text>
                  </View>
                ) : query.length > 0 && data.length === 0 ? (
                  <View style={styles.add}>
                    <Text style={styles.resultText}>
                      Searched facility not found
                    </Text>
                  </View>
                ) : null}

                <FlatList
                  data={data}
                  keyExtractor={(item: any) => item.id}
                  renderItem={({ item }) => (
                    <TouchableOpacity onPress={() => handleChange(item)}>
                      <View
                        style={
                          item.ischecked
                            ? styles.listItem_active
                            : styles.listItem
                        }
                      >
                        <View style={{ flexDirection: "row", flex: 0.9 }}>
                          <img
                            src={
                              item.attributes.logo
                                ? item.attributes.logo
                                : require("../assets/hospital copy.png")
                            }
                            style={{ height: 56, width: 56 }}
                          />
                          <View
                            style={{
                              flexDirection: "column",
                              marginVertical: "auto",
                            }}
                          >
                            <Text style={styles.names}>
                              {item.attributes.name}
                            </Text>
                            <Text style={styles.role} numberOfLines={2}>
                              {item.attributes.address}
                            </Text>
                          </View>
                        </View>
                        <TouchableOpacity
                          style={{ marginVertical: "auto", flex: 0.1 }}
                        >
                          <img
                            src={
                              item.ischecked
                                ? require("../assets/radio_active.png")
                                : require("../assets/radio_unactive.png")
                            }
                            style={{
                              height: 26,
                              width: 26,
                              backgroundColor: "#4e3e71",
                              borderRadius: "50%",
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                  )}
                />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  {offset < 1 ? null : (
                    <TouchableOpacity onPress={() => setOffset(offset - 10)}>
                      <Text style={{ color: "#4e3e71" }}>{"< Prev"}</Text>
                    </TouchableOpacity>
                  )}
                  {data.length < 10 ? null : (
                    <TouchableOpacity onPress={() => setOffset(offset + 10)}>
                      <Text style={{ color: "#4e3e71" }}>{"Next >"}</Text>
                    </TouchableOpacity>
                  )}
                </View>
              </ScrollView>
            </View>
          )}

          <TouchableOpacity onPress={() => submitChanges()}>
            <View style={styles.button}>
              <Text style={styles.buttonText}>Continue</Text>
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.container}>
          <View>
            <View>
              {window.screen.width < 950 && (
                <View style={styles.headingView}>
                  <TouchableOpacity
                    style={styles.leftArrowIconWrapper}
                    onPress={() => navigation.goBack()}
                  >
                    <img
                      src={require("../assets/leftArrow1.png")}
                      style={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </TouchableOpacity>
                  <Text style={styles.heading}>Edit Booking Date & Time</Text>
                </View>
              )}
              <View />
              <Text style={{ color: "#4e3e71", fontSize: 24, padding: 24 }}>
                Select Date & Time
              </Text>
              <View style={{ marginHorizontal: "auto" }}>
                <Calendar
                  minDate={new Date()}
                  onChange={onChange}
                  value={value}
                  className="calenderNew"
                />
              </View>
              <View />
            </View>
            <View>
              <Text style={{ color: "#4e3e71", padding: 24, fontSize: 24 }}>
                Select Time
              </Text>
              <View style={styles.timePicker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </View>
            </View>
          </View>
          <TouchableOpacity onPress={() => navigateTo()}>
            <View style={styles.button}>
              <Text style={styles.buttonText}>Continue</Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
  },
  role: {
    color: "#b2bac6",
    paddingHorizontal: 11,
    width: scale(220),
  },
  names: {
    color: "#4e3e71",
    fontSize: 15,
    paddingHorizontal: 11,
  },
  listItem: {
    height: 88,
    marginHorizontal: 26,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem_active: {
    height: 88,
    marginHorizontal: 26,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  add: {
    flexDirection: "row",
    justifyContent: "center",
  },
  card: {
    flex: 1,
    marginTop: 20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  searchBox: {
    flexDirection: "row",
    marginHorizontal: 26,
    marginVertical: 20,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  searchIcon: {
    paddingLeft: 30,
    paddingVertical: 10,
  },
  input: {
    outlineStyle: "none",
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  timePicker: {
    //     margin: 20,
    paddingHorizontal: 24,
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 24,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
});
