import React, {useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
Platform,
TouchableOpacity,
ActivityIndicator,
TextInput,
FlatList,
ScrollView, } from 'react-native';
import scale from "../../../framework/src/Scale";
import DesktopLayout from "./CatalougeDesktopManufac";
import { getAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';



export default function SelectManfac({navigation}: {navigation: any}) {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [brand_id, setbrand_id ] = useState('')
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)
  const role_id = localStorage.getItem('role') || ''

  useEffect(() => {
    if(window.screen.width < 950) {
      getListOfManufac();
    }
      
  }, [offset]);

  const getListOfManufac = async (search: any = '') => {
        setLoader(true)
        let url;
        if(role_id === '4'){
          url ='/account_block/accounts/user_selected_brands'
        } else {
          url = `/bx_block_catalogue/brands?search=${search}&limit=10&offset=${offset}`
        }
        
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                setPopup(true)
                setMessage(res.data)
                setLoader(false)
              } else {
                setLoader(false)
                setData(res.data.data.sort(function (a: any, b: any) 
                { return (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase())
                   ? 1 : ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase())
                    ? -1 : 0); }))
              }
            })
      
        }
  const handleSearch = (text : any) => {
        setQuery(text)
        getListOfManufac(text)
      };
  const ListEmptyView = () => {
      return (
        <View>
          <Text style={styles.heading2}>No Record Found</Text>
          </View>
          )}

  const handleChange = (item:any) => {
          const order = JSON.parse(localStorage.getItem('order') || '{}')
      order.manfacturer_id = item.id
      localStorage.setItem('order', JSON.stringify(order))
      navigation.navigate('CatalougeCategories')
  };
  
  return (
    window.screen.width < 950 ?
    <View style={styles.container}>

        <View style={styles.headingView}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
            <img src={require("../assets/leftArrow1.png")} style={{
              height: 24, width: 24,
            }} />
          </TouchableOpacity>
    <Text style={styles.heading}>{role_id === '4' ? "Your Manufacturers" : "Select Manufacturer"}</Text>
         
      </View>
      { role_id === '1' ? null :
       <View style={styles.searchBox}>
                <TextInput
                          style={styles.input}
                          placeholder="Search "
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
              </TouchableOpacity> 
              </View>  }
              {loader ? <ActivityIndicator
                        style={{ margin: 'auto', height: 100, width: 100, }}
                        size="large" color="#4e3e71" />
                        :
                      <View style={styles.card}>
    <ScrollView style={{flex: 1}}>
    {
                      (query.length > 0 && data.length > 0) ? 
                      <View style={styles.add}>
                      <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                      </View> 
                           : 
                           (query.length > 0 && data.length === 0) ? 
                           <View style={styles.add}>
                           <Text style={styles.resultText}>Searched Manufacturer not found, {'\n'} 
                          </Text>
                           </View> : null
                     }
                      
                     <FlatList
                      data={data}
                      keyExtractor={(item: any) => item.id}
                      ListEmptyComponent={ListEmptyView()}
                      renderItem={({ item }) => (
                      <TouchableOpacity onPress={() => handleChange(item)} >
                                    <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                        <View style={{flexDirection: 'row' , flex: 0.95}}>
                                          <img src={require("../assets/manifac_img.png")} style={{ height: 56, width: 56}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                               <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>    
                                                  <Text style={styles.role} numberOfLines={2}>{item.attributes.address}</Text>
                                                </View>
                                        </View>
                                      
                                    </View>
                      </TouchableOpacity>
                     
                      )}
                      />
                       {role_id !== '4' && <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                          {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"< Prev"}</Text></TouchableOpacity>}
                          {data.length < 9 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"Next >"}</Text></TouchableOpacity>}
                        </View>}
                      </ScrollView>
                      </View> 
                }
      </View>
      :
      <DesktopLayout navigation={navigation} />
)
}
const styles = StyleSheet.create({
container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: 'column'
  },
  heading2: {
      marginVertical: 100,
      marginHorizontal: 'auto',
      color: '#b2bac6',
      fontSize: 24,
      fontWeight: 'bold',
      flexDirection : 'row',
      justifyContent: 'center'
    },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  listItem: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItem_active: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(240),
    
  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(220),
    
  },
  
  checkboxContainer: {
    flex: 0.1,
    paddingRight: 10,
    paddingTop: 80
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 27,
    backgroundColor: '#4e3e71',
    marginBottom: 20,

  },
  heading:{
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'       
  },
  crossIcon: {
    position: 'absolute',
    top: 40,
    right: 10,
    elevation: 10,
},      
searchBox: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 10,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50
  
},
    input: {
outlineStyle: 'none',
      flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
    },
  card: {
    flex: 1,
    marginTop:10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard1 : {
    flex: 1,
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#eff8ff",
    marginHorizontal: 30,
    marginVertical: 6,
    position: 'relative',
    justifyContent: 'space-between'
  },
  innerCard1: {
    flex: 0.2,
    height: 100,
    backgroundColor: "white",
    margin: 10,
    borderRadius: 10
  },
  innerCard2: {
    flex: 0.2,
    backgroundColor: "#eff8ff",
  },
  nameaddress:{
    flex: 0.6,
    backgroundColor: "#eff8ff",
  },

  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  cardHeading: {
    marginTop: 10,
    color: '#0579ed',
    fontSize: 18,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {   
    color: '#0579ed'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#aa9cc9',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
})
