import React,{useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
FlatList
 } from 'react-native';
import DesktopDesigns from "./DesktopCMFP-2";

 export default function CMFPB({navigation} : {navigation:any}) { 
       const [materials, setMaterials] = useState<any>([]);
      const code = localStorage.getItem('selectedCptCode')

   useEffect(() => {
     let materialsNew = JSON.parse(localStorage.getItem('selectedCodeList') || '[]')
    const filteredArr = materialsNew.reduce((unique: any, o: any) => {
      if (!unique.some((obj: any) => obj.material_id == o.material_id && obj.size == o.size)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setMaterials(filteredArr)
    
   },[])
   
 
      

       const increaseQuant = (id:any) => {
            let newList = materials.map((x:any) => {
              if(x.material_id === id && x.quantity <10){
                x.quantity = x.quantity+1
              } return x
            })
            setMaterials(newList)
          }


          const decreaseQuant = (id:any) => {
            let newList = materials.map((x:any) => {
              if(x.material_id === id && x.quantity > 1){
                x.quantity = x.quantity-1
              } return x
            })
            setMaterials(newList)
          }

          const deleteMaterial = (id : any) => {
            let newList = materials.filter((x: any) => x.material_id !== id)
            setMaterials(newList)
            localStorage.setItem('selectedCodeList', JSON.stringify(newList))
          }

          const addMaterails = () => {
                localStorage.setItem('isCptCodeFlow', 'yes')
                navigation.navigate('SelectManufacture')
          }

          const navigateTo = () => {
                localStorage.setItem('selectedMaterialList', JSON.stringify(materials))
            navigation.navigate('CMFPC')
          }
      
       
   return (
     window.screen.width < 950 ?
            <View style={styles.container}>
              <View>
         <View style={styles.headingView}>
           <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
             <img src={require("../assets/leftArrow1.png")} style={{
               height: 24, width: 24,
             }} />
           </TouchableOpacity>
                   <Text style={styles.heading}> Choose Material  </Text>
                   </View>
                  <View style={styles.card}>
                  <View style={styles.topHeading}>
                        <Text style={styles.text1}>Code:- {code}</Text>
                        <TouchableOpacity onPress={() => addMaterails()}>
                        <Text style={styles.text3}>Add Material </Text>
                        </TouchableOpacity>
                      
                  </View>
                  <FlatList 
                                data={materials}
                                keyExtractor={(item: any) => item.material_id}
                                renderItem={({ item }) => (
                            
                                  <View style={styles.material}>
                                  <View style={{flexDirection: 'row'}}>
                                <img src={require("../assets/material2.png")} style={{height: 72, width: 72}}/>     
                                <View style={{flexDirection: 'column'}}>                                
                                  <Text style={{fontSize: 20, color: '#2b2b2b'}}>{item.material_name}</Text>
                                  <Text style={{color: '#4e3e71', fontSize: 15, paddingVertical: 10}}>{item.size}</Text>
                                  <View style={styles.quantity}>
              
                                <TouchableOpacity onPress={() => increaseQuant(item.material_id)}>
                                <Text style={styles.btn}>+</Text>
                                </TouchableOpacity>
                            
                                <Text style={styles.btn2}>{item.quantity === 10 ? item.quantity : "0"+item.quantity}</Text>

                                <TouchableOpacity onPress={() => decreaseQuant(item.material_id)}>
                                <Text style={styles.btn}>-</Text>
                                </TouchableOpacity>
                              </View>
                                  </View>   
                                </View>
                                <TouchableOpacity onPress={() => deleteMaterial(item.material_id)}>
                                <img src={require("../assets/delete2.png")} style={{height : 24, width: 24}}/>
                                </TouchableOpacity>
                        </View>
                                )}
                              />
                                  </View>
                            
                </View>
                  <TouchableOpacity onPress={() => navigateTo()}>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Add To Patient List </Text>
                              </View>
                  </TouchableOpacity>
              
                  
      
       </View>
       :
       <DesktopDesigns navigation={navigation} />
       )}

const styles = StyleSheet.create({
            container: {
                  flex: 1,
                  backgroundColor: "#ffff",
                  flexDirection: 'column',
                  justifyContent: 'space-between'
              
                },
                headingView: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 27,
                  backgroundColor: '#4e3e71',
                  marginBottom: 20,
              
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
                heading:{
                  color: '#e8fafe',
                  fontSize: 20,
                  marginHorizontal: 'auto'       
                },
                quantity:{
                  flexDirection: 'row',
                  flex: 1,
                  width: 108,
                  // height: 32,
                  borderRadius: 5,
                  borderColor: '#e5e5e5',
                  borderWidth: 1, 
                  marginVertical: 5,
                  justifyContent: 'space-between'
                },
                btn:{
                  fontSize: 20,
                  color: '#4e3e71',
                  paddingVertical: 2,
                  paddingHorizontal: 10
                },
                btn2:{
                  fontSize: 15,
                  color: '#4e3e71',
                  padding: 2,
                  backgroundColor: '#cce9e5',
                  borderRadius: 4,
                  margin: 5,
                  paddingHorizontal: 10
                },
                material: {
                  paddingVertical: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: '#d8d8d8'
                },
                info: {
                  color: '#0778df',
                  fontSize: 20,
                  paddingVertical: 2,
            },
                card: {
                  marginVertical: 24,
                  marginHorizontal: 20,
                  flexDirection: 'column',
                  justifyContent: 'space-between'

                },
                topHeading: {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginVertical: 10
                },
                materials:{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginVertical: 5,
                  borderBottomWidth: 1,
                  borderColor :'grey'
                },
                text2:{
                  paddingVertical: 2,
                  color: '#007af2',
                  fontSize: 20
                },
                text1:{
                  fontSize: 24,
                  color: '#4e3e71',
                },
                text3: {
                  fontSize: 24,
                  color: '#4e3e71',
                      borderBottomWidth: 1,
                      borderBottomColor: '#4e3e71'
                },
                button: {
                  borderRadius: 12,
                  paddingVertical: 15,
                  paddingHorizontal: 50,
                  marginHorizontal: 20,
                  backgroundColor: '#4e3e71',
                  marginBottom: 10,
                  marginTop: 10
                },
                buttonText: {
                  color: '#e8fafe',
                  fontSize: 17,
                  textAlign: 'center',
                },

})