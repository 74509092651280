import React,{ useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity, } from 'react-native';
import Dialog from '@material-ui/core/Dialog';
import CMFPConfirm from "./CMFP-confirm"
import { getErrorMessage, patchAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';


export default function CMFPC({ navigation }: { navigation: any }) {
      
      const [open, setOpen] = useState(false)
      const handleClose = () => {
            setOpen(false)
      }


      const upDateList = async () => {
            const cpt_material_id = localStorage.getItem('selectedCode')
            const material_data = JSON.parse(localStorage.getItem('selectedMaterialList') || '[]')
            const postData = {
                  cpt_material_id: material_data[0].selectedCptId,
                  material_data: material_data
            }
            let url = `/bx_block_order/orders/update_material_for_cpt_code`;
            await patchAxiosAPI(url, postData).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
              } else {
                  if (window.screen.width < 950) {
                        navigation.navigate('CMFPconfirm')  
                  } else {
                        setOpen(true)
                  }
              }
            })
      }

      const navigateTo = () => {
            if (window.screen.width < 950) {
                  navigation.navigate('CMFPconfirm')  
            } else {
                  setOpen(true)
            }
     
      }
      return (
            <View style={styles.container}>
                  <View style={{flexDirection: 'column', flex: 0.8}}>
                        <Text style={styles.text}>Want to update the default for the Procedure.</Text>
                  </View>
                  <View style={{flex: 0.2}}>
                  <TouchableOpacity onPress={() => navigateTo()}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>No</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => upDateList()}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Yes</Text>
                        </View>
                  </TouchableOpacity>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                  >
                        <View style={{ height: 500, width: 500, margin: 50 }}>
                              <CMFPConfirm navigation={navigation} />
                        </View>

                  </Dialog>
            </View>
      )
};

const styles = StyleSheet.create({
      container: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
            backgroundColor: "#ffff"

          },
          text: {
            // paddingHorizontal: 50,
            marginVertical: 'auto',
            marginHorizontal: 24,
            alignItems: 'center',
            // paddingVertical: 10,
            fontSize: 24,
            color: '#4e3e71'
          },
          button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
})