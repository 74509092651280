// Customizable Area Start
import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList,
} from 'react-native';
import ChevronRight from "@material-ui/icons/ChevronRight";
import Dialog from '@material-ui/core/Dialog';
import { getRole, logOut } from '../../../framework/src/Utilities';



export default function Menue({ navigation, state, userData }: { navigation: any, state: any, userData: any }) {
  
  const routeListSurgeon = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: 'confirmed',
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png")
    },
    {
      name: "Profile",
      routename: "Profile",
      status: 'confirmed',
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png")
    },
    {
      name: "Existing Booking",
      routename: "ExBooking",
      status: 'confirmed',
      image: require("../assets/book.png"),
      Desktop: require("../assets/book.png")
    },
    {
      name: "Start New Booking",
      routename: "CreateBooking",
      status: 'confirmed',
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png")
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: userData.status,
      image: require("../assets/shopping.png"),
      Desktop: require("../assets/shopping.png")
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: 'confirmed',
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png")
    },
    {
      name: "History",
      routename: "History",
      status: 'confirmed',
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png")
    },
    {
      name: "Tracking",
      routename: "Tracking",
      status: 'confirmed',
      image: require("../assets/tracking.png"),
      Desktop: require("../assets/navigationDesktop.png")
    },
    {
      name: "Invite Users",
      routename: "AddTeam",
      status: 'confirmed',
      image: require("../assets/invite.png"),
      Desktop: require("../assets/personDesktop.png")
    },
    {
      name: "Notification",
      routename: "Notification",
      status: 'confirmed',
      image: require("../assets/bell.png"),
      Desktop: require("../assets/bellDesktop.png")
    },
    {
      name: "Logout",
      routename: "Logout",
      status: 'confirmed',
      image: require("../assets/logout.png"),
      Desktop: require("../assets/logout.png")
    }

  ];

  
  const routeListMaterialManage = [
    {
      name: "Dashboard",
      routename: "Dashboard",
       status: 'confirmed',
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png")
    },
    {
      name: "Profile",
      routename: "Profile",
       status: 'confirmed',
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png")
    },
    {
      name: "Validate New Manager",
      routename: "ValidateManager",
      status: userData.status,
      image: require("../assets/validateNewManager.png"),
      Desktop: require("../assets/cameraDesktop.png")
    },
    {
      name: "Generate Reports ",
      routename: "UserActivityReport",
      status: "confirmed",
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: userData.status,
      image: require("../assets/shopping.png"),
      Desktop: require("../assets/shopping.png")
    },
    {
      name: "Validate New Surgeon",
      routename: "ValidateNewSurgeonBlock",
      status: userData.status,
      image: require("../assets/userIcon.png"),
      Desktop: require("../assets/cameraDesktop.png")
    },
    {
      name: "New Material",
      routename: window.screen.width < 950 ? "ChooseAddMaterialType" : "NewMaterial",
      status: userData.status,
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png")
    },
    {
      name: "History",
      routename: "History",
      status: userData.status,
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png")
    },
    {
      name: "Notification",
      routename: "Notification",
      image: require("../assets/bell.png"),
       status: 'confirmed',
      Desktop: require("../assets/bellDesktop.png")
    },
    {
      name: "Logout",
      routename: "Logout",
      image: require("../assets/logout.png"),
       status: 'confirmed',
      Desktop: require("../assets/logout.png")
    }
  ];

  
  const routeListBookingCoordinator = [
    {
      name: "Dashboard",
       status: 'confirmed',
      routename: "Dashboard",
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png")
    },
    {
      name: "Profile",
       status: 'confirmed',
      routename: "Profile",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png")
    },
    {
      name: "All Booking",
       status: userData.status,
      routename: "ExBooking",
      image: require("../assets/validateNewManager.png"),
      Desktop: require("../assets/cameraDesktop.png")
    },
    {
      name: "Generate Reports ",
      routename: "UserActivityReport",
      status: "confirmed",
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Validate BC",
       status: userData.status,
      routename: "ValidateBC",
      image: require("../assets/userIcon.png"),
      Desktop: require("../assets/cameraDesktop.png")
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: userData.status,
      image: require("../assets/shopping.png"),
      Desktop: require("../assets/shopping.png")
    },
    {
      name: "My Facility",
      status: 'confirmed',
      routename: "UserFacilities",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png")
    },
    {
      name: "History",
      routename: "History",
      status: userData.status,
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png")
    },
    {
      name: "Notification",
      routename: "Notification",
      status: 'confirmed',
      image: require("../assets/bell.png"),
      Desktop: require("../assets/calendarDesktop.png")
    },
    {
      name: "Logout",
      routename: "Logout",
      status: 'confirmed',
      image: require("../assets/logout.png"),
      Desktop: require("../assets/logout.png")
    }
  ];

  
  const routeListSalesRep = [
    {
      name: "Dashboard",
      status: 'confirmed',
      routename: "Dashboard",
      image: require("../assets/home.png"),
      Desktop: require("../assets/homeDesktop.png")
    },
    {
      name: "Profile",
      status: 'confirmed',
      routename: "Profile",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png")
    },
    {
      name: "All Booking",
      status: 'confirmed',
      routename: "ExBooking",
      image: require("../assets/book.png"),
      Desktop: require("../assets/book.png")
    },
    {
      name: "History",
      status: 'confirmed',
      routename: "History",
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png")
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: userData.status,
      image: require("../assets/shopping.png"),
      Desktop: require("../assets/shopping.png")
    },
    {
      name: "Manage Manufacturers",
      status: 'confirmed',
      routename: "UserManufacturers",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png")
    },
    {
      name: "Add New Material",
      status: 'confirmed',
      routename: "NewMaterial",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png")
    },
    {
      name: "Confirm Material Details",
      status: 'confirmed',
      routename: "RequestedMaterial",
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png")
    },
    {
      name: "Notification",
      status: 'confirmed',
      routename: "Notification",
      image: require("../assets/bell.png"),
      Desktop: require("../assets/navigationDesktop.png")
    },
    {
      name: "Out Of Office",
      status: 'confirmed',
      routename: "OutOfOffice",
      image: require("../assets/bell.png"),
      Desktop: require("../assets/bellDesktop.png")
    },
    {
      name: "Logout",
      status: 'confirmed',
      routename: "Logout",
      image: require("../assets/logout.png"),
      Desktop: require("../assets/logout.png")
    }
  ];


  useEffect(() => {
    const assignlist = () => {
      const user = JSON.parse(
        localStorage.getItem("loggedinUserInfo") || "{}"
      );
      let id = user.role_id
      if (id === 1) {
        setrouteList(routeListSurgeon)
      } else if (id === 2) {
          setrouteList(routeListMaterialManage);
      } else if (id === 3) {
          setrouteList(routeListBookingCoordinator);
      } else {
        setrouteList(routeListSalesRep)
      }
    }

    assignlist()

  }, [])

  const changeRoute = (route: any) => {
    state({ left: false })
    navigation.navigate(route)
  }

  


  const [routeList, setrouteList] = useState<any>([]);

 


  let roleId = localStorage.getItem('role')

  const [open, setOpen] = useState(false)

  const handleClose = () => {

  }



  return (
    <View style={styles.container}>
      <View style={styles.profile}>
        <View style={{ flexDirection: 'row', }}>
          <TouchableOpacity onPress={() =>  navigation.navigate('Profile')}>
          <img src={userData.profile_image ? userData.profile_image : require("../assets/avatar-chat1.png")} style={{ height: 110, width: 110, borderRadius: '50%' }} />
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => navigation.navigate('Profile')}>
           <img src={require("../assets/edit.png")} style={{ height: 24, width: 24, marginTop: 70, borderRadius: '50%', marginLeft: 20, position: 'absolute' }} />
          </TouchableOpacity> */}
        </View>

        <Text style={styles.profileText}>{userData?.first_name + " " + userData?.last_name}</Text>
        <Text style={styles.profileText2}>Role: {getRole(JSON.stringify(userData.role_id))}</Text>
      </View>
      <View style={styles.subWrapper}>
        <FlatList
          data={routeList}
          keyExtractor={(item: any) => item.name}
          renderItem={({ item }) => {
            return (
              <>
                {item.status === 'pending' ?
                  <View
                    style={styles.menuWrapper}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Image source={item.image} style={styles.menuImage} />

                      <Text style={styles.menuName2}>{item.name}</Text>
                    </View>

                    <ChevronRight color={"disabled"} />
                  </View> :
                  <TouchableOpacity
                    style={styles.menuWrapper}
                    onPress={() => item.name === 'Logout' ? logOut() : changeRoute(item.routename)}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Image source={item.image} style={styles.menuImage} />

                      <Text style={styles.menuName}>{item.name}</Text>
                    </View>

                    <ChevronRight color={"disabled"} />
                  </TouchableOpacity>
                }  </>
            );
            
          }}
        />
      </View>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <Text>You are not Validate by your respective manager yet</Text>
        </Dialog>
    </View>
  )
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#4E3E71"
  },
  profileImage: {
    width: 100,
    height: 100,
    backgroundColor: "#b2bac6",
    borderRadius: 99
  },
  profileText: {
    color: "#fff",
    fontSize: 24
  },
  profileText2: {
    color: "#fff",
    fontSize: 14,
    paddingTop: 5
  },
  profile: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4E3E71",
    paddingHorizontal: 50,
    flex: 0.3
  },
  menuImage: { height: 24, width: 24, marginRight: 8, resizeMode: 'contain' },
  menuName: { fontSize: 14, color: "#4e3e71", marginTop: 4, marginLeft: 10 },
  menuName2: { fontSize: 14, color: "#b2bac6", marginTop: 4, marginLeft: 10 },
  menuWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 24,
    width: "100%",
    paddingHorizontal: 10,
    alignItems: "center"
  },
  subWrapper: {
    borderTopEndRadius: 24,
    zIndex: 999,
    backgroundColor: "#E8FAFE",
    flex: 0.7
  }

})
// Customizable Area End